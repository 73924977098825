import React from 'react';
import EmbedVideo from 'react-player/youtube';

function shouldEmbbedYoutubeLink(node) {
  const recognisedDomains = ['https://youtu.be/', 'https://youtube.com/'];
  return recognisedDomains.some((domain) => node.href.startsWith(domain));
}

function shouldEmbedVideoLink(node) {
  return node.title === 'video'; // links of form: [Title](videolink "video")
}

export default function LinkRenderer(node) {
  if (shouldEmbbedYoutubeLink(node)) {
    return <EmbedVideo url={node.href} controls style={{ maxWidth: '100%' }} />;
  }

  if (shouldEmbedVideoLink(node)) {
    return (
      <video controls style={{ maxHeight: '50vh', maxWidth: '80vw' }}>
        <source src={node.href}></source>
      </video>
    );
  }

  // if it's an inter-page link, then href is "#element-id"
  if (node.href.startsWith('#')) {
    return <a href={node.href}>{node.children}</a>;
  }

  return (
    <a href={node.href} target="_blank" rel="noopener noreferrer">
      {node.children}
    </a>
  );
}
