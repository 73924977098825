import dayjs from 'dayjs';
import React from 'react';
import useAuth0 from '../../hooks/use-auth0';
import useMentorSubmissionsContext from '../../hooks/use-mentor-submissions';
import { postProjectSubmissionReview, ProjectSubmission } from '../../hydra';
import Button from '../Button';
import { Ratings } from './Ratings';
import utc from 'dayjs/plugin/utc';
import styled from 'styled-components';
import {
  getSubmissionSecondsSpent,
  SubmissionsTimeSpentContext,
} from '../../contexts/submissions-time-spent';

dayjs.extend(utc);

const MIN_FEEEDBACK_LENGTH = 150;
const MAX_REVIEW_LENGTH = 3000;

const ValidationLabel = styled.p`
  font-size: 0.75em;
  color: ${({ theme }) => theme.colors.textDanger};
  margin: 0.2em 0;
`;

export default function Review({
  submission,
  onPostReview,
}: {
  submission: ProjectSubmission;
  onPostReview: () => void;
}) {
  const { getTokenSilently } = useAuth0();
  const { retry: refreshSubmissions, loading: isRefreshingSubmissions } =
    useMentorSubmissionsContext();
  const [didTriggerRefresh, setDidTriggerRefresh] = React.useState(false);
  const [didSubmitReview, setDidSubmitReview] = React.useState(false);

  const isReviewed = !!submission.review?.date;
  const [internalReview, setInternalReview] = React.useState(
    submission?.review?.internal ?? ''
  );
  const isInternalReviewFilledIn =
    internalReview.length >= MIN_FEEEDBACK_LENGTH;
  const didInternalReviewChange =
    internalReview !== submission.review?.internal;

  const [learnerReview, setLearnerReview] = React.useState(
    submission?.review?.learner ?? ''
  );
  const isLearnerReviewFilledIn = learnerReview.length >= MIN_FEEEDBACK_LENGTH;
  const didHitCharacterLimit = learnerReview.length >= MAX_REVIEW_LENGTH;
  const didLearnerReviewChange = learnerReview !== submission.review?.learner;

  const [score, setScore] = React.useState(submission.review?.score || null);
  const didScoreChange = score !== submission.review?.score;

  const [submissionsIntervals] = React.useContext(SubmissionsTimeSpentContext);

  const onSubmitReview = async () => {
    setDidSubmitReview(true);
    const token = await getTokenSilently();
    if (token && score && isInternalReviewFilledIn && isLearnerReviewFilledIn) {
      await postProjectSubmissionReview(token, {
        projectSessionId: submission.submission.id,
        score,
        internalReview,
        learnerReview,
        secondsSpent: getSubmissionSecondsSpent({
          submissionType: submission.type,
          submissionId: submission.submission.id,
          submissionsIntervals,
        }),
      });
      refreshSubmissions();
      setDidTriggerRefresh(true);
    }
  };
  // we cannot await "refreshSubmissions"
  // we react to its loading state change, and trigger onPostReview after it's done loading, knowing that we refreshed it before
  React.useEffect(() => {
    if (didTriggerRefresh && !isRefreshingSubmissions) {
      onPostReview();
      setDidTriggerRefresh(false);
      setDidSubmitReview(false);
    }
  }, [didTriggerRefresh, isRefreshingSubmissions, onPostReview]);

  return (
    <div style={{ marginTop: '1em' }}>
      <ul style={{ margin: '0' }}>
        <li>
          {submission.review.date
            ? `Reviewed at: ${dayjs
                .utc(submission.review.date)
                .format('DD/MM/YYYY - HH:mm')}`
            : 'Not reviewed yet'}
        </li>
      </ul>
      <div style={{ flex: 1, marginTop: '1em' }}>
        <h4>Feedback</h4>
        <p style={{ margin: '1em 0 0.5em 0' }}>
          Would you like to leave some feedback for the learner on how they
          could have improved their project? (sent to the learner)
        </p>
        <textarea
          placeholder="Please enter your review here.."
          rows={learnerReview.split('\n').length}
          value={learnerReview}
          onChange={(e) => setLearnerReview(e.target.value)}
        />
        {!isLearnerReviewFilledIn && (
          <ValidationLabel>
            min {MIN_FEEEDBACK_LENGTH} characters
          </ValidationLabel>
        )}
        {didHitCharacterLimit && (
          <ValidationLabel>
            Oops! You've exceeded the character limit, please shorten your
            message.
          </ValidationLabel>
        )}
        <p style={{ margin: '1em 0 0.5em 0' }}>
          Why has working on this project been a valuable use of time for the
          learner? (only seen by Enki, to help with customer success)
        </p>
        <textarea
          placeholder="Please enter your review here.."
          rows={internalReview.split('\n').length}
          value={internalReview}
          onChange={(e) => setInternalReview(e.target.value)}
        />
        {!isInternalReviewFilledIn && (
          <ValidationLabel>
            min {MIN_FEEEDBACK_LENGTH} characters
          </ValidationLabel>
        )}
      </div>
      <div style={{ flex: 1, marginTop: '1em' }}>
        <h4>Score</h4>
        <Ratings score={score} setScore={setScore} />
        <Button
          primary
          disabled={
            didHitCharacterLimit ||
            didSubmitReview ||
            !isInternalReviewFilledIn ||
            !isLearnerReviewFilledIn ||
            !score ||
            (!didScoreChange &&
              !didInternalReviewChange &&
              !didLearnerReviewChange)
          }
          onClick={() => onSubmitReview()}
          style={{ marginTop: '1em' }}
          isLoading={didSubmitReview}
        >
          {isReviewed ? 'Edit review' : 'Submit review'}
        </Button>
      </div>
    </div>
  );
}
