import React from 'react';
import { useAsyncRetry } from 'react-use';
import * as CrashReporter from '../crash-reporter';

export default function useAsyncRetryWithCrashReporter<T>(
  fn: () => Promise<any>,
  args?: React.DependencyList
) {
  const state = useAsyncRetry<T>(fn, args);
  const error = state?.error;

  React.useEffect(() => {
    if (error) {
      CrashReporter.reportError(error);
    }
  }, [error]);

  return state;
}
