import React from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import Navbar from '../components/Navbar';
import PageWrapper from '../components/PageWrapper';
import useAuth0 from '../hooks/use-auth0';
import useCleanGlobalClasses from '../hooks/use-clean-global-classes';
import useMentorContext from '../hooks/use-mentor';
import ConfirmSkillsWidget from '../components/ConfirmSkillsWidget';
import { postSkillTest } from '../hydra';
import { SkillType, getSkillStatus } from '../helpers';
import { toast } from 'react-toastify';

const PageContentWrapper = styled.div`
  padding: 2em;
  background-color: ${(prop) => prop.theme.colors.sectionBg};
  border: 1px solid ${(prop) => prop.theme.colors.sectionBorder};
  border-radius: ${(prop) => prop.theme.dimensions.sectionBorderRadius};
`;

const SkillsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
`;
export default function ConfirmSkills() {
  const { isLoading, logout, auth0User, isAuthenticated, getTokenSilently } =
    useAuth0();
  const { value, retry: refreshMentorContext } = useMentorContext();
  const [skillToConfirm, setSkillToConfirm] = React.useState<string | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const submitSkillTest = React.useCallback(
    async (submission) => {
      setIsSubmitting(true);
      const token = await getTokenSilently();

      if (!token || !skillToConfirm) {
        return;
      }

      await postSkillTest(token, {
        skillIdentifier: skillToConfirm,
        skillTest: submission,
      });
      toast.success('Skill test submitted!');
      setSkillToConfirm(null);
      refreshMentorContext();
      setIsSubmitting(false);
    },
    [skillToConfirm, getTokenSilently, refreshMentorContext]
  );

  useCleanGlobalClasses({ isLoading });

  if (!value || !value.profile) {
    return <h2>Sorry, something went wrong.</h2>;
  }

  const { skills, status } = value.profile;

  return (
    <>
      <Navbar
        auth={{
          isLoading,
          isAuthenticated,
          user: auth0User,
          logout,
        }}
      />
      <PageWrapper>
        <PageContentWrapper>
          <h1>Confirm your Skills</h1>
          <SkillsTaskSection mentorStatus={status} />

          <p>
            Before you can mentor with Enki, you'll need to confirm at least one
            skill. This should take 20-30 minutes to complete.
          </p>
          <p>
            Confirm your skills to qualify for mentoring in associated Camps. To
            unlock more skills, email us on{' '}
            <a href="mailto:concierge@enki.com">concierge@enki.com</a>.
          </p>
          <Skills
            skills={skills}
            setSkillToConfirm={setSkillToConfirm}
            skillToConfirm={skillToConfirm}
          />
          <p style={{ marginTop: '1em' }}>
            Once you submit your answers, we'll be in touch with next steps.
          </p>
          <ConfirmSkillsWidget
            skill={skills.find((skill) => skill.identifier === skillToConfirm)}
            onConfirm={submitSkillTest}
            isSubmitting={isSubmitting}
          />
        </PageContentWrapper>
      </PageWrapper>
    </>
  );
}

function Skills({
  skills,
  setSkillToConfirm,
  skillToConfirm,
}: {
  skills: Array<SkillType>;
  setSkillToConfirm: (skill: string) => void;
  skillToConfirm: string | null;
}) {
  // TODO: maybe show an error message if no skills are unlocked.
  return (
    <SkillsWrapper>
      {skills.map((skill) => (
        <Skill
          isSelected={skill.identifier === skillToConfirm}
          key={skill.identifier}
          skill={skill}
          onSelect={() => setSkillToConfirm(skill.identifier)}
        />
      ))}
    </SkillsWrapper>
  );
}

const SkillWrapper = styled.div<{ isDisabled: boolean }>`
  opacity: ${(prop) => (prop.isDisabled ? 0.5 : 1)};
  background-color: ${(prop) => prop.theme.colors.pageBg};
  color: ${(prop) => prop.theme.colors.text};
  border-radius: ${(prop) => prop.theme.dimensions.sectionBorderRadius};
  padding: 1em;
  flex: 1;
  margin-bottom: 2em;
`;

const SkillName = styled.h3`
  margin: 0.5em 0;
`;

const SkillStatus = styled.div<{ isDisabled: boolean; isConfirmed: boolean }>`
  display: flex;
  align-items: center;
  color: ${(prop) => prop.theme.colors.textSecondary};
  margin-bottom: 1em;
`;

function Skill({
  skill,
  onSelect,
  isSelected,
}: {
  skill: SkillType;
  onSelect: () => void;
  isSelected: boolean;
}) {
  const status = getSkillStatus(skill);

  return (
    <SkillWrapper isDisabled={!skill.isSelected}>
      <SkillName>{skill.name}</SkillName>
      <SkillStatus
        isDisabled={!skill.isSelected}
        isConfirmed={skill.isConfirmed}
      >
        {status}
      </SkillStatus>
      {skill.isSelected && (
        <Button
          primary={isSelected}
          onClick={onSelect}
          disabled={skill.isSubmitted || skill.isConfirmed}
        >
          Confirm now
        </Button>
      )}
    </SkillWrapper>
  );
}

const SkillsTaskHighlight = styled.p`
  font-weight: bold;
  font-style: uppercase;
  color: ${(prop) => prop.theme.colors.pageBg};
  background-color: ${(prop) => prop.theme.colors.primary};
  padding: 0.5em;
  display: inline-block;
`;

function SkillsTaskSection({ mentorStatus }: { mentorStatus: string }) {
  if (!['SKILLS_TASK_SHARED', 'SKILLS_TASK_COMPLETED'].includes(mentorStatus)) {
    return null;
  }

  const copy =
    mentorStatus === 'SKILLS_TASK_SHARED'
      ? 'To complete onboarding, confirm at least one skill. Click "Confirm now" on any of the skills below to get started.'
      : 'Great stuff! Our team will review and get back to you by email shortly - please allow a couple days. In the meantime, feel free to confirm any other skills if applicable.';
  return <SkillsTaskHighlight>{copy}</SkillsTaskHighlight>;
}
