import React, { useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import Navbar from '../components/Navbar';
import PageWrapper from '../components/PageWrapper';
import useAuth0 from '../hooks/use-auth0';
import useCleanGlobalClasses from '../hooks/use-clean-global-classes';
import useMentorSubmissionsContext from '../hooks/use-mentor-submissions';
import { ActivitySubmission, ProjectSubmission } from '../hydra';
import uniqBy from 'lodash.uniqby';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router-dom';
import utc from 'dayjs/plugin/utc';
import SubmissionModal from '../components/Submission';
import Table from '../components/Table';
import PostReviewModal from '../components/Submission/PostReviewModal';
import { SubmissionsTimeSpentContext } from '../contexts/submissions-time-spent';
import useActivityDetector from '../hooks/use-activity-detector';
import * as tableConfigs from '../table-configs';

dayjs.extend(utc);

const PageContentWrapper = styled.div`
  padding: 2em;
  background-color: ${(prop) => prop.theme.colors.sectionBg};
  border: 1px solid ${(prop) => prop.theme.colors.sectionBorder};
  border-radius: ${(prop) => prop.theme.dimensions.sectionBorderRadius};
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Submissions() {
  const theme = useTheme();
  const query = useQuery();
  const history = useHistory();

  const { isLoading, logout, auth0User, isAuthenticated } = useAuth0();
  const { value: submissionsResponse } = useMentorSubmissionsContext();

  useCleanGlobalClasses({ isLoading });

  const learnerOptions = [
    {
      value: 'all',
      label: 'All',
    },
    ...uniqBy(
      submissionsResponse?.submissions.map((s) => ({
        value: s.learner.id.toString(),
        label: s.learner.name,
      })),
      'value'
    ),
  ];

  const topicsOptions = [
    {
      value: 'all',
      label: 'All',
    },
    ...uniqBy(
      submissionsResponse?.submissions.map((s) => ({
        value: s.content.slug,
        label: s.content.name,
      })),
      'value'
    ),
  ];

  const trackOptions = [
    {
      value: 'all',
      label: 'All',
    },
    ...uniqBy(
      submissionsResponse?.submissions.map((s) => ({
        value: s.track.slug,
        label: s.track.name,
      })),
      'value'
    ),
  ];

  const reviewOptions = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'unreviewed',
      label: 'Not reviewed',
    },
    {
      value: 'reviewed',
      label: 'Reviewed',
    },
  ];

  const [learnerFilter, setLearnerFilter] = React.useState(
    learnerOptions
      .map((x) => x.value)
      .find((tag) => query.get('learner') === tag) || 'all'
  );
  const [topicFilter, setTopicFilter] = React.useState(
    topicsOptions
      .map((x) => x.value)
      .find((tag) => query.get('topic') === tag) || 'all'
  );
  const [trackFilter, setTrackFilter] = React.useState(
    trackOptions
      .map((x) => x.value)
      .find((tag) => query.get('track') === tag) || 'all'
  );
  const [reviewFilter, setReviewFilter] = React.useState(
    reviewOptions
      .map((x) => x.value)
      .find((tag) => query.get('review') === tag) || 'all'
  );

  const urlSubmissionId = query.get('submission');

  const [openSubmissionId, setOpenSubmissionId] = React.useState<number | null>(
    urlSubmissionId ? parseInt(urlSubmissionId) || null : null
  );
  const [openSubmissionType, setOpenSubmissionType] = React.useState<
    string | null
  >(query.get('type') || null);

  const openSubmission = submissionsResponse?.submissions.find(
    (x) => x.submission.id === openSubmissionId && x.type === openSubmissionType
  );

  React.useEffect(() => {
    history.push({
      search: `?learner=${learnerFilter}&topic=${topicFilter}&track=${trackFilter}&review=${reviewFilter}${
        openSubmissionId
          ? `&submission=${openSubmissionId}&type=${openSubmission?.type}`
          : ''
      }`,
    });
  }, [
    history,
    topicFilter,
    trackFilter,
    reviewFilter,
    learnerFilter,
    openSubmissionId,
    openSubmission?.type,
  ]);

  const [isPostReviewModalOpen, setIsPostReviewModalOpen] =
    React.useState(false);

  const onNextSubmission = () => {
    const nextSubmission = (submissionsResponse?.submissions ?? []).find(
      (x) => !x.review.date
    );
    if (nextSubmission) {
      setOpenSubmissionId(nextSubmission.submission.id);
      setOpenSubmissionType(nextSubmission.type);
      setIsPostReviewModalOpen(false);

      const submissionModalContainer = document.getElementById(
        'submission-modal-container'
      );
      if (submissionModalContainer) {
        submissionModalContainer.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  };
  const unreviewedSubmissionsCount = (
    submissionsResponse?.submissions ?? []
  ).filter((x) => !x.review.date).length;

  const onPostReview = () => {
    setIsPostReviewModalOpen(true);
  };

  const [, dispatchTimeSpent] = useContext(SubmissionsTimeSpentContext);

  const isIdle = useActivityDetector();
  React.useEffect(() => {
    if (openSubmission?.type && openSubmission?.submission.id && !isIdle) {
      dispatchTimeSpent({
        submissionType: openSubmission?.type,
        submissionId: openSubmission?.submission.id,
        type: 'start',
      });
    } else {
      dispatchTimeSpent({
        type: 'stop',
      });
    }
  }, [openSubmission, dispatchTimeSpent, isIdle]);

  return (
    <>
      <Navbar
        auth={{
          isLoading,
          isAuthenticated,
          user: auth0User,
          logout,
        }}
      />

      {openSubmissionId && (
        <SubmissionModal
          key={`activity-review-${openSubmission?.submission.id}-${openSubmission?.type}`}
          onPostReview={onPostReview}
          submission={openSubmission}
          onCloseModal={() => {
            setOpenSubmissionId(null);
            setOpenSubmissionType(null);
          }}
        />
      )}

      <PageWrapper>
        {isPostReviewModalOpen && (
          <PostReviewModal
            onCloseModal={() => setIsPostReviewModalOpen(false)}
            unreviewedSubmissionsCount={unreviewedSubmissionsCount}
            onNextSubmission={onNextSubmission}
          />
        )}
        <PageContentWrapper>
          <h1>Learners' Submissions</h1>
          <Table<ActivitySubmission | ProjectSubmission>
            canResetFilters
            onClick={(item) => {
              setOpenSubmissionId(item.submission.id);
              setOpenSubmissionType(item.type);
            }}
            defaultSortIndex={6}
            defaultSortDirection={'desc'}
            data={submissionsResponse?.submissions || []}
            filters={tableConfigs.submissions.getFilters({
              theme,
              learner: {
                options: learnerOptions,
                value: learnerFilter,
                onChange: setLearnerFilter,
              },
              topic: {
                options: topicsOptions,
                value: topicFilter,
                onChange: setTopicFilter,
              },
              track: {
                options: trackOptions,
                value: trackFilter,
                onChange: setTrackFilter,
              },
              review: {
                options: reviewOptions,
                value: reviewFilter,
                onChange: setReviewFilter,
              },
            })}
            columnConfigs={tableConfigs.submissions.getColumns({ theme })}
            fallbackCopy="No submissions"
          />
        </PageContentWrapper>
      </PageWrapper>
    </>
  );
}

export default Submissions;
