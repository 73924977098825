import React from 'react';
import styled, { useTheme } from 'styled-components';
import useMentorContext from '../../hooks/use-mentor';

const ProgressBarWrapper = styled.div`
  max-width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
`;

const ProgressBar = styled.div`
  display: flex;
  flex-direction: row;
  height: 1em;
  border-radius: 0.25em;
  overflow: hidden;
`;

const Progress = styled.div<{ flex: number; color: string }>`
  flex: ${(props) => props.flex};
  background: ${(props) => props.color};
`;

const ProgressText = styled.p<{ offsetRatio: number }>`
  margin-bottom: 0.1em;
  position: relative;
  left: ${(props) => props.offsetRatio * 100}%;
`;

export default function MentorOnboardingProgress() {
  const { value } = useMentorContext();
  const theme = useTheme();

  const status = value?.profile?.status;

  if (!status || status === 'READY') {
    return null;
  }

  const { step, totalSteps, promptCopy } = getOnboardingProgress(status);
  const offsetRatio = parseFloat((step / totalSteps).toFixed(2));

  return (
    <div style={{ marginBottom: '1em' }}>
      <h1>Onboarding</h1>
      <p style={{ marginBottom: '1em' }}>{promptCopy}</p>
      <ProgressBarWrapper>
        {/* shifting the text with 2.5% such that it's aligned with the progress bar */}
        <ProgressText offsetRatio={offsetRatio - 0.025}>
          {Math.round(offsetRatio * 100)}%
        </ProgressText>
        <ProgressBar>
          <Progress flex={step} color={theme.colors.primary} />
          <Progress
            flex={totalSteps - step}
            color={theme.colors.textSecondary}
          />
        </ProgressBar>
      </ProgressBarWrapper>
    </div>
  );
}

function getOnboardingProgress(status: string) {
  // 1 INVITE_SHARED
  // -> [mentor] signs up & completes profile
  // 2 SKILLS_TASK_SHARED
  // -> [mentor] completes skills task
  // 3 SKILLS_TASK_COMPLETED
  // -> [ops] approve skill task
  // 4 INTERVIEW_TASK_SHARED
  // -> [mentor] schedules interview
  // 5 INTERVIEW_TASK_COMPLETED
  // -> [ops] approve interview task
  // 6  CONTRACT_TASK_SHARED
  // -> [mentor] signs contract
  // 7 CONTRACT_TASK_COMPLETED
  // -> [system] invites to slack
  // 8 SLACK_TASK_SHARED
  // -> [mentor] joins slack
  // 9 READY

  let state = {
    step: 1,
    promptCopy: 'To get started, please complete your profile!',
    isNextActionOnMentorPortal: false,
  };

  if (status === 'SKILLS_TASK_SHARED') {
    state = {
      step: 2,
      promptCopy: 'To proceed, please complete at least one skill test!',
      isNextActionOnMentorPortal: false,
    };
  }

  if (status === 'SKILLS_TASK_COMPLETED') {
    state = {
      step: 3,
      promptCopy:
        "Great work! We'll get back to you shortly once your submission has been reviewed.",
      isNextActionOnMentorPortal: true,
    };
  }

  if (status === 'INTERVIEW_TASK_SHARED') {
    state = {
      step: 4,
      promptCopy:
        'To proceed, please schedule a quick onboarding call with Enki Ops.',
      isNextActionOnMentorPortal: false,
    };
  }

  if (status === 'INTERVIEW_TASK_COMPLETED') {
    state = {
      step: 5,
      promptCopy:
        'Thanks for scheduling a call with Enki Ops, we look forward to speaking with you!',
      isNextActionOnMentorPortal: true,
    };
  }

  if (status === 'CONTRACT_TASK_SHARED') {
    state = {
      step: 6,
      promptCopy: 'To proceed, please review and sign your contract.',
      isNextActionOnMentorPortal: false,
    };
  }

  if (status === 'CONTRACT_TASK_COMPLETED') {
    state = {
      step: 7,
      promptCopy:
        'Great work! Please keep an eye out for an email inviting you to join our Slack Community.',
      isNextActionOnMentorPortal: true,
    };
  }

  if (status === 'SLACK_TASK_SHARED') {
    state = {
      step: 8,
      promptCopy:
        'To complete the onboarding process, please accept the invite to join our Slack Community.',
      isNextActionOnMentorPortal: false,
    };
  }

  // when step reaches 9, the parent component won't render at all

  return {
    ...state,
    totalSteps: 9,
  };
}
