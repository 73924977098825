import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import styled from 'styled-components';
import isEmpty from 'lodash.isempty';
import { prop } from 'styled-tools';
import {
  getBestPracticesResourceUrl,
  getBillingResourceUrl,
  getCommunicationResourceUrl,
  getFAQResourceUrl,
  getHomeUrl,
  getProfileUrl,
  getProjectsResourceUrl,
  getReviewingResourceUrl,
  getCyclesAndTracksResourceUrl
} from '../../routing';
import { LogoutOptions, User } from '@auth0/auth0-spa-js';

const NavbarWrapper = styled.div.attrs({
  id: 'navbar-main',
})`
  height: inherit !important;
  position: sticky !important;
`;

const Logo = styled.img.attrs(() => ({
  alt: 'EnkiCamp Logo',
  src: 'https://img.enkipro.com/8344382dc68c2c04d8846956e591e769.png',
}))`
  width: 7em;
  @media screen and (min-width: 768px) {
    align-self: flex-start;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;

  @media screen and (min-width: 768px) {
    margin-top: 0;
    margin-left: 1em;
  }
`;

const UserEmail = styled.div`
  @media screen and (min-width: 768px) {
    align-self: flex-end;
  }
`;

const NavButton = styled.span`
  border-radius: 3px;
  height: 1.1em;
  line-height: initial;
  padding: 0;
  color: ${prop('theme.colors.text')};
  &:hover {
    color: ${prop('theme.colors.textSecondary')};
    cursor: pointer;
  }
  margin-right: 1em;
  display: relative;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const PreviewBanner = styled.div`
  width: 100%;
  background-color: ${prop('theme.colors.primary')};
  color: ${prop('theme.colors.elementBg')};
  text-transform: uppercase;
  height: 4vh;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export default function Navbar({
  links = [],
  auth,
  isPreview = false,
  hideHome = false,
}: {
  auth: {
    isLoading: boolean;
    isAuthenticated: boolean;
    user: User | null;
    logout: (params?: LogoutOptions) => void;
  };
  links?: any[];
  isPreview?: boolean;
  hideHome?: boolean;
}) {
  const { isLoading, isAuthenticated, user, logout } = auth;
  const history = useHistory();

  useEffect(() => {
    // hack to make the first link active
    window.scrollBy(0, 1);
  }, []);

  return (
    <NavbarWrapper>
      <div className="inner">
        <Nav>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          {!isEmpty(links) && (
            <ul>
              {links.map(({ name, text, type }, i) => {
                const props = {
                  to: name,
                  offset: i === 0 ? -120 : -90,
                  activeClass: 'active',
                  smooth: true,
                  spy: true,
                };
                const LinkComponent =
                  type === 'scroll' ? (
                    <ScrollLink {...props}>{text}</ScrollLink>
                  ) : (
                    <Link {...props}>{text}</Link>
                  );
                return <li key={name}>{LinkComponent}</li>;
              })}
            </ul>
          )}
          {isLoading ? (
            <i className="fas fa-spinner fa-spin" />
          ) : (
            isAuthenticated && (
              <UserEmail>
                {!hideHome && (
                  <NavButton
                    title="home"
                    onClick={() => history.push(getHomeUrl())}
                  >
                    <i
                      className="fas fa-home"
                      style={{ marginRight: '0.5em' }}
                    ></i>
                    Home
                  </NavButton>
                )}
                <NavDropdown
                  title="Resources"
                  options={[
                    {
                      title: 'Cycles and Tracks',
                      onClick: () =>
                        history.push(getCyclesAndTracksResourceUrl()),
                    },
                    {
                      title: 'Communication with learners',
                      onClick: () =>
                        history.push(getCommunicationResourceUrl()),
                    },
                    {
                      title: 'Reviewing missions',
                      onClick: () => history.push(getReviewingResourceUrl()),
                    },
                    {
                      title: 'Projects',
                      onClick: () => history.push(getProjectsResourceUrl()),
                    },
                    {
                      title: 'Best practices',
                      onClick: () =>
                        history.push(getBestPracticesResourceUrl()),
                    },
                    {
                      title: 'Billing & Admin',
                      onClick: () => history.push(getBillingResourceUrl()),
                    },
                    {
                      title: "FAQ's",
                      onClick: () => history.push(getFAQResourceUrl()),
                    },
                  ]}
                />
                <NavButton
                  title="profile"
                  onClick={() => history.push(getProfileUrl())}
                >
                  <i
                    className="fas fa-user"
                    style={{ marginRight: '0.5em' }}
                  ></i>
                  Profile
                </NavButton>
                <code style={{ marginRight: '1em' }}>{user?.email}</code>
                <NavButton
                  title="Logout"
                  onClick={() =>
                    logout({
                      returnTo: window.location.origin,
                    })
                  }
                >
                  <i className="fas fa-sign-out-alt"></i>
                </NavButton>
              </UserEmail>
            )
          )}
        </Nav>
        {isPreview && <PreviewBanner>preview mode</PreviewBanner>}
      </div>
    </NavbarWrapper>
  );
}

const DropdownItemsWrapper = styled.div`
  position: fixed;
  background-color: ${(props) => props.theme.colors.elementBg};
  border-radius: ${(props) => props.theme.dimensions.sectionBorderRadius};
  box-shadow: ${(props) => props.theme.shadows.light};
  padding: 0.5em 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 3em;
`;

const DropdownItem = styled.p`
  margin: 0em;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.text};
  }
  line-height: 2.5em;
`;

const NavDropdown = ({
  title,
  options,
}: {
  title: string;
  options: Array<{ title: string; onClick: () => void }>;
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <div
      style={{ display: 'inline-block', position: 'relative' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <NavButton title={title}>
        <i
          className="fa-solid fa-clipboard-list-check"
          style={{ marginRight: '0.5em' }}
        ></i>
        {title}
        <i
          className="fa-solid fa-angle-down"
          style={{ marginLeft: '0.5em' }}
        ></i>
      </NavButton>
      {isHovered && (
        <DropdownItemsWrapper>
          {options.map(({ title, onClick }) => (
            <DropdownItem key={title} onClick={onClick}>
              {title}
            </DropdownItem>
          ))}
        </DropdownItemsWrapper>
      )}
    </div>
  );
};
