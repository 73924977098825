import React from 'react';
import styled from 'styled-components';

const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  > h2 {
    padding-left: 10px;
  }
`;

const NotFound = () => (
  <CenteredDiv>
    <h1>404</h1>
    <h2>Page Not Found</h2>
  </CenteredDiv>
);

export default NotFound;
