import { useState, useEffect } from 'react';
// @ts-ignore no types of "activity-detector"
import createActivityDetector from 'activity-detector';

export default function useActivityDetector() {
  const [isIdle, setIsIdle] = useState(false);
  useEffect(() => {
    const activityDetector = createActivityDetector({
      timeToIdle: 1000 * 60 * 10, // we consider you inactive after 10 minutes of no interaction, 1000ms * 60s (in a min) * 10m (minutes)
      ignoredEventsWhenIdle: [], // consider moving the mouse a valid event
    });
    activityDetector.on('idle', () => setIsIdle(true));
    activityDetector.on('active', () => setIsIdle(false));

    return () => activityDetector.stop();
  }, []);

  return isIdle;
}
