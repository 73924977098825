import { ProjectSubmission, ActivitySubmission, MentorLearner } from './hydra';
import {
  HighlightCell,
  TagCell,
  ReviewIconCell,
} from './components/Table/Cells';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DefaultTheme } from 'styled-components';
import { ColumnConfig, FiltersConfig } from './components/Table';

dayjs.extend(utc);

type MentorSubmission = ProjectSubmission | ActivitySubmission;

export const submissions = {
  getColumns: ({
    theme,
  }: {
    theme: DefaultTheme;
  }): ColumnConfig<MentorSubmission>[] => [
    {
      header: 'Learner',
      componentFn: (submission) => (
        <HighlightCell
          text={submission.learner.name}
          style={{ minWidth: '9em' }}
        />
      ),
      sortFn: (a, b) => a.learner.name.localeCompare(b.learner.name),
    },
    {
      header: 'Activity',
      componentFn: (submission) => (
        <HighlightCell
          style={{ minWidth: '9em' }}
          text={
            submission.type === 'activity'
              ? submission.activity.name
              : submission.name
          }
        />
      ),
      sortFn: (a, b) => {
        const aName = a.type === 'activity' ? a.activity.name : a.name;
        const bName = b.type === 'activity' ? b.activity.name : b.name;
        return aName.localeCompare(bName);
      },
    },
    {
      header: 'Type',
      componentFn: (submission) => (
        <TagCell
          style={{ minWidth: '8em' }}
          text={
            submission.type === 'activity'
              ? submission.activity.isCore
                ? 'CORE'
                : 'STRETCH'
              : 'Project'
          }
          color={
            submission.type === 'activity'
              ? theme.accents.lightYellow
              : theme.accents.lightRed
          }
        />
      ),
      sortFn: (a, b) => {
        const aName =
          a.type === 'activity'
            ? a.activity.isCore
              ? 'CORE'
              : 'STRETCH'
            : 'Project';
        const bName =
          b.type === 'activity'
            ? b.activity.isCore
              ? 'CORE'
              : 'STRETCH'
            : 'Project';
        return aName.localeCompare(bName);
      },
    },
    {
      header: 'Mission',
      componentFn: (submission) => (
        <HighlightCell
          style={{ minWidth: '14em' }}
          text={
            submission.type === 'activity'
              ? `${submission.mission.name} Week ${submission.week.index + 1}`
              : submission.name
          }
        />
      ),
      sortFn: (a, b) => {
        const aName =
          a.type === 'activity'
            ? `${a.mission.name} Week ${a.week.index + 1}`
            : a.name;
        const bName =
          b.type === 'activity'
            ? `${b.mission.name} Week ${b.week.index + 1}`
            : b.name;
        return aName.localeCompare(bName);
      },
    },
    {
      header: 'Content',
      componentFn: (submission) => (
        <TagCell
          text={submission.content.name}
          color={theme.accents.lightYellow}
          style={{ minWidth: '8em' }}
        />
      ),
      sortFn: (a, b) => a.content.name.localeCompare(b.content.name),
    },
    {
      header: 'Track',
      componentFn: (submission) => (
        <TagCell
          text={submission.track.name}
          color={theme.accents.lightBlue}
          style={{ minWidth: '10em' }}
        />
      ),
      sortFn: (a, b) => a.track.name.localeCompare(b.track.name),
    },
    {
      header: 'Submission Date',
      componentFn: (submission) => (
        <HighlightCell
          text={dayjs
            .utc(submission.submission.date)
            .format('DD/MM/YYYY - HH:mm')}
        />
      ),
      sortFn: (a, b) =>
        dayjs.utc(a.submission.date).diff(dayjs.utc(b.submission.date)),
    },
    {
      header: 'Review',
      componentFn: (submission) => (
        <ReviewIconCell reviewedAt={submission.review.date} />
      ),
      sortFn: (a, b) => {
        if (
          (a.review.date && b.review.date) ||
          (!a.review.date && !b.review.date)
        ) {
          return 0;
        }
        if (a.review.date && !b.review.date) {
          return 1;
        }
        if (!a.review.date && b.review.date) {
          return -1;
        }
        // won't get here, but just in case
        return 0;
      },
    },
  ],
  getFilters: ({
    theme,
    learner,
    topic,
    track,
    review,
  }: {
    theme: DefaultTheme;
    learner: {
      value: string;
      onChange: React.Dispatch<React.SetStateAction<string>>;
      options: { value: string; label: string }[];
    };
    topic: {
      value: string;
      onChange: React.Dispatch<React.SetStateAction<string>>;
      options: { value: string; label: string }[];
    };
    track: {
      value: string;
      onChange: React.Dispatch<React.SetStateAction<string>>;
      options: { value: string; label: string }[];
    };
    review: {
      value: string;
      onChange: React.Dispatch<React.SetStateAction<string>>;
      options: { value: string; label: string }[];
    };
  }): FiltersConfig<MentorSubmission> => [
    {
      label: 'Learners',
      value: learner.value,
      options: learner.options,
      onChange: learner.onChange,
      type: 'dropdown',
      filterFn: (submission, value) =>
        value === 'all'
          ? true
          : submission.learner.id.toString().includes(learner.value),
      defaultValue: 'all',
    },
    {
      label: 'Content',
      value: topic.value,
      options: topic.options,
      onChange: topic.onChange,
      type: 'dropdown',
      filterFn: (submission, value) =>
        value === 'all' ? true : submission.content.slug.includes(topic.value),
      color: theme.accents.lightYellow,
      defaultValue: 'all',
    },
    {
      label: 'Track',
      value: track.value,
      options: track.options,
      onChange: track.onChange,
      type: 'dropdown',
      filterFn: (submission, value) =>
        value === 'all' ? true : submission.track.slug.includes(track.value),
      color: theme.accents.lightBlue,
      defaultValue: 'all',
    },
    {
      label: 'Review',
      value: review.value,
      options: review.options,
      onChange: review.onChange,
      type: 'dropdown',
      filterFn: (submission, value) => {
        if (value === 'all') {
          return true;
        }
        if (value === 'unreviewed' && !submission.review.date) {
          return true;
        }
        if (value === 'reviewed' && submission.review.date) {
          return true;
        }
        return false;
      },
      color: theme.accents.lightTeal,
      defaultValue: 'all',
    },
  ],
};

export const learners = {
  getColumns: ({
    theme,
    submissions,
  }: {
    theme: DefaultTheme;
    submissions: MentorSubmission[];
  }): ColumnConfig<MentorLearner>[] => [
    {
      header: 'Learner',
      componentFn: (item) => <HighlightCell text={item.learner.name} />,
      sortFn: (a, b) => a.learner.name.localeCompare(b.learner.name),
    },
    {
      header: 'Track',
      componentFn: (item) => (
        <TagCell text={item.track.name} color={theme.accents.lightBlue} />
      ),
      sortFn: (a, b) => a.track.name.localeCompare(b.track.name),
    },
    {
      header: 'Content',
      componentFn: (item) => (
        <TagCell text={item.content.name} color={theme.accents.lightYellow} />
      ),
      sortFn: (a, b) => a.content.name.localeCompare(b.content.name),
    },
    {
      header: 'Email',
      componentFn: (item) => <HighlightCell text={item.learner.email} />,
      sortFn: (a, b) => a.learner.email.localeCompare(b.learner.email),
    },
    {
      header: 'Company',
      componentFn: (item) => <HighlightCell text={item.learner.company} />,
      sortFn: (a, b) => a.learner.company.localeCompare(b.learner.company),
    },
    {
      header: 'Reviews',
      componentFn: (item) => {
        const { reviewed, unReviewed } = getLearnerSubmissionsCounts(
          submissions,
          item
        );
        return (
          <TagCell
            text={`${reviewed} / ${unReviewed + reviewed}`}
            color={
              unReviewed > 0 ? theme.accents.lightRed : theme.accents.lightTeal
            }
          />
        );
      },
    },
  ],
  getFilters: ({
    theme,
    track,
    learner,
    topic,
    review,
    submissions,
  }: {
    theme: DefaultTheme;
    track: {
      value: string;
      onChange: React.Dispatch<React.SetStateAction<string>>;
      options: { value: string; label: string }[];
    };
    learner: {
      value: string;
      onChange: React.Dispatch<React.SetStateAction<string>>;
      options: { value: string; label: string }[];
    };
    topic: {
      value: string;
      onChange: React.Dispatch<React.SetStateAction<string>>;
      options: { value: string; label: string }[];
    };
    review: {
      value: string;
      onChange: React.Dispatch<React.SetStateAction<string>>;
      options: { value: string; label: string }[];
    };
    submissions: MentorSubmission[];
  }): FiltersConfig<MentorLearner> => [
    {
      label: 'Learners',
      value: learner.value,
      options: learner.options,
      onChange: learner.onChange,
      type: 'dropdown',
      filterFn: (item, value) =>
        value === 'all'
          ? true
          : item.learner.id.toString().includes(learner.value),
      defaultValue: 'all',
    },
    {
      label: 'Track',
      value: track.value,
      options: track.options,
      onChange: track.onChange,
      type: 'dropdown',
      filterFn: (submission, value) =>
        value === 'all' ? true : submission.track.slug.includes(track.value),
      color: theme.accents.lightBlue,
      defaultValue: 'all',
    },
    {
      label: 'Topic',
      value: topic.value,
      options: topic.options,
      onChange: topic.onChange,
      type: 'dropdown',
      filterFn: (submission, value) =>
        value === 'all' ? true : submission.content.slug.includes(topic.value),
      color: theme.accents.lightYellow,
      defaultValue: 'all',
    },
    {
      label: 'Review',
      value: review.value,
      options: review.options,
      onChange: review.onChange,
      type: 'dropdown',
      filterFn: (item, value) => {
        const { reviewed, unReviewed } = getLearnerSubmissionsCounts(
          submissions,
          item
        );
        if (value === 'all') {
          return true;
        }
        if (value === 'unreviewed' && reviewed !== reviewed + unReviewed) {
          return true;
        }
        if (value === 'reviewed' && reviewed === reviewed + unReviewed) {
          return true;
        }
        return false;
      },
      color: theme.accents.lightTeal,
      defaultValue: 'all',
    },
  ],
};

function getLearnerSubmissionsCounts(
  submissions: Array<ProjectSubmission | ActivitySubmission>,
  learner: MentorLearner
) {
  return submissions.reduce(
    (acc, val) => {
      if (val.learner.id !== learner.learner.id) {
        return acc;
      }

      if (val.review.date) {
        return { ...acc, reviewed: acc.reviewed + 1 };
      }

      return { ...acc, unReviewed: acc.unReviewed + 1 };
    },
    {
      reviewed: 0,
      unReviewed: 0,
    }
  );
}

export const notes = {
  getColumns: (): ColumnConfig<{
    text: string;
    type: string;
    date: string;
  }>[] => [
    {
      header: 'Note',
      componentFn: (item) => <HighlightCell text={item.text} />,
      sortFn: (a, b) => a.text.localeCompare(b.text),
    },
    {
      header: 'Type',
      componentFn: (item) => <HighlightCell text={parseNoteType(item.type)} />,
      sortFn: (a, b) => a.type.localeCompare(b.type),
    },
    {
      header: 'Date',
      componentFn: (item) => (
        <HighlightCell
          text={dayjs.utc(item.date).format('DD/MM/YYYY - HH:mm')}
        />
      ),
      sortFn: (a, b) => dayjs.utc(a.date).diff(dayjs.utc(b.date)),
    },
  ],
};

export const onboardingAnswers = {
  getColumns: (): ColumnConfig<MentorLearner['onboardingAnswers'][0]>[] => [
    {
      header: 'Question',
      componentFn: (item) => <HighlightCell text={item.question} />,
    },
    {
      header: 'Answer',
      componentFn: (item) => <HighlightCell text={item.answer} />,
    },
  ],
};

function parseNoteType(type: string) {
  if (type === 'student') {
    return 'Learner';
  }
  if (type === 'project') {
    return 'Project';
  }
  if (type === 'company') {
    return 'Company';
  }
  return 'Unknown';
}
