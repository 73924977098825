import { createPortal } from 'react-dom';
import styled, { useTheme } from 'styled-components';
import { learnerDashboardURL, MentorLearner } from '../../hydra';
import Tag from '../Tag';
import { prop } from 'styled-tools';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import useMentorSubmissionsContext from '../../hooks/use-mentor-submissions';
import TabbedContainer from '../TabbedContainer';
import Notes from './Notes';
import LearnerSubmissions from './LearnerSubmissions';
import OnboardingAnswers from './OnboardingAnswers';
dayjs.extend(utc);

const Pressable = styled.div`
  :hover {
    transform: translateY(-0.1em);
    transition: transform 0.5s;
    cursor: pointer;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  @media screen and (max-width: 768px) {
    padding: 1em;
  }
  padding: 2em;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
`;

const ModalBase = styled.div`
  @media screen and (max-width: 768px) {
    padding: 1em 1em;
  }
  padding: 4em 4em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 3;

  background-color: ${prop('theme.colors.sectionBg')};
  border: 1px solid ${prop('theme.colors.sectionBorder')};
  border-radius: 8px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const SectionWrapper = styled.div`
  margin-bottom: 3em;
`;

const HeaderSection = ({
  learner,
  onClose,
}: {
  learner: MentorLearner;
  onClose: () => void;
}) => {
  const theme = useTheme();
  return (
    <SectionWrapper>
      <HeaderRow>
        <h1 style={{ margin: '0' }}>Learner: {learner.learner.name}</h1>

        <Pressable title="Close Modal">
          <i
            className="fa-solid fa-x fa-2x"
            style={{ color: theme.colors.text }}
            onClick={onClose}
          />
        </Pressable>
      </HeaderRow>
    </SectionWrapper>
  );
};

const DividerRow = styled(Row)`
  gap: 1.5em;
  :before {
    content: '';
    border: 1px solid ${(props) => props.color || 'white'};
    align-self: stretch;
  }
`;

function ProfileSection({
  learner,
  style,
}: {
  learner: MentorLearner;
  style: React.CSSProperties;
}) {
  const { value: submissionResponse } = useMentorSubmissionsContext();
  const { core, stretch } = (submissionResponse?.submissions || [])
    .filter((x) => x.learner.id === learner.learner.id)
    .reduce(
      (acc, val) => {
        if (val.type === 'activity') {
          if (val.activity.isCore && val.submission.date) {
            acc.core.submitted++;
          }
          if (val?.activity.isCore && !val.submission.date) {
            acc.core.unSubmitted++;
          }
          if (!val.activity.isCore && val.submission.date) {
            acc.stretch.submitted++;
          }
          if (!val.activity.isCore && !val.submission.date) {
            acc.stretch.unSubmitted++;
          }
        }
        // treat projects as core for these counts
        if (val.type === 'project') {
          if (val.submission.date) {
            acc.core.submitted++;
          }
          if (!val.submission.date) {
            acc.core.unSubmitted++;
          }
        }

        return acc;
      },
      {
        core: {
          submitted: 0,
          unSubmitted: 0,
        },
        stretch: {
          submitted: 0,
          unSubmitted: 0,
        },
      }
    );

  return (
    <div style={{ flex: 1, ...style }}>
      <h2 style={{ marginBottom: '0' }}>Profile</h2>
      <TagsWrapper style={{ flexDirection: 'column', marginTop: '1em' }}>
        <TagsWrapper>
          <Tag style={{ margin: '0' }}>Email - {learner.learner.email}</Tag>
        </TagsWrapper>
        <TagsWrapper>
          <Tag style={{ margin: '0' }}>Company - {learner.learner.company}</Tag>
        </TagsWrapper>
        <ul style={{ marginBottom: '0' }}>
          <li>
            CORE submissions - {core.submitted} /{' '}
            {core.submitted + core.unSubmitted}{' '}
          </li>
          <li>
            STRETCH submissions - {stretch.submitted} /{' '}
            {stretch.submitted + stretch.unSubmitted}{' '}
          </li>
        </ul>
      </TagsWrapper>
    </div>
  );
}

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
`;

function TrackSection({
  learner,
  style,
}: {
  learner: MentorLearner;
  style?: React.CSSProperties;
}) {
  const theme = useTheme();

  const onOpenContent = () => {
    window.open(
      `${learnerDashboardURL}/preview/${learner.content.slug}/dashboard`,
      '_blank'
    );
  };

  return (
    <div style={{ flex: 1, ...style }}>
      <h2 style={{ marginBottom: '0' }}>Track</h2>
      <TagsWrapper style={{ flexDirection: 'column', marginTop: '1em' }}>
        <TagsWrapper>
          <Tag color={theme.accents.lightBlue} style={{ margin: '0' }}>
            {learner.track.name}
          </Tag>
        </TagsWrapper>
        <ul style={{ marginBottom: '0' }}>
          <li>
            Started On -{' '}
            {dayjs.utc(learner.cycle.startDate).format('DD/MM/YYYY')}
          </li>
          <li>
            Ends On - {dayjs.utc(learner.cycle.endDate).format('DD/MM/YYYY')}
          </li>
        </ul>
        <TagsWrapper>
          <Pressable
            onClick={onOpenContent}
            title="Open content preview dashboard"
          >
            <Tag color={theme.accents.lightYellow} style={{ margin: '0' }}>
              Course - {learner.content.name}
              {'  '}
              <i className="fa-solid fa-arrow-up-right-from-square" />
            </Tag>
          </Pressable>
        </TagsWrapper>
      </TagsWrapper>
    </div>
  );
}

export default function LearnerModal({
  learner,
  onCloseModal,
}: {
  learner?: MentorLearner;
  onCloseModal: () => void;
}) {
  const theme = useTheme();

  const modalRoot = document.getElementById('root');
  if (!modalRoot || !learner) {
    return null;
  }

  return createPortal(
    <ModalWrapper>
      <ModalBase>
        <HeaderSection learner={learner} onClose={onCloseModal} />
        <SectionWrapper>
          <DividerRow color={theme.colors.textSecondary}>
            <ProfileSection learner={learner} style={{ order: -1 }} />
            <TrackSection learner={learner} />
          </DividerRow>
        </SectionWrapper>
        <SectionWrapper>
          <TabbedContainer
            tabs={[
              {
                name: 'Notes',
                componentFn: () => <Notes learner={learner} />,
              },
              {
                name: 'Submissions',
                componentFn: () => <LearnerSubmissions learner={learner} />,
              },
              {
                name: 'Onboarding Survey',
                componentFn: () => <OnboardingAnswers learner={learner} />,
              },
            ]}
          />
        </SectionWrapper>
      </ModalBase>
    </ModalWrapper>,
    modalRoot
  );
}
