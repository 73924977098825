import { useTheme } from 'styled-components';
import useMentorSubmissionsContext from '../../hooks/use-mentor-submissions';
import {
  MentorLearner,
  ProjectSubmission,
  ActivitySubmission,
} from '../../hydra';
import Table from '../Table';
import * as tableConfigs from '../../table-configs';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default function LearnerSubmissions({
  learner,
}: {
  learner: MentorLearner;
}) {
  const { value: submissionsResponse } = useMentorSubmissionsContext();
  const theme = useTheme();
  const learnerSubmissions = (submissionsResponse?.submissions ?? []).filter(
    (sub) => sub.learner.id === learner.learner.id
  );

  const onOpenSubmission = (sub: ProjectSubmission | ActivitySubmission) => {
    window.open(
      `${window.location.protocol}/submissions?submission=${sub.submission.id}&type=${sub.type}`,
      '_blank'
    );
  };

  return (
    <div>
      <Table<ProjectSubmission | ActivitySubmission>
        filters={[]}
        hoverTitle="Open submission in new page"
        onClick={onOpenSubmission}
        data={learnerSubmissions}
        columnConfigs={tableConfigs.submissions.getColumns({ theme })}
        defaultSortDirection="desc"
        defaultSortIndex={6}
        fallbackCopy="No submissions"
      />
    </div>
  );
}
