import dayjs from 'dayjs';
import React from 'react';
import useAuth0 from '../../hooks/use-auth0';
import useMentorSubmissionsContext from '../../hooks/use-mentor-submissions';
import { ActivitySubmission, postActivitySubmissionReview } from '../../hydra';
import Button from '../Button';
import { Ratings } from './Ratings';
import utc from 'dayjs/plugin/utc';
import styled from 'styled-components';
import {
  getSubmissionSecondsSpent,
  SubmissionsTimeSpentContext,
} from '../../contexts/submissions-time-spent';
dayjs.extend(utc);

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const MAX_REVIEW_LENGTH = 3000;

const ValidationLabel = styled.p`
  font-size: 0.75em;
  color: ${({ theme }) => theme.colors.textDanger};
  margin: 0.2em 0;
`;

export default function Review({
  submission,
  onPostReview,
  questionsComments,
  onChangeQuestionsComments,
  hasSeenAllQuestions,
  seenAllQuestionsWarning,
  setSeenAllQuestionsWarning,
}: {
  submission: ActivitySubmission;
  onPostReview: () => void;
  questionsComments: string[];
  onChangeQuestionsComments: (index: number, value: string) => void;
  hasSeenAllQuestions: boolean;
  seenAllQuestionsWarning: boolean;
  setSeenAllQuestionsWarning: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { getTokenSilently } = useAuth0();
  const { retry: refreshSubmissions, loading: isRefreshingSubmissions } =
    useMentorSubmissionsContext();
  const [didTriggerRefresh, setDidTriggerRefresh] = React.useState(false);
  const [didSubmitReview, setDidSubmitReview] = React.useState(false);

  const isReviewed = !!submission.review?.date;
  const [feedback, setFeedback] = React.useState(
    submission.review?.feedback || ''
  );
  const [score, setScore] = React.useState(submission.review?.score || null);

  const didInputsChange =
    feedback !== submission.review?.feedback ||
    score !== submission.review?.score ||
    questionsComments.some(
      (comment, i) =>
        comment ?? '' !== submission?.review?.questions_comments?.[i] ?? ''
    );

  const [submissionsIntervals] = React.useContext(SubmissionsTimeSpentContext);

  const didHitCharacterLimit =
    feedback.length + questionsComments.join('').length > MAX_REVIEW_LENGTH;

  const onSubmitReview = async () => {
    const token = await getTokenSilently();
    if (token && score && (feedback || questionsComments.some(Boolean))) {
      setDidSubmitReview(true);

      await postActivitySubmissionReview(token, {
        activityReviewId: submission.submission.id,
        score,
        review: feedback,
        questionsComments,
        secondsSpent: getSubmissionSecondsSpent({
          submissionType: submission.type,
          submissionId: submission.submission.id,
          submissionsIntervals,
        }),
      });
      refreshSubmissions();
      setDidTriggerRefresh(true);
    }
  };
  // we cannot await "refreshSubmissions"
  // we react to its loading state change, and trigger onPostReview after it's done loading, knowing that we refreshed it before
  React.useEffect(() => {
    if (didTriggerRefresh && !isRefreshingSubmissions) {
      onPostReview();
      setDidTriggerRefresh(false);
      setDidSubmitReview(false);
    }
  }, [didTriggerRefresh, isRefreshingSubmissions, onPostReview]);

  return (
    <div style={{ marginTop: '1em' }}>
      <ul style={{ margin: '0' }}>
        <li>
          {submission.review.date
            ? `Reviewed at: ${dayjs
                .utc(submission.review.date)
                .format('DD/MM/YYYY - HH:mm')}`
            : 'Not reviewed yet'}
        </li>
      </ul>
      <div style={{ flex: 1, marginTop: '1em' }}>
        <Row style={{ gap: '1em' }}>
          <div style={{ flex: 1 }}>
            <h3>Feedback</h3>

            <p style={{ margin: '1em 0 0.5em 0' }}>
              Let your learner know how they did and what they could improve!
            </p>

            <textarea
              style={{
                paddingBottom: 0,
                borderBottom: 0,
                marginBottom: 0,
                resize: 'none',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
              value={questionsComments
                .map((comment, i) => `Q${i + 1} - ${comment}`)
                .filter((_, i) => questionsComments[i])
                .join('\n')}
              readOnly
              disabled
              rows={Math.max(1, questionsComments.filter(Boolean).length)}
            />
            <textarea
              style={{
                marginTop: 0,
                borderTop: 0,
                resize: 'none',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
              value={feedback}
              rows={feedback.split('\n').length}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Please enter your review here.."
              onFocusCapture={(e) => {
                if (!seenAllQuestionsWarning && !hasSeenAllQuestions && !feedback) {
                  window.alert(`It looks like you might have missed a question or two!
Check-out all the questions to use this feedback box.`);
                  setSeenAllQuestionsWarning(true);
                }
              }}
            />
            {didHitCharacterLimit && (
              <ValidationLabel>
                Oops! You've exceeded the character limit, please shorten your
                message.
              </ValidationLabel>
            )}
          </div>
        </Row>
        <h3 style={{ marginTop: '1em' }}>Score</h3>
        <Ratings score={score} setScore={setScore} />
        <Button
          primary
          disabled={
            didHitCharacterLimit ||
            didSubmitReview ||
            !(feedback || questionsComments.some((x) => x)) ||
            !score ||
            !didInputsChange
          }
          onClick={() => onSubmitReview()}
          style={{ marginTop: '1em' }}
          isLoading={didSubmitReview}
        >
          {isReviewed ? 'Edit review' : 'Submit review'}
        </Button>
      </div>
    </div>
  );
}
