import React from 'react';
import styled from 'styled-components';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  primary?: boolean;
  isLoading?: boolean;
}

export const ButtonWrapper = styled.button.attrs<ButtonProps>(
  ({ primary, type = 'button', className = '' }) => ({
    type,
    className: `button ${primary ? 'primary' : ''} ${className}`,
  })
)``;

export default function Button({
  children,
  isLoading = false,
  ...props
}: ButtonProps) {
  return (
    <ButtonWrapper {...props}>
      {children}
      {isLoading && <i className="fas fa-spinner fa-spin" />}
    </ButtonWrapper>
  );
}
