import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useGetMentorTasks } from '../hooks/use-hydra';
import {
  getConfirmSkillsUrl,
  getScheduleInterviewUrl,
  getSignContractUrl,
} from '../routing';
import Button from './Button';
import Card from './Card';
import Loader from './Loader';

const TasksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export default function MentorTasks() {
  const { value, loading: isLoading, error } = useGetMentorTasks();

  if (isLoading) {
    return <Loader />;
  }
  if (error || !value) {
    return <h4>Sorry, it seems something went wrong :(.</h4>;
  }

  const { tasks } = value;
  const openTasks = tasks.filter((task) => !task.isCompleted);
  const closedTasks = tasks.filter((task) => task.isCompleted);

  return (
    <>
      <h1>Your tasks</h1>
      <h3>Open tasks</h3>
      {openTasks.length ? (
        <TasksWrapper>
          {openTasks.map((task, index) => (
            <MentorTask {...task} key={`${index}-${task.type}-open`} />
          ))}
        </TasksWrapper>
      ) : (
        <NoTasks />
      )}
      {closedTasks.length ? (
        <>
          <h3 style={{ marginTop: '2em' }}>Completed tasks</h3>
          <TasksWrapper>
            {closedTasks.map((task, index) => (
              <DisabledOverlay>
                <MentorTask {...task} key={`${index}-${task.type}-closed`} />
              </DisabledOverlay>
            ))}
          </TasksWrapper>
        </>
      ) : null}
    </>
  );
}

function NoTasks() {
  return (
    <>
      <p>You're all done for now!</p>{' '}
      <img
        alt=""
        src="https://i.pinimg.com/originals/3f/f1/a2/3ff1a2ec82b097bdb404de49bd12ad7b.png"
        height={150}
      ></img>
    </>
  );
}

const MentorTaskCard = styled(Card)`
  max-width: 50%;
`;

function MentorTask({
  type,
  isCompleted,
}: {
  type: string;
  isCompleted: boolean;
}) {
  const history = useHistory();

  if (type === 'ONBOARDING_COMPLETE_PROFILE') {
    return (
      <GenericMentorTask
        title="Complete Profile"
        subtitle="To proceed, please take a moment to complete your profile."
        onClick={null}
      />
    );
  }

  if (type === 'ONBOARDING_SKILLS_TEST') {
    return (
      <GenericMentorTask
        title={'Complete the Skills Test'}
        subtitle={
          'This task should take about 30 minutes per subject to complete. It’ll focus on your mentoring ability as well as technical knowledge.'
        }
        onClick={isCompleted ? null : () => history.push(getConfirmSkillsUrl())}
      />
    );
  }

  if (type === 'ONBOARDING_INTERVIEW') {
    return (
      <GenericMentorTask
        title={'Schedule an onboarding call with Enki Ops'}
        subtitle={
          'During this call, we will cover the main responsibilities of an Enki Mentor, as well as, a quick tour of our platform.'
        }
        onClick={
          isCompleted ? null : () => history.push(getScheduleInterviewUrl())
        }
      />
    );
  }

  if (type === 'ONBOARDING_CONTRACT') {
    return (
      <GenericMentorTask
        title={'Time to sign your contract'}
        subtitle={
          'Please sign your Enki contract to finalize your agreement as an Enki Mentor.'
        }
        onClick={isCompleted ? null : () => history.push(getSignContractUrl())}
      />
    );
  }

  if (type === 'ONBOARDING_SLACK_JOIN') {
    return (
      <GenericMentorTask
        title="Accept the Slack Community Invite"
        subtitle="As a final step, please join our Slack community, this is the main tool you will use to communicate with your learners."
        onClick={null}
      />
    );
  }

  return null;
}

const DisabledOverlay = styled.div`
  pointer-events: none;
  opacity: 0.5;
`;

const TaskSubtitle = styled.p`
  font-style: italic;
  margin-bottom: 1em;
`;

function GenericMentorTask({
  title,
  subtitle,
  onClick,
}: {
  title: string;
  subtitle: string;
  onClick: null | (() => void);
}) {
  return (
    <MentorTaskCard>
      <h3>{title}</h3>
      <TaskSubtitle>{subtitle}</TaskSubtitle>
      {onClick && (
        <Button primary onClick={onClick}>
          Open task
        </Button>
      )}
    </MentorTaskCard>
  );
}
