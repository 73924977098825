import React from 'react';
import ReactSelect, { StylesConfig } from 'react-select';
import { DefaultTheme, useTheme } from 'styled-components';

export default function Select({
  innerRef,
  value,
  options,
  palceholder,
  onChange,
  isClearable,
  isMulti,
  isSearchable,
}: {
  innerRef?: React.Ref<any>;
  value?: { label: string; value: string };
  options?: { label: string; value: string }[];
  palceholder?: string;
  onChange?: (val: any, triggeredAction: any) => void;
  isClearable?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
}) {
  const theme = useTheme();

  return (
    <ReactSelect
      ref={innerRef}
      value={value}
      styles={getSelectCustomStyles(theme)}
      options={options}
      placeholder={palceholder}
      onChange={onChange}
      isClearable={isClearable}
      isMulti={isMulti}
      isSearchable={isSearchable}
    />
  );
}

// duplicated from enkicamp-student
// https://github.com/sagelabs/enkicamp-student/blob/36f1c59c51e7583ecad1b7fb82bea04b70679bfd/src/components/Onboarding/Typeform/InputTimeTimezone.jsx#L139
const getSelectCustomStyles = (theme: DefaultTheme): StylesConfig => ({
  menu: (provided) => ({
    ...provided,
    backgroundColor: theme.colors.sectionBg,
  }),

  control: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderRadius: theme.dimensions.sectionBorderRadius,
    borderColor: isFocused ? theme.colors.primaryBorder : '#ccc',
    boxShadow: isFocused ? 'none' : provided.boxShadow,
    '&:hover': {
      borderColor: isFocused ? theme.colors.primaryBorder : '#ccc',
    },
  }),

  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    backgroundColor:
      isSelected || isFocused ? theme.colors.textSecondary : 'transparent',
    color: isSelected || isFocused ? '#ffffff' : theme.colors.textSecondary,
    cursor: isSelected ? 'not-allowed' : 'default',
  }),

  singleValue: (provided) => ({
    ...provided,
    color: theme.colors.textSecondary,
  }),

  input: (provided) => ({
    ...provided,
    color: theme.colors.textSecondary,
    'input[type="text"]:focus': {
      boxShadow: 'none',
    },
  }),
});
