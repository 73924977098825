import React from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import get from 'lodash.get';
import styled from 'styled-components';
import Alert from '../components/Alert';
import Button, { ButtonWrapper } from '../components/Button';
import { getHomeUrl } from '../routing';

const ErrorWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > ${ButtonWrapper}, > a {
    margin-top: 2em;
  }
`;

export default function RequestError({
  error,
  retry,
}: {
  error: any;
  retry: () => void;
}) {
  return (
    <ErrorWrapper>
      <Alert
        type="error"
        text={get(error, 'response.data.message', error.message)}
      />
      {retry && (
        <Button onClick={retry}>
          <i className="fas fa-fw fa-sync" />
          Try again
        </Button>
      )}
      <Link to={getHomeUrl()}>
        <i className="fas fa-fw fa-home" />
        Go Home
      </Link>
    </ErrorWrapper>
  );
}
