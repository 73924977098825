import React from 'react';
import { useVerifyAuthUser } from '../hooks/use-hydra';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const { loading, error, value: user, retry } = useVerifyAuthUser();

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading: loading,
        error,
        retry,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
