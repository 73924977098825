import useAsyncRetryWithCrashReporter from './use-async-retry-with-crash-reporter';
import useAuth0 from './use-auth0';
import axios from 'axios';
import {
  getHealthcheck,
  verifyAuthUser,
  getMentorProfile,
  MentorProfile,
  getMentorTasks,
  MentorTasksResponse,
  getMentorSubmissions,
  MentorSubmissionsResponse,
  MentorLearnersResponse,
  getMentorLearners,
} from '../hydra';

export function useHealthcheck() {
  const state = useAsyncRetryWithCrashReporter(async () => {
    const result = await getHealthcheck();
    return result;
  }, []);

  return state;
}

export function useVerifyAuthUser() {
  const { isAuthenticated, getTokenSilently, isLoading } = useAuth0();

  const state = useAsyncRetryWithCrashReporter(async () => {
    if (isLoading) {
      return null;
    }

    // we only verify authenticated users
    // if a user is not authenticated by auth0
    // we skip our own auth verification
    if (!isAuthenticated) {
      return null;
    }

    const token = await getTokenSilently();
    if (!token) {
      return;
    }
    const authUser = await verifyAuthUser(token);
    return authUser;
  }, [isAuthenticated, isLoading]);

  return state;
}

export type GetMentorProfileResponse = {
  isMentor: boolean;
  shouldUpdateProfile: boolean;
  profile: MentorProfile | null;
};

export function useGetMentorProfile() {
  const { getTokenSilently, isLoading } = useAuth0();

  const getMentorProfileHandler = async (): Promise<
    GetMentorProfileResponse | undefined
  > => {
    if (isLoading) {
      return;
    }
    const token = await getTokenSilently();
    if (!token) {
      return;
    }

    try {
      const mentorProfile = await getMentorProfile(token);
      return {
        isMentor: true,
        ...mentorProfile,
      };
    } catch (error) {
      // we expect this error to happen when the user is not a mentor
      if (axios.isAxiosError(error) && error?.response?.status === 403) {
        return {
          isMentor: false,
          shouldUpdateProfile: false,
          profile: null,
        };
      } else {
        throw error;
      }
    }
  };

  const state = useAsyncRetryWithCrashReporter<GetMentorProfileResponse>(
    getMentorProfileHandler,
    [isLoading]
  );

  return state;
}

export function useGetMentorTasks() {
  const { getTokenSilently, isLoading } = useAuth0();

  const getMentorTasksHandler = async (): Promise<MentorTasksResponse> => {
    if (isLoading) {
      return { tasks: [] };
    }

    const token = await getTokenSilently();
    if (!token) {
      return { tasks: [] };
    }

    const response = await getMentorTasks(token);
    return response;
  };

  const state = useAsyncRetryWithCrashReporter<MentorTasksResponse>(
    getMentorTasksHandler,
    [isLoading]
  );

  return state;
}

export function useGetMentorSubmissions() {
  const { getTokenSilently, isLoading } = useAuth0();

  const getMentorSubmissionsHandler =
    async (): Promise<MentorSubmissionsResponse> => {
      if (isLoading) {
        return { submissions: [] };
      }

      const token = await getTokenSilently();
      if (!token) {
        return { submissions: [] };
      }

      const response = await getMentorSubmissions(token);
      return response;
    };

  const state = useAsyncRetryWithCrashReporter<MentorSubmissionsResponse>(
    getMentorSubmissionsHandler,
    [isLoading]
  );

  return state;
}

export function useGetMentorLearners() {
  const { getTokenSilently, isLoading } = useAuth0();

  const getMentorLearnersHandler =
    async (): Promise<MentorLearnersResponse> => {
      if (isLoading) {
        return { learners: [] };
      }

      const token = await getTokenSilently();
      if (!token) {
        return { learners: [] };
      }

      const response = await getMentorLearners(token);
      return response;
    };

  const state = useAsyncRetryWithCrashReporter<MentorLearnersResponse>(
    getMentorLearnersHandler,
    [isLoading]
  );

  return state;
}
