import React from 'react';
import styled from 'styled-components';
import Navbar from './Navbar';
import PageWrapper from './PageWrapper';
import useAuth0 from '../hooks/use-auth0';
import useCleanGlobalClasses from '../hooks/use-clean-global-classes';

const PageContentWrapper = styled.div`
  padding: 2em;
  background-color: ${(prop) => prop.theme.colors.sectionBg};
  border: 1px solid ${(prop) => prop.theme.colors.sectionBorder};
  border-radius: ${(prop) => prop.theme.dimensions.sectionBorderRadius};
`;

export default function SimplePage({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isLoading, logout, auth0User, isAuthenticated } = useAuth0();

  useCleanGlobalClasses({ isLoading });

  return (
    <>
      <Navbar
        auth={{
          isLoading,
          isAuthenticated,
          user: auth0User,
          logout,
        }}
      />
      <PageWrapper>
        <PageContentWrapper>{children}</PageContentWrapper>
      </PageWrapper>
    </>
  );
}
