import React from 'react';
import styled from 'styled-components';
import Markdown from '../../components/Markdown';
import SimplePage from '../../components/SimplePage';

const md = `# Cycles and Tracks

## Tracks

Enki's content modules, Tracks, are designed to be accessible to both complete novices and technically skilled employees across the whole organization.

The current lineup is:
- [Data 101](https://www.notion.so/f9b14e13ce324fe8ad996fd97b62a0dd) - A broad overview of data analysis that includes: common top mistakes, correlation, forecasting, KPIs, funnel analysis, and A/B testing.
- KYD - Know Your Data is a fully customized module written by the Enki team in close cooperation with the data team at a partner company.
- [Looker](https://www.notion.so/ff978baa88c844429a878a8662cb81c9) - The fundamentals of Looker and how to work with Explores, Looks, and Dashboards.
- Project - A track dedicated to working on a more important, time-intensive project. Commonly taken after a Learner completes a Data 101 and a BI Tool Track.
- Sheets - The fundamentals of working with Sheets or Microsoft Excel.
- [SQL](https://www.notion.so/b2de38907ed54020a87b233474778b89) - The basics of SQL: SELECT and WHERE clauses, filtering and sorting as well as working with multiple tables and different types of JOINs.
- [Tableau](https://www.notion.so/75b441d8ab1c462db7bcdce6bebddb0c) - The fundamentals of Tableau and how to work with Worksheets, Dashboards, and Stories.

## Cycles

4-week Cycles are at the heart of the Enki experience. The first Cycle of every Track for a Learner begins with a Kick-off Call, hosted by Enki Ops, in which Learners are told about what to expect throughout the upcoming Cycle. The Cycle culminates with a Showcase, in which Learners showcase either a [Project](https://mentor.enki.com/resources/projects) or something useful they've learned in the last few weeks. Between the Kick-off Call and Showcase, in an ideal scenario, Learners complete all their Tracks' activities and a project.

![](https://img.enkipro.com/c564d353259feee55f0ed543df54682e.png)
_This diagram represents the ideal scenario in which a Learner is fully engaged - completing all their activities on time and progressing unto their project straight away._

After the initial Cycle ends, a new cycle begins. If the learner is starting on a new Track, the Cycle will closely resemble the format above. 

However, most Cycles are a continuation of a previous Cycle in the same Track. In this 'continuation cycle' a Learner completes any activities they may have skipped in the first Cycle and continues working on a project, if not starting and finishing a new project. 

![](https://img.enkipro.com/e10d870d8e9f7b63b961743942bc1a95.png)
_This diagram represents the typical scenario in which a Learner has a few activities leftover from their previous cycle and needs to either finish their project or start a completely new one if they have the time._

Note that this Cycle is still fairly similar to a typical initial Cycle except Learners will not need to complete a Kick-off call as they are not starting a new Track.

## Learner's Journey

After a few Cycles of a certain Track, in conjunction with their Mentor, Learners are able to choose the next Track in their EnkiCamp experience. It is a Mentor's responsibility to help their Learner identify which next Track makes the most sense out of the choices available, ensuring Learners receive the highest benefit from their EnkiCamp experience.

![](https://img.enkipro.com/b98c1cb2a8a74c41a121a5f822f05f1a.png)
_This is an example of a possible learning journey a Learner could take. In reality, the Tracks will come in the order/placement that makes the most sense for the Learner._


`;

const HalfWidthContainer = styled.div`
  margin: 0 25%;

  p,
  ul,
  ol {
    margin-bottom: 1em;
  }
`;

export default function CyclesAndTracks() {
  return (
    <SimplePage>
      <HalfWidthContainer>
        <Markdown md={md} />
      </HalfWidthContainer>
    </SimplePage>
  );
}
