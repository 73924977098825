import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useAsync } from 'react-use';
import useAuth0 from '../hooks/use-auth0';

const AuthenticatedRoute = ({ path, children, ...rest }: {
  path: string;
  children: React.ReactNode;
  targetUrl?: string;
  exact?: boolean;
}) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const targetUrl = rest.targetUrl || location.pathname;

  useAsync(async () => {
    if (isLoading || isAuthenticated) {
      return;
    }
    return loginWithRedirect({
      appState: { targetUrl },
    });
  }, [isLoading, isAuthenticated, loginWithRedirect, targetUrl]);

  return (
    <Route path={path} {...rest}>
      {isAuthenticated && children}
    </Route>
  );
};

export default AuthenticatedRoute;
