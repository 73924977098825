import { rawTimeZones } from '@vvo/tzdb';

type Timezone = { label: string; value: string };

const timezones = rawTimeZones.map<Timezone>(
  ({ name: timezoneName, mainCities, alternativeName }) => ({
    label: `${timezoneName} - ${alternativeName} - ${mainCities.join(', ')}`,
    value: timezoneName,
  })
);
export default timezones;
