import React from 'react';
import { ActivitySubmission, learnerDashboardURL } from '../../hydra';
import HeaderSection from './Header';
import { useTheme } from 'styled-components';
import {
  Row,
  Pressable,
  TagsWrapper,
  SectionWrapper,
  DividerRow,
  QuestionsWrapper,
} from './common';
import ActivityReview from './ActivityReview';
import ActivityQuestions from './ActivityQuestions';
import Tag from '../Tag';
import { Ratings } from './Ratings';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default function ActivitySubmissionContent({
  submission,
  onCloseModal,
  onPostReview,
}: {
  submission: ActivitySubmission;
  onCloseModal: () => void;
  onPostReview: () => void;
}) {
  const theme = useTheme();

  const [questionsComments, setQuestionsComments] = React.useState(
    submission.questions.map(
      (_, i) => submission?.review?.questions_comments?.[i] || ''
    )
  );
  const onChangeQuestionsComments = (index: number, value: string) => {
    setQuestionsComments(
      questionsComments.map((comment, idx) => (idx === index ? value : comment))
    );
  };

  const [hasSeenAllQuestions, setHasSeenAllQuestions] = React.useState(false);
  const [seenAllQuestionsWarning, setSeenAllQuestionsWarning] = React.useState(false);


  return (
    <>
      <HeaderSection submission={submission} onClose={onCloseModal} />
      <SectionWrapper>
        <DividerRow color={theme.colors.textSecondary}>
          <ActivityContentDetailsSection
            submission={submission}
            style={{ order: -1 }}
          />
          <ActivitySubmissionSection submission={submission} />
        </DividerRow>
      </SectionWrapper>
      <ActivityQuestionsSection
        submission={submission}
        questionsComments={questionsComments}
        onChangeQuestionsComments={onChangeQuestionsComments}
        setHasSeenAllQuestions={setHasSeenAllQuestions}
      />
      <ActivityReviewSection
        submission={submission}
        onPostReview={onPostReview}
        questionsComments={questionsComments}
        onChangeQuestionsComments={onChangeQuestionsComments}
        hasSeenAllQuestions={hasSeenAllQuestions}
        seenAllQuestionsWarning={seenAllQuestionsWarning}
        setSeenAllQuestionsWarning={setSeenAllQuestionsWarning}
      />
    </>
  );
}

const ActivityContentDetailsSection = ({
  submission,
  style,
}: {
  submission: ActivitySubmission;
  style: React.CSSProperties;
}) => {
  const theme = useTheme();

  const onOpenContent = () => {
    window.open(
      `${learnerDashboardURL}/preview/${submission.content.slug}/dashboard`,
      '_blank'
    );
  };

  const onOpenMission = () => {
    window.open(
      `${learnerDashboardURL}/preview/missions/${submission.mission.slug}`,
      '_blank'
    );
  };

  const onOpenActivity = () => {
    window.open(
      `${learnerDashboardURL}/preview/missions/${submission.mission.slug}/activities/${submission.activity.slug}`,
      '_blank'
    );
  };

  return (
    <div style={{ flex: 1, ...style }}>
      <Row>
        <Pressable onClick={onOpenActivity} title="Open activity preview">
          <h2 style={{ marginBottom: '0' }}>
            Activity: {submission.activity.name}
            {'  '}
            <i className="fa-solid fa-arrow-up-right-from-square" />
          </h2>
        </Pressable>
      </Row>
      <TagsWrapper style={{ flexDirection: 'column', marginTop: '1em' }}>
        <TagsWrapper style={{ alignItems: 'center' }}>
          <Pressable
            onClick={onOpenContent}
            title="Open content preview dashboard"
          >
            <Tag color={theme.accents.lightYellow} style={{ margin: '0' }}>
              Course - {submission.content.name}
              {'  '}
              <i className="fa-solid fa-arrow-up-right-from-square" />
            </Tag>
          </Pressable>
          <Pressable onClick={onOpenMission} title="Open mission preview">
            <Tag color={theme.accents.lightYellow} style={{ margin: '0' }}>
              {submission.week.name} / {submission.mission.name}
              {'  '}
              <i className="fa-solid fa-arrow-up-right-from-square" />
            </Tag>
          </Pressable>
        </TagsWrapper>
        <TagsWrapper>
          <Tag
            color={
              submission.review.date
                ? theme.accents.lightTeal
                : theme.accents.lightRed
            }
            style={{ margin: '0' }}
          >
            {submission.review.date ? 'Reviewed' : 'Not Reviewed'}
          </Tag>
          <Tag color={theme.accents.lightBlue} style={{ margin: '0' }}>
            Track - {submission.track.name}
          </Tag>
        </TagsWrapper>
      </TagsWrapper>
    </div>
  );
};

const ActivityReviewSection = ({
  submission,
  onPostReview,
  questionsComments,
  onChangeQuestionsComments,
  hasSeenAllQuestions,
  seenAllQuestionsWarning,
  setSeenAllQuestionsWarning,
}: {
  submission: ActivitySubmission;
  onPostReview: () => void;
  questionsComments: string[];
  onChangeQuestionsComments: (index: number, value: string) => void;
  hasSeenAllQuestions: boolean;
  seenAllQuestionsWarning: boolean;
  setSeenAllQuestionsWarning: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <SectionWrapper>
      <h2 style={{ margin: '0' }}>Mentor Review</h2>
      <ActivityReview
        key={`activity-review-${submission.submission.id}-${submission.type}`}
        submission={submission}
        onPostReview={onPostReview}
        questionsComments={questionsComments}
        onChangeQuestionsComments={onChangeQuestionsComments}
        hasSeenAllQuestions={hasSeenAllQuestions}
        seenAllQuestionsWarning={seenAllQuestionsWarning}
        setSeenAllQuestionsWarning={setSeenAllQuestionsWarning}
      />
    </SectionWrapper>
  );
};

const ActivityQuestionsSection = ({
  submission,
  questionsComments,
  onChangeQuestionsComments,
  setHasSeenAllQuestions,
}: {
  submission: ActivitySubmission;
  questionsComments: string[];
  onChangeQuestionsComments: (index: number, value: string) => void;
  setHasSeenAllQuestions: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <SectionWrapper>
      <QuestionsWrapper>
        <ActivityQuestions
          key={`activity-questions-${submission.submission.id}-${submission.type}`}
          submission={submission}
          questionsComments={questionsComments}
          onChangeQuestionsComments={onChangeQuestionsComments}
          setHasSeenAllQuestions={setHasSeenAllQuestions}
        />
      </QuestionsWrapper>
    </SectionWrapper>
  );
};

const ActivitySubmissionSection = ({
  submission,
}: {
  submission: ActivitySubmission;
}) => {
  return (
    <div style={{ flex: 1 }}>
      <h2 style={{ margin: '0' }}>Learner Submission</h2>
      <ul style={{ margin: '1em 0' }}>
        <li>
          {dayjs.utc(submission.submission.date).format('DD/MM/YYYY - HH:mm')}
        </li>
        <li>
          {Math.floor(submission.submission.seconds / 60)} minutes{' '}
          {Math.floor(submission.submission.seconds % 60)} seconds
        </li>
      </ul>
      <h4>Learner Feedback</h4>

      <p style={{ margin: '0 0 1em 0' }}>
        {submission.submission.feedback || 'No learner feedback'}
      </p>
      <h4>Learner Difficulty Rating</h4>
      <Ratings score={submission.submission.difficulty} isDisabled={true} />
    </div>
  );
};
