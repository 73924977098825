import styled from 'styled-components';

const SectionWrapper = styled.div`
  margin-bottom: 3em;
`;

const Pressable = styled.div`
  :hover {
    transform: translateY(-0.1em);
    transition: transform 0.5s;
    cursor: pointer;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const DividerRow = styled(Row)`
  gap: 1.5em;
  :before {
    content: '';
    border: 1px solid ${(props) => props.color || 'white'};
    align-self: stretch;
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
`;

const QuestionsWrapper = styled.div`
  padding: 1.5em;
  border: solid 1px rgba(255, 255, 255, 0.15);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 1em;
`;

export {
  SectionWrapper,
  Pressable,
  Row,
  DividerRow,
  TagsWrapper,
  QuestionsWrapper,
};
