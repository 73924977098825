import axios from 'axios';

export const apiURL = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return process.env.REACT_APP_HTTPS
        ? 'https://local.desktop.enki.com:1234'
        : 'http://localhost:1234';
    case 'staging':
      return 'https://staging.desktop.enki.com';
    case 'production':
      return 'https://desktop.enki.com';
    default:
      return 'http://localhost:1234';
  }
})();

export const learnerDashboardURL = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return 'http://localhost:3000';
    case 'staging':
      return 'https://staging.learn.enki.com';
    case 'production':
      return 'https://learn.enki.com';
    default:
      return 'http://localhost:3000';
  }
})();

axios.defaults.baseURL = apiURL;
axios.defaults.headers.common['Accept-Encoding'] = 'gzip,identity';

export async function getHealthcheck() {
  const response = await axios({
    method: 'get',
    url: '/healthcheck',
  });
  return response.data;
}

export async function verifyAuthUser(token: string) {
  const response = await axios({
    method: 'get',
    url: '/verify-user',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export type GetMentorProfileResponseRaw = {
  shouldUpdateProfile: boolean;
  profile: MentorProfile;
};

export type MentorProfile = {
  name: string | null;
  company: string | null;
  status: string;
  linkedinURL: string | null;
  timezonePrimary: string | null;
  timezoneSecondary: string | null;
  address: string | null;
  availability: {
    slots: Array<{
      day: string;
      period: string;
      isAvailable: boolean;
    }>;
    updatedAt: string;
    extraComment: string | null;
    hours: string | null;
  } | null;
  skillsExtraComment: string | null;
  skills: Array<{
    identifier: string;
    name: string;
    isSelected: boolean;
    isConfirmed: boolean;
    isSubmitted: boolean;
  }>;
  contract: {
    url: string | null;
  };
};

export async function getMentorProfile(token: string) {
  const response = await axios.request<GetMentorProfileResponseRaw>({
    method: 'get',
    url: `/mentor/profile`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export type MentorTasksResponse = {
  tasks: Array<{ type: string; isCompleted: boolean }>;
};
export async function getMentorTasks(token: string) {
  const response = await axios.request<MentorTasksResponse>({
    method: 'get',
    url: '/mentor/tasks',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export type MentorSubmissionsResponse = {
  submissions: Array<ProjectSubmission | ActivitySubmission>;
};

export type ProjectSubmission = {
  type: 'project';
  name: 'Project';
  content: {
    name: string;
    slug: string;
  };
  track: {
    name: string;
    slug: string;
  };
  learner: {
    name: string;
    id: number;
  };
  questions: Array<{
    text: string;
    answer: string;
  }>;
  review: {
    date: string;
    score: number;
    internal: string;
    learner: string;
  };
  submission: {
    date: string;
    id: number;
    fileLinks: Array<string>;
    externalLinks: Array<string>;
  };
};

export type ActivitySubmission = {
  type: 'activity';
  activity: {
    name: string;
    slug: string;
    question: string;
    isCore: boolean;
  };
  mission: {
    name: string;
    slug: string;
    index: string;
  };
  week: {
    name: string;
    slug: string;
    index: string;
  };
  content: {
    name: string;
    slug: string;
  };
  track: {
    name: string;
    slug: string;
  };
  questions: Array<{
    text: string;
    notes: string;
    solution: string;
    type: string;
  }>;
  review: {
    date: string;
    score: number;
    feedback: string;
    questions_comments: string[];
  };
  learner: {
    name: string;
    id: number;
  };
  submission: {
    date: string;
    id: number;
    seconds: number;
    difficulty: number;
    feedback: string;
  };
};

export async function getMentorSubmissions(token: string) {
  const response = await axios.request<MentorSubmissionsResponse>({
    method: 'get',
    url: '/mentor/submissions',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function postMentorProfile(
  token: string,
  data: {
    name: string;
    company: string;
    address: string;
    linkedinURL: string | null;
    availability: {
      slots: Array<{
        day: string;
        period: string;
        isAvailable: boolean;
      }>;
      extraComment: string | null;
      hours: string | null;
    };
    timezonePrimary: string | null;
    timezoneSecondary: string | null;
    skillsExtraComment: string | null;
  }
) {
  const response = await axios.request({
    method: 'post',
    url: `/mentor/profile`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return response.data;
}

export async function postSkillTest(
  token: string,
  data: {
    skillIdentifier: string;
    skillTest: Array<{ question: string; answer: string }>;
  }
) {
  const response = await axios.request({
    method: 'post',
    url: `/mentor/skill-test`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return response.data;
}

export async function confirmMentorInterviewTask(token: string) {
  const response = await axios.request({
    method: 'post',
    url: `/mentor/interview-task`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {},
  });
  return response.data;
}

export async function postActivitySubmissionReview(
  token: string,
  data: {
    activityReviewId: number;
    score: number;
    review: string;
    questionsComments: string[];
    secondsSpent: number;
  }
) {
  const response = await axios.request({
    method: 'post',
    url: `/mentor/submissions/activity`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return response.data;
}

export async function postProjectSubmissionReview(
  token: string,
  data: {
    projectSessionId: number;
    score: number;
    internalReview: string;
    learnerReview: string;
    secondsSpent: number;
  }
) {
  const response = await axios.request({
    method: 'post',
    url: `/mentor/submissions/project`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return response.data;
}

export type MentorLearnersResponse = {
  learners: MentorLearner[];
};

export type MentorLearner = {
  content: {
    name: string;
    slug: string;
  };
  track: {
    name: string;
    slug: string;
  };
  cycle: {
    startDate: Date;
    endDate: Date;
  };
  learner: {
    name: string;
    id: number;
    email: string;
    company: string;
    notes: string;
  };
  notes: Array<{
    date: string;
    type: string;
    text: string;
  }>;
  onboardingAnswers: Array<{
    question: string;
    answer: string;
  }>;
};

export async function getMentorLearners(token: string) {
  const response = await axios.request<MentorLearnersResponse>({
    method: 'get',
    url: '/mentor/learners',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function createLearnerNote(
  token: string,
  data: {
    learnerId: number;
    note: string;
    type: 'project' | 'company' | 'student';
  }
) {
  const response = await axios.request({
    method: 'put',
    url: `mentor/learners/notes`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return response.data;
}
