import React from 'react';

import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { ProjectSubmission, ActivitySubmission } from '../../hydra';
import { prop } from 'styled-tools';

import ActivitySubmissionContent from './ActivitySubmissionContent';
import ProjectSubmissionContent from './ProjectSubmissionContent';

const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  @media screen and (max-width: 768px) {
    padding: 1em;
  }
  padding: 2em;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
`;

const ModalBase = styled.div`
  @media screen and (max-width: 768px) {
    padding: 1em 1em;
  }
  padding: 4em 4em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 3;

  background-color: ${prop('theme.colors.sectionBg')};
  border: 1px solid ${prop('theme.colors.sectionBorder')};
  border-radius: 8px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export default function SubmissionModal({
  submission,
  onCloseModal,
  onPostReview,
}: {
  submission?: ProjectSubmission | ActivitySubmission;
  onCloseModal: () => void;
  onPostReview: () => void;
}) {
  const modalRoot = document.getElementById('root');
  if (!modalRoot || !submission) {
    return null;
  }

  return createPortal(
    <ModalWrapper>
      <ModalBase id="submission-modal-container">
        {submission.type === 'activity' ? (
          <ActivitySubmissionContent
            onCloseModal={onCloseModal}
            submission={submission}
            onPostReview={onPostReview}
          />
        ) : (
          <ProjectSubmissionContent
            onCloseModal={onCloseModal}
            submission={submission}
            onPostReview={onPostReview}
          />
        )}
      </ModalBase>
    </ModalWrapper>,
    modalRoot
  );
}
