import React from 'react';

export default function RejectedMentorHome() {
  return (
    <>
      <h1>Your onboarding</h1>
      <p>
        Thanks again for taking the time and interest in becoming an Enki
        Mentor.
      </p>
      <p>
        At this time, we have decided to move forward with other candidates who
        we believe most closely match our mentor requirements.
      </p>
      <p>
        We really appreciate you taking the time to speak with us and would
        encourage you to reach out again in the future to see if we have any
        mentor openings.
      </p>
      <img
        alt=""
        src="https://i.pinimg.com/originals/3f/f1/a2/3ff1a2ec82b097bdb404de49bd12ad7b.png"
        height={200}
      ></img>
    </>
  );
}
