import mixpanel from 'mixpanel-browser';

const { REACT_APP_ENV = 'development', REACT_APP_MIXPANEL_TOKEN = 'dummy' } =
  process.env;

const IS_DEV = REACT_APP_ENV === 'development';

if (!IS_DEV) {
  mixpanel.init(REACT_APP_MIXPANEL_TOKEN);
} else {
  console.log(
    `Skipping Analytics Mixpanel init as environment = ${REACT_APP_ENV}`
  );
}

export function setUser({
  id,
  email,
  name,
  isSignUp,
}: {
  id: number;
  email: string;
  name: string;
  isSignUp: boolean;
}) {
  if (!IS_DEV) {
    mixpanel.people.set({ email, name });
    if (isSignUp) {
      mixpanel.alias(id.toString());
    } else {
      mixpanel.identify(id.toString());
    }
  } else {
    console.log(
      `Skipping Analytics Mixpanel setUser as environment = ${REACT_APP_ENV}`
    );
  }
}

export function removeUser() {
  if (!IS_DEV) {
    mixpanel.reset();
  } else {
    console.log(
      `Skipping Analytics Mixpanel removeUser as environment = ${REACT_APP_ENV}`
    );
  }
}

export function trackEvent({
  event,
  properties,
}: {
  event: string;
  properties?: any;
}) {
  if (!IS_DEV) {
    mixpanel.track(event, properties);
  } else {
    console.log(
      `Skipping Analytics Mixpanel trackEvent for ${event} as environment = ${REACT_APP_ENV}`
    );
  }
}

export const EVENTS = {
  AUTHENTICATE: 'authenticate',
};
