import React from 'react';
import styled, { useTheme } from 'styled-components';
import { learnerDashboardURL, ActivitySubmission } from '../../hydra';
import Button from '../Button';
import Markdown from '../Markdown';
import queryString from 'query-string';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h4,
  p,
  code {
    color: ${(props) => props.color || 'white'};
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${(props) => props.color || 'white'};
  width: 100%;
`;

const IconSpan = styled.span<{
  isDisabled?: boolean;
  activeColor: string;
  inactiveColor: string;
}>`
  vertical-align: middle;
  color: ${(props) => props.inactiveColor};
  ${(props) =>
    !props.isDisabled &&
    `
    cursor: pointer;
    color: ${props.activeColor};
  `}
`;

export default function Questions({
  submission,
  questionsComments,
  onChangeQuestionsComments,
  setHasSeenAllQuestions,
}: {
  submission: ActivitySubmission;
  questionsComments: string[];
  onChangeQuestionsComments: (index: number, value: string) => void;
  setHasSeenAllQuestions: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const theme = useTheme();
  const [qIndex, setQIndex] = React.useState(0);
  const [seenQuestions, setSeenQuestions] = React.useState<number[]>([]);

  React.useEffect(() => {
    setSeenQuestions((sQ) => Array.from(new Set([...sQ, qIndex])));
  }, [qIndex]);

  React.useEffect(() => {
    if (seenQuestions.length === submission.questions.length) {
      setHasSeenAllQuestions(true);
    }
  }, [seenQuestions.length, submission.questions.length, setHasSeenAllQuestions]);

  const question = submission.questions?.[qIndex];

  const onPreview = () => {
    window.open(
      `${learnerDashboardURL}/preview/missions/${
        submission.mission.slug
      }/activities/${submission.activity.slug}?${queryString.stringify({
        question: qIndex,
        solutions: submission.questions.map((x) => x.solution || 'no answer'),
      })}`,
      '_blank'
    );
  };

  const qSolution = getQuestionSolution(question);

  return (
    <div>
      <h3>Question Description</h3>
      <ContentWrapper color={theme.colors.textSecondary}>
        <Markdown md={submission.activity.question} />
      </ContentWrapper>
      <Divider color={theme.colors.textSecondary} />
      <Row style={{ margin: '1em 0', alignItems: 'center', gap: '0.75em' }}>
        <h3 style={{ marginBottom: '0' }}>
          Question {qIndex + 1}/{submission.questions.length}
        </h3>
        <IconSpan
          activeColor={theme.colors.primary}
          inactiveColor={theme.colors.textSecondary}
          isDisabled={qIndex === 0}
          className="fa-solid fa-left fa-2x"
          onClick={() => {
            if (qIndex !== 0) {
              setQIndex(qIndex - 1);
            }
          }}
        />
        <IconSpan
          activeColor={theme.colors.primary}
          inactiveColor={theme.colors.textSecondary}
          isDisabled={qIndex === submission.questions.length - 1}
          className="fa-solid fa-right fa-2x"
          onClick={() => {
            if (qIndex !== submission.questions.length - 1) {
              setQIndex(qIndex + 1);
            }
          }}
        />
        <Button onClick={onPreview} style={{ fontSize: '0.5em' }}>
          Preview Answer
        </Button>
      </Row>

      <Row style={{ gap: '1em' }}>
        <div style={{ flex: 1 }}>
          <h5>Question Text</h5>
          <ContentWrapper color={theme.colors.textSecondary}>
            <Markdown md={question.text} />
          </ContentWrapper>
        </div>
        <div style={{ flex: 1 }}>
          <h5>Content Notes</h5>
          <ContentWrapper color={theme.colors.textSecondary}>
            <Markdown md={question.notes || 'No notes yet :('} />
          </ContentWrapper>
        </div>
      </Row>
      <h5>Learner Answer</h5>
      <ContentWrapper color={theme.colors.textSecondary}>
        <Markdown md={qSolution} />
      </ContentWrapper>

      <h5 style={{ marginTop: '1em' }}>Question Review</h5>
      <textarea
        value={questionsComments[qIndex]}
        placeholder="Add your review for this question here"
        onChange={(e) => onChangeQuestionsComments(qIndex, e.target.value)}
      />
    </div>
  );
}

function getQuestionSolution(question: ActivitySubmission['questions'][0]) {
  if (!question.solution) {
    return 'No Answer';
  }
  if (question.type === 'PLAYGROUND' || question.type === 'FREE_TEXT_SQL_CODE') {
    return `\`\`\`sql
${question.solution}
\`\`\``;
  }
  return question.solution;
}
