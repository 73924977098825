import React from 'react';
import Filters from './Filters';
import TableBody from './TableBody';
import isNil from 'lodash.isnil';

export type FiltersConfig<X> = Array<{
  options: Array<{ value: string; label: string }>;
  label: string;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  filterFn: (item: X, value: string) => boolean;
  type: 'dropdown';
  color?: string;
  defaultValue: string;
}>;

export type ColumnConfig<T> = {
  header: string;
  componentFn: (item: T) => React.ReactNode;
  sortFn?: (a: T, b: T) => number;
};

function Table<T extends Record<string, any>>({
  data,
  columnConfigs,
  filters,
  onClick,
  hoverTitle,
  fallbackCopy,
  canResetFilters,
  defaultSortIndex,
  defaultSortDirection,
}: {
  data: Array<T>;
  hoverTitle?: string;
  filters: FiltersConfig<T>;
  columnConfigs: Array<ColumnConfig<T>>;
  onClick?: (item: T) => void;
  fallbackCopy?: string;
  canResetFilters?: boolean;
  defaultSortIndex?: number;
  defaultSortDirection?: 'asc' | 'desc';
}) {
  const [filteredAndSortedData, setFilteredAndSortedData] =
    React.useState<Array<T>>(data);
  const [columnSortIndex, setColumnSortIndex] = React.useState<number | null>(
    isNil(defaultSortIndex) ? null : defaultSortIndex
  );
  const [columnSortDirection, setColumnSortDirection] = React.useState<
    'asc' | 'desc'
  >(defaultSortDirection || 'asc');

  React.useEffect(() => {
    setFilteredAndSortedData(
      applySorting(
        applyFilters<T>(data, filters),
        !isNil(columnSortIndex) ? columnConfigs[columnSortIndex] : null,
        columnSortDirection
      )
    );
  }, [filters, data, columnConfigs, columnSortDirection, columnSortIndex]);

  const onSort = (index: number) => {
    if (columnSortIndex === index) {
      setColumnSortDirection(columnSortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setColumnSortIndex(index);
      setColumnSortDirection('asc');
    }
  };

  return (
    <>
      <Filters filters={filters} canResetFilters={canResetFilters} />
      <TableBody<T>
        hoverTitle={hoverTitle}
        data={filteredAndSortedData}
        columnConfigs={columnConfigs}
        onClick={onClick}
        fallbackCopy={fallbackCopy}
        columnSortIndex={columnSortIndex}
        columnSortDirection={columnSortDirection}
        onSort={onSort}
      />
    </>
  );
}

function applyFilters<Z>(data: Array<Z>, filters: FiltersConfig<Z>): Array<Z> {
  let filteredData = data;
  filters.forEach((filter) => {
    filteredData = filteredData.filter((item) =>
      filter.filterFn(item, filter.value)
    );
  });
  return filteredData;
}

function applySorting<Z>(
  data: Array<Z>,
  columnConfig: ColumnConfig<Z> | null,
  sortOrder: 'asc' | 'desc'
): Array<Z> {
  if (!columnConfig?.sortFn) {
    return data;
  }
  const sortedData = [...data].sort(columnConfig.sortFn);
  return sortOrder === 'asc' ? sortedData : sortedData.reverse();
}

export default Table;
