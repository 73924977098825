import Linkify from 'linkify-react';
import React, { forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import EmbeddedLinkRenderer from './EmbeddedLinkRenderer';
import remarkGfm from 'remark-gfm';
import { remark } from 'remark';
import remarkToc from 'remark-toc';
import rehypeSlug from 'rehype-slug';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';

const Wrapper = styled.div`
  img {
    max-width: 100%;
    max-height: 100%;
  }
  pre {
    border-radius: 8px;
  }
  code {
    /* same font as ACE Editor */
    font-family: Monaco, Menlo, 'Ubuntu Mono', Consolas, source-code-pro,
      monospace;
    white-space: pre;
    font-size: 0.9em;
  }
`;

type Props = {
  md: string;
  renderers?: { [key: string]: any };
  style?: React.CSSProperties;
  showTOC?: boolean;
};

export default forwardRef<HTMLDivElement, Props>(function Markdown(
  { md, renderers, style, showTOC = false, ...rest },
  ref
) {
  const markdown = showTOC
    ? String(
        remark()
          .use(remarkToc, { heading: 'contents', ordered: true })
          .processSync(md)
      ) // note: the TOC is generated only if there's a ## Contents header in the markdown; see https://github.com/remarkjs/remark-toc for structure
    : md;

  return (
    <div ref={ref} style={style}>
      <Wrapper>
        <ReactMarkdown
          children={markdown}
          remarkPlugins={[remarkGfm]} // adds support for stuff not in CommonMarkdown such as tables, strikethrough and direct links
          rehypePlugins={[rehypeSlug, rehypeAutolinkHeadings]} // adds support for inter-page links
          components={{
            link: EmbeddedLinkRenderer,
            a: EmbeddedLinkRenderer,
            p: (props) => {
              // makes in-text links click-able even if they don't use markdown syntax
              return (
                <Linkify
                  tagName="p"
                  options={{
                    target: '_blank',
                    rel: 'nofollow noopener noreferrer',
                  }}
                >
                  {props.children}
                </Linkify>
              );
            },
          }}
        />
      </Wrapper>
    </div>
  );
});
