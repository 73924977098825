import { ProjectSubmission, learnerDashboardURL } from '../../hydra';
import HeaderSection from './Header';
import { useTheme } from 'styled-components';
import {
  Row,
  Pressable,
  TagsWrapper,
  SectionWrapper,
  DividerRow,
  QuestionsWrapper,
} from './common';
import Tag from '../Tag';
// import { Ratings } from './Ratings';
import ProjectQuestions from './ProjectQuestions';
import ProjectReview from './ProjectReview';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

function ProjectSubmissionContent({
  submission,
  onCloseModal,
  onPostReview,
}: {
  submission: ProjectSubmission;
  onCloseModal: () => void;
  onPostReview: () => void;
}) {
  const theme = useTheme();
  return (
    <>
      <HeaderSection submission={submission} onClose={onCloseModal} />
      <SectionWrapper>
        <DividerRow color={theme.colors.textSecondary}>
          <ProjectContentDetailsSection
            submission={submission}
            style={{ order: -1 }}
          />
          <ProjectSubmissionSection submission={submission} />
        </DividerRow>
      </SectionWrapper>
      <ProjectQuestionsSection submission={submission} />
      <ProjectReviewSection
        submission={submission}
        onPostReview={onPostReview}
      />
    </>
  );
}

const ProjectContentDetailsSection = ({
  submission,
  style,
}: {
  submission: ProjectSubmission;
  style: React.CSSProperties;
}) => {
  const theme = useTheme();

  const onOpenContent = () => {
    window.open(
      `${learnerDashboardURL}/preview/${submission.content.slug}/dashboard`,
      '_blank'
    );
  };

  return (
    <div style={{ flex: 1, ...style }}>
      <Row>
        <h2 style={{ marginBottom: '0' }}>Activity: {submission.name}</h2>
      </Row>
      <TagsWrapper style={{ flexDirection: 'column', marginTop: '1em' }}>
        <TagsWrapper style={{ alignItems: 'center' }}>
          <Pressable
            onClick={onOpenContent}
            title="Open content preview dashboard"
          >
            <Tag color={theme.accents.lightYellow} style={{ margin: '0' }}>
              Course - {submission.content.name}
              {'  '}
              <i className="fa-solid fa-arrow-up-right-from-square" />
            </Tag>
          </Pressable>
        </TagsWrapper>
        <TagsWrapper>
          <Tag
            color={
              submission.review.date
                ? theme.accents.lightTeal
                : theme.accents.lightRed
            }
            style={{ margin: '0' }}
          >
            {submission.review.date ? 'Reviewed' : 'Not Reviewed'}
          </Tag>
          <Tag color={theme.accents.lightBlue} style={{ margin: '0' }}>
            Track - {submission.track.name}
          </Tag>
        </TagsWrapper>
      </TagsWrapper>
    </div>
  );
};

const ProjectSubmissionSection = ({
  submission,
}: {
  submission: ProjectSubmission;
}) => {
  return (
    <div style={{ flex: 1 }}>
      <h2 style={{ margin: '0' }}>Learner Submission</h2>
      <ul style={{ margin: '1em 0' }}>
        <li>
          {dayjs.utc(submission.submission.date).format('DD/MM/YYYY - HH:mm')}
        </li>
      </ul>
    </div>
  );
};

const ProjectQuestionsSection = ({
  submission,
}: {
  submission: ProjectSubmission;
}) => {
  return (
    <SectionWrapper>
      <QuestionsWrapper>
        <ProjectQuestions
          key={`project-questions-${submission.submission.id}-${submission.type}`}
          submission={submission}
        />
      </QuestionsWrapper>
    </SectionWrapper>
  );
};

const ProjectReviewSection = ({
  submission,
  onPostReview,
}: {
  submission: ProjectSubmission;
  onPostReview: () => void;
}) => {
  return (
    <SectionWrapper>
      <h2 style={{ margin: '0' }}>Mentor Review</h2>
      <ProjectReview
        key={`project-review-${submission.submission.id}-${submission.type}`}
        submission={submission}
        onPostReview={onPostReview}
      />
    </SectionWrapper>
  );
};

export default ProjectSubmissionContent;
