import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ProjectSubmission } from '../../hydra';
import Markdown from '../Markdown';
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h4,
  p,
  code {
    color: ${(props) => props.color || 'white'};
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${(props) => props.color || 'white'};
  width: 100%;
`;

const IconSpan = styled.span<{
  isDisabled?: boolean;
  activeColor: string;
  inactiveColor: string;
}>`
  vertical-align: middle;
  color: ${(props) => props.inactiveColor};
  ${(props) =>
    !props.isDisabled &&
    `
    cursor: pointer;
    color: ${props.activeColor};
  `};
`;

export default function Questions({
  submission,
}: {
  submission: ProjectSubmission;
}) {
  const theme = useTheme();
  const [qIndex, setQIndex] = React.useState(0);

  const question = submission.questions?.[qIndex];

  return (
    <div>
      <Row style={{ margin: '1em 0', alignItems: 'center', gap: '0.75em' }}>
        <h3 style={{ marginBottom: '0' }}>
          Question {qIndex + 1}/{submission.questions.length}
        </h3>
        <IconSpan
          activeColor={theme.colors.primary}
          inactiveColor={theme.colors.textSecondary}
          isDisabled={qIndex === 0}
          className="fa-solid fa-left fa-2x"
          onClick={() => {
            if (qIndex !== 0) {
              setQIndex(qIndex - 1);
            }
          }}
        />
        <IconSpan
          activeColor={theme.colors.primary}
          inactiveColor={theme.colors.textSecondary}
          isDisabled={qIndex === submission.questions.length - 1}
          className="fa-solid fa-right fa-2x"
          onClick={() => {
            if (qIndex !== submission.questions.length - 1) {
              setQIndex(qIndex + 1);
            }
          }}
        />
      </Row>

      <Row style={{ gap: '1em' }}>
        <div style={{ flex: 1 }}>
          <h5>Question Text</h5>
          <ContentWrapper color={theme.colors.textSecondary}>
            <Markdown md={question.text} />
          </ContentWrapper>
        </div>
      </Row>

      <h5>Learner Answer</h5>
      <ContentWrapper color={theme.colors.textSecondary}>
        <Markdown md={question.answer} />
      </ContentWrapper>
      <Divider color={theme.colors.textSecondary} />
      <h3 style={{ margin: '1em 0' }}>Project Files</h3>
      <ContentWrapper color={theme.colors.textSecondary}>
        {(submission?.submission?.fileLinks ?? []).length ? (
          <ul>
            {submission?.submission?.fileLinks.map((link) => {
              // url should be "https://bucket.com/track-slug/learner-email/file-name.file-extension?queryparams"
              // we remove the query params, and then extract the last name in the "/"-denominated path - should be the file name + extension
              const [name] = (link.split('?')?.[0] ?? '').split('/').reverse();
              return (
                <li>
                  <a href={link} target="_">
                    {name}
                  </a>
                </li>
              );
            })}
          </ul>
        ) : (
          <p>No files uploaded by the learner.</p>
        )}
      </ContentWrapper>
      <Divider color={theme.colors.textSecondary} />
      <h3 style={{ margin: '1em 0' }}>Project Links</h3>
      <ContentWrapper color={theme.colors.textSecondary}>
        {(submission?.submission?.externalLinks ?? []).length ? (
          <ul>
            {submission?.submission?.externalLinks.map((link) => {
              return (
                <li>
                  <a href={link} target="_">
                    {link}
                  </a>
                </li>
              );
            })}
          </ul>
        ) : (
          <p>No external links added by the learner.</p>
        )}
      </ContentWrapper>
    </div>
  );
}
