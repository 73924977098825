import { CSSProperties } from 'react';
import styled, { useTheme } from 'styled-components';
import Tag from '../Tag';

const HoverHighlightText = styled.p`
  margin: 0;
`;

export const FlexCell = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: CSSProperties;
}) => (
  <td style={style}>
    <div style={{ display: 'inline-flex' }}>{children}</div>
  </td>
);

export const HighlightCell = ({
  text,
  style,
}: {
  text: string;
  style?: CSSProperties;
}) => (
  <td style={style}>
    <HoverHighlightText className="highlight">{text}</HoverHighlightText>
  </td>
);

export const TagCell = ({
  text,
  color,
  style,
}: {
  text: string;
  color: string;
  style?: CSSProperties;
}) => (
  <FlexCell style={style}>
    <Tag color={color}>{text}</Tag>
  </FlexCell>
);

export const ReviewIconCell = ({ reviewedAt }: { reviewedAt?: string }) => {
  const theme = useTheme();
  const icon = reviewedAt ? 'fa-solid fa-circle-check' : 'fa-solid fa-circle-x';
  const color = reviewedAt ? theme.accents.lightTeal : theme.accents.lightRed;

  return (
    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
      <i className={`${icon} fa-2x`} style={{ color }} />
    </td>
  );
};
