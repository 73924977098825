import styled from 'styled-components';
import Tag from '../Tag';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Pressable = styled.div`
  :hover {
    transform: translateY(-0.1em);
    transition: transform 0.5s;
    cursor: pointer;
  }
`;

export default function Filters({
  filters,
  canResetFilters,
}: {
  filters: Array<{
    options: Array<{ value: string; label: string }>;
    label: string;
    value: string;
    onChange: React.Dispatch<React.SetStateAction<string>>;
    type: 'dropdown';
    color?: string;
    defaultValue: string;
  }>;
  canResetFilters?: boolean;
}) {
  if (!filters.length) {
    return null;
  }

  const onResetFilters = () => {
    filters.forEach((filter) => {
      filter.onChange(filter.defaultValue);
    });
  };

  return (
    <div style={{ marginBottom: '2em', padding: '0.75em' }}>
      <h3>Filters</h3>
      <Row>
        {filters.map((filter, i) => {
          if (filter.type === 'dropdown') {
            return (
              <Dropdown
                key={`filter-${i}-${filter.label}`}
                label={filter.label}
                options={filter.options}
                value={filter.value}
                onChange={filter.onChange}
                color={filter.color}
              />
            );
          }
          return null;
        })}
      </Row>
      {canResetFilters && <ResetFilters onResetFilters={onResetFilters} />}
    </div>
  );
}

function Dropdown({
  label,
  options,
  value,
  onChange,
  color,
}: {
  label: string;
  options: Array<{ value: string; label: string }>;
  value?: string;
  onChange: (value: string) => void;
  color?: string;
}) {
  return (
    <div style={{ marginRight: '1em' }}>
      <Tag color={color}>{label}</Tag>
      <select
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options.map((t) => (
          <option key={`option-dropdown-${label}-${t.value}`} value={t.value}>
            {t.label}
          </option>
        ))}
      </select>
    </div>
  );
}

const ResetFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.colors.textDanger};
  margin-top: 1em;
`;

function ResetFilters({ onResetFilters }: { onResetFilters?: () => void }) {
  return (
    <Pressable>
      <ResetFilterWrapper onClick={onResetFilters}>
        <i
          className="fa-solid fa-filter-circle-xmark"
          style={{ marginRight: '0.5em' }}
        />
        <span>Clear filters</span>
      </ResetFilterWrapper>
    </Pressable>
  );
}
