import React from 'react';
import useAuth0 from '../hooks/use-auth0';
import PageWrapper from '../components/PageWrapper';
import styled from 'styled-components';
import Button from '../components/Button';

const CenteredColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5%;
`;

export default function NonMentorHome() {
  const { logout, auth0User } = useAuth0();

  return (
    <>
      <PageWrapper>
        <CenteredColumn>
          <h2>Sorry {auth0User?.email}, it seems you're not a mentor </h2>
          <p>
            If you think this is a mistake, or if you want to apply for a
            mentorship position, feel free to get in contact with us at{' '}
            <a href="mailto:concierge@enki.com">concierge@enki.com</a>
          </p>
          <Row>
            <Button onClick={() => logout()}>Log out</Button>
          </Row>
        </CenteredColumn>
      </PageWrapper>
    </>
  );
}
