export function getHomeUrl() {
  return `/`;
}

export function getProfileUrl() {
  return `/profile`;
}

export function getConfirmSkillsUrl() {
  return `/confirm-skills`;
}

export function getScheduleInterviewUrl() {
  return `/schedule-interview`;
}

export function getSignContractUrl() {
  return `/sign-contract`;
}

export function getSubmissionsUrl() {
  return `/submissions`;
}

export function getLearnersUrl() {
  return `/learners`;
}

export function getCommunicationResourceUrl() {
  return '/resources/comms-with-learners';
}

export function getReviewingResourceUrl() {
  return '/resources/reviewing-submissions';
}

export function getProjectsResourceUrl() {
  return '/resources/projects';
}

export function getBestPracticesResourceUrl() {
  return '/resources/best-practices';
}

export function getBillingResourceUrl() {
  return '/resources/billing-admin';
}

export function getFAQResourceUrl() {
  return '/resources/faqs';
}

export function getCyclesAndTracksResourceUrl() {
  return '/resources/cycles-and-tracks';
}
