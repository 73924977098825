import React from 'react';
import styled from 'styled-components';
import Markdown from '../../components/Markdown';
import SimplePage from '../../components/SimplePage';

const md = `# Projects

## **How do projects work?**

Each one of Enki's Tracks is completed with a hands-on project. Learners apply the concepts covered during the Track to their role. **Put simply, it's the 'end goal' of each Track.**

The Enki platform enables this in an automated way by prompting Learners and Mentors to decide what the project should be together. Our platform also ensures the project is started and completed as efficiently as possible.

- A project is ***any application*** of theoretical concepts learned throughout the Track with the most relevant data and tool to the Learner
- A ***proof of work done*** for the project is required to complete each Track. This doesn't need to be complicated; it can be a series of screenshots, a video recording, or simply a doc summarizing what they did and why it was useful to them & their company.
- **There is no need to share any sensitive company data** - query details can even be obfuscated if necessary. Method is far more important than results - **focus on how concepts were practically applied.**

## **Project guidance**

Start helping your Learner brainstorm the project that might best fit their requirements **from the first 1:1**. Ultimately Learners should be able to ask and answer data related queries using the skills picked up throughout the Track.

**Initially, get Learners thinking about:**

- What are the most impactful ways of applying new Track skills to their day-to-day work?
- What will make them more self-sufficient in the context of data?
- What type of tasks does the data team tend to get asked to carry out on behalf of the Learner?

### **1. Learn to Replicate**

What are reports, dashboards, or functionality that would provide meaningful value to them in their day to day, if they became confident in building or working with them?

Often this is what the data team would be tasked with. But, if the Learner learned to do this independently, it would both empower them and free up time for the data team.

Ideally, add the reasons for why each project was chosen. Each Learner should end up having 1-3 project options to choose from.

**Examples:**

- Learn to build and work confidently with the customers dashboard
- Learn to write and understand the fundamentals of the following 3 queries: 'X', 'Y', 'Z'

### **2. Data-related Questions**

This is a list of questions that Learners could be answering themselves using the skills from the Track. Often, these are questions that are not asked at all - but should be - so this exercise also improves habits in the context of asking the right questions.

Typically, 5-10 questions are enough to help Learners put together a list to choose from for their project. The project itself would be learning to answer these questions in a self-sufficient way.

**Examples:**

- What client-side events did user {insert user_id} perform on a specified date?
- What percent of deposits come from each payment method by state in the last 30 days?

## **Project suggestion format**

When submitting their project they will be asked to answer the following questions in their Learner dashboard:

1. Which project did you choose and why? How did it benefit your role?
2. How did you approach the problem? How did you explore the data, what data did you discover and how was it useful?
3. What problems did you run into? How did you tackle them?
4. Did you need help from your Mentor or others? Who did you seek help from?
5. How did this impact your role? What elements of your learning will you take forward?

The format they submit can simply be: series of screenshots, a video recording, or a Google doc / slide show / pdf.

## **Showcase Session**

Held at the end of each Cycle, this gives Learners an opportunity to showcase what they've been working on. Although preferred, Learners don't need to have a fully completed project ready for the Showcase. Project ideas or powerful bits of information from the Track they've found useful at work can be shared at the first Showcase. However, **Learners will need to discuss how their Showcase, project or not, relates to their work at their company**.

Here are a few examples of projects that Enki alumni submitted, with commentary and context:

- SQL *Built in Periscope, using SQL*
    
    ![https://img.enkipro.com/2f65d919c58030ed19b824c7092154e5.png](https://img.enkipro.com/2f65d919c58030ed19b824c7092154e5.png)
    
    As a Product Manager, Megan launched and owns several projects. She used SQL to aggregate data from different systems, which resulted in creating a tool she uses for observability. This is essentially a 'birds-eye' view of the different stages of the selling cycle their products go through.
    
    Megan's team gave her access to their BI tool and documentation on the relevant data. She compiled a PDF of several annotated screenshots with what she did to develop the query that generated the heat-map above.
    
- Know Your Data + Tableau *Deal Analyzer Dashboard, built in Tableau*
    
    ![https://img.enkipro.com/0b0380f5f0150276cac5654c8933a3ff.png](https://img.enkipro.com/0b0380f5f0150276cac5654c8933a3ff.png)
    
    Zak built this dashboard that shows KPIs in different US states to improve his team's observability. As this was developed during a Know Your Data Track developed with Tableau as a precursor, both Zak and his mentor had access to the company's Tableau Server throughout the content.
    
    Hence, Zak was able to simply share the dashboard's link, together with a short description of the project's purpose and implementation as part of his submission.
    
- Looker *Vendor Performance Dashboard, built in Looker*
    
    ![https://img.enkipro.com/3c213cdf21203fbb523c55dd5d0dfacc.png](https://img.enkipro.com/3c213cdf21203fbb523c55dd5d0dfacc.png)
    
    Here, Sarah implemented a Looker dashboard that looks at her company's vendors performance.
    
    Compared to the previous project examples where each Learner had a unique project application. Here, the team's manager suggested that everyone could implement the same dashboard, but through their unique perspective!
    
    Sarah simply submitted a screenshot of the final result, together with a short project description.
    
- Data 101 - *Simple project example*
    - **Data visualization:** Look through all the reports, charts & data visualizations that you've sent or received in the last 6 months. Critically analyze them and write up all the ways they could've been improved, with reasons.
    - **Correlations:** Perform a correlation analysis on the following sets of data specific to various drivers of Product X at Your Company [data provided]. What did this process teach you about Product X?
    - **Funnel Analysis:** Here is funnel data specific to Product Y at Your Company [data provided]. Analyze it and come up with all relevant insights and suggested follow ups regarding Product Y.
    - **AB testing:** Suggest a feature or improvement to Product Z. How *exactly* would you set up an AB test to test whether this new feature is an improvement to the current version? Explain your reasoning.
    - **Forecasting:** Here is revenue data for the last 12 months of Product XX, and a model with the key drivers [data provided]. Forecast what you think will happen over the next 12 months. What were the key decisions you made and why within your forecast?

## **Other ways to help your Learners**

Aside from providing guidance on which projects to choose, below are some additional ways to help your Learners get the most value from the Enki experience:

- Confirm early on that they have all the resources and access to relevant company data that they would need to complete their project
- Encourage them to review internal documentation about resources and tools that could help address any questions about their companies databases relevant to their project
`;

const HalfWidthContainer = styled.div`
  margin: 0 25%;

  p,
  ul,
  ol {
    margin-bottom: 1em;
  }
`;

export default function ProjectsResourcePage() {
  return (
    <SimplePage>
      <HalfWidthContainer>
        <Markdown md={md} />
      </HalfWidthContainer>
    </SimplePage>
  );
}
