import React from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import PageWrapper from '../components/PageWrapper';
import useAuth0 from '../hooks/use-auth0';
import useCleanGlobalClasses from '../hooks/use-clean-global-classes';
import useMentorContext from '../hooks/use-mentor';
import {
  InlineWidget,
  CalendlyEventListener,
  EventScheduledEvent,
} from 'react-calendly';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { confirmMentorInterviewTask } from '../hydra';
import { getHomeUrl } from '../routing';

const PageContentWrapper = styled.div`
  padding: 2em;
  background-color: ${(prop) => prop.theme.colors.sectionBg};
  border: 1px solid ${(prop) => prop.theme.colors.sectionBorder};
  border-radius: ${(prop) => prop.theme.dimensions.sectionBorderRadius};
`;

const CALENDLY_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://calendly.com/kirill2/30min'
    : 'https://calendly.com/loopiezlol/meet';

export default function ScheduleCalendlyInterview() {
  const { isLoading, logout, auth0User, isAuthenticated, getTokenSilently } =
    useAuth0();
  const { value: mentorProfileResponse, retry: refreshMentorContext } =
    useMentorContext();
  const history = useHistory();

  useCleanGlobalClasses({ isLoading });

  React.useEffect(() => {
    if (mentorProfileResponse?.profile?.status !== 'INTERVIEW_TASK_SHARED') {
      history.push(getHomeUrl());
    }
  });

  const onEventScheduled = React.useCallback(
    async (e: EventScheduledEvent) => {
      if (e.data.event === 'calendly.event_scheduled') {
        const token = await getTokenSilently();
        if (!token) {
          return;
        }
        // TODO: error handling
        await confirmMentorInterviewTask(token);
        toast.success('Interview scheduled!');
        refreshMentorContext();
      }
    },
    [refreshMentorContext, getTokenSilently]
  );

  return (
    <>
      <Navbar
        auth={{
          isLoading,
          isAuthenticated,
          user: auth0User,
          logout,
        }}
      />
      <PageWrapper>
        <PageContentWrapper>
          <h1>Schedule a call</h1>
          <p>
            For the final step, let's set up a call to go over all details
            related to the platform, the experience, and your contract.
          </p>
          <CalendlyEventListener onEventScheduled={onEventScheduled} />
          <InlineWidget
            url={CALENDLY_URL}
            styles={{ height: '80vh' }}
            prefill={{
              email: auth0User?.email,
              name: mentorProfileResponse?.profile?.name || '',
            }}
          />
        </PageContentWrapper>
      </PageWrapper>
    </>
  );
}
