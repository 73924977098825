import styled from 'styled-components';
import React from 'react';
import { ColumnConfig } from '.';

const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  min-width: 0;
`;

const Table = styled.table``;

export const ClickableRow = styled.tr`
  cursor: pointer;

  :hover {
    .highlight {
      color: white;
    }
  }
`;

const ColumnHeaderWrapper = styled.div<{ isClickable: boolean }>`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  ${(props) =>
    props.isClickable &&
    `
    :hover {
      transform: translateY(-0.1em);
      transition: transform 0.5s;
      cursor: pointer;
    }
  `}
`;

export default function TableBody<T extends Record<string, any>>({
  data,
  columnConfigs,
  onClick,
  style,
  hoverTitle,
  fallbackCopy = 'No records yet',
  columnSortIndex,
  columnSortDirection,
  onSort,
}: {
  fallbackCopy?: string;
  data: Array<T>;
  hoverTitle?: string;
  onClick?: (item: T) => void;
  columnConfigs: Array<ColumnConfig<T>>;
  style?: React.CSSProperties;
  columnSortIndex?: number | null;
  columnSortDirection?: 'asc' | 'desc';
  onSort?: (index: number) => void;
}) {
  return (
    <TableWrapper>
      <Table style={style}>
        <thead>
          <tr>
            {Object.values(columnConfigs).map(({ header, sortFn }, i) => (
              <th key={`table-header-${header}`}>
                <ColumnHeaderWrapper
                  isClickable={!!sortFn}
                  onClick={sortFn && onSort ? () => onSort(i) : () => {}}
                >
                  {header}
                  {sortFn && (
                    <i
                      style={{ marginLeft: '0.5em' }}
                      className={`fa-solid ${getColumnSortIcon(
                        i === columnSortIndex,
                        columnSortDirection
                      )}`}
                    ></i>
                  )}
                </ColumnHeaderWrapper>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <ClickableRow
              key={`table-item-${i}`}
              onClick={onClick ? () => onClick(item) : () => {}}
              title={hoverTitle}
            >
              {columnConfigs.map((config, j) => {
                const cell = config.componentFn(item);

                return (
                  <React.Fragment key={`cell-${i}-${j}`}>{cell}</React.Fragment>
                );
              })}
            </ClickableRow>
          ))}
          {data.length === 0 && (
            <tr>
              <td colSpan={columnConfigs.length}>{fallbackCopy}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

function getColumnSortIcon(isSorted: boolean, direction?: 'asc' | 'desc') {
  if (isSorted) {
    return direction === 'asc' ? 'fa-sort-up' : 'fa-sort-down';
  }

  return 'fa-sort';
}
