import { MentorLearner } from '../../hydra';
import * as tableConfigs from '../../table-configs';
import Table from '../Table';

export default function OnboardingAnswers({
  learner,
}: {
  learner: MentorLearner;
}) {
  return (
    <div>
      <Table<MentorLearner['onboardingAnswers'][0]>
        filters={[]}
        hoverTitle="Open submission in new page"
        data={learner.onboardingAnswers}
        columnConfigs={tableConfigs.onboardingAnswers.getColumns()}
        fallbackCopy="No onboarding answers"
      />
    </div>
  );
}
