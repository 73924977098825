import React from 'react';
import dayjs from 'dayjs';
import styled, { useTheme } from 'styled-components';
import Navbar from '../components/Navbar';
import PageWrapper from '../components/PageWrapper';
import useAuth0 from '../hooks/use-auth0';
import useCleanGlobalClasses from '../hooks/use-clean-global-classes';
import { GetMentorProfileResponse } from '../hooks/use-hydra';
import useMentorContext from '../hooks/use-mentor';
import { useForm, Controller } from 'react-hook-form';
import { postMentorProfile } from '../hydra';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSkillStatus, SkillType } from '../helpers';
import Button from '../components/Button';
import Select from '../components/Select';
import { getConfirmSkillsUrl, getHomeUrl } from '../routing';
import timezones from '../timezones';

const PageContentWrapper = styled.div`
  padding: 2em;
  background-color: ${(prop) => prop.theme.colors.sectionBg};
  border: 1px solid ${(prop) => prop.theme.colors.sectionBorder};
  border-radius: ${(prop) => prop.theme.dimensions.sectionBorderRadius};
`;

export default function Profile() {
  const { isLoading, logout, auth0User, isAuthenticated } = useAuth0();
  const { value } = useMentorContext();

  useCleanGlobalClasses({ isLoading });

  if (!value || !value.profile) {
    return <h2>Sorry, something went wrong.</h2>;
  }

  return (
    <>
      <Navbar
        auth={{
          isLoading,
          isAuthenticated,
          user: auth0User,
          logout,
        }}
      />
      <PageWrapper>
        <PageContentWrapper>
          <h1>Your Profile</h1>
          <ProfileForm profile={value.profile} />
        </PageContentWrapper>
      </PageWrapper>
    </>
  );
}

const FormWrapper = styled.div`
  display: flex;
`;

const Form = styled.form`
  flex-direction: column;
  display: flex;
`;

const FormSubmit = styled.input`
  margin-top: 1em;
  type: submit;
`;

const FormCaption = styled.p`
  font-style: italic;
  margin-bottom: 0.5em;
`;

// should be as tall as <FormError/>
const FormSpacer = styled.div`
  height: 2em;
`;

const SkillItemWrapper = styled.div`
  background-color: ${(prop) => prop.theme.colors.pageBg};
  color: ${(prop) => prop.theme.colors.text};
  padding: 1em;
  border-radius: ${(prop) => prop.theme.dimensions.sectionBorderRadius};
  margin-bottom: 1.2em;
  display: flex;
  flex-direction: column;
`;

const SkillItemText = styled.p`
  margin-bottom: 0.1em;
`;

function SkillItem({
  style,
  skill,
}: {
  style?: React.CSSProperties;
  skill: SkillType;
}) {
  const status = getSkillStatus(skill);
  return (
    <SkillItemWrapper style={style}>
      <SkillItemText>{skill.name}</SkillItemText>
      <SkillItemText style={{ fontSize: '0.7em' }}>{status}</SkillItemText>
    </SkillItemWrapper>
  );
}

const FormError = styled.p<{
  isValid: boolean;
}>`
  margin-bottom: 0;
  margin: 0.25em 0;
  font-size: 0.8em;
  color: ${(prop) =>
    prop.isValid ? prop.theme.colors.sectionBg : prop.theme.colors.textDanger};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

// for now, the availability slots aren't part of the react form
// TODO: make this a form controlled component
type FormType = {
  name: string;
  company: string;
  linkedinURL: string;
  address: string;
  timezonePrimary: string;
  timezoneSecondary: string;
  skillsExtraComment: string;
  availabilityExtraComment: string;
  availabilityHours: string;
};

type Slot = {
  day: string;
  period: string;
  isAvailable: boolean;
};

function parseAvailabilities(slots: Array<Slot>) {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const periods = [
    'Morning (8 - 11)',
    'Afternoon (11 - 14)',
    'Mid-afternoon (14 - 17)',
    'Evening (17 - 20)',
  ];

  const cartProduct = days.reduce((acc: any[], val) => {
    acc.push(...periods.map((period) => ({ day: val, period })));
    return acc;
  }, []);

  const combinations = cartProduct.map(
    ({ day, period }: { day: string; period: string }) => ({
      day,
      period,
      isAvailable:
        slots.find((slot) => slot.day === day && slot.period === period)
          ?.isAvailable ?? true,
    })
  );

  return combinations;
}

function ProfileForm({
  profile,
}: {
  profile: NonNullable<GetMentorProfileResponse['profile']>;
}) {
  const history = useHistory();
  const { getTokenSilently } = useAuth0();
  const { retry: refetchMentorProfile, value: mentorContext } =
    useMentorContext();

  const [slots, setSlots] = React.useState<Array<Slot>>(
    parseAvailabilities(mentorContext?.profile?.availability?.slots || [])
  );

  const [hasNoSlots, setHasNoSlots] = React.useState<boolean>(
    !!(
      mentorContext?.profile?.availability?.slots &&
      (mentorContext?.profile?.availability?.slots ?? []).every(
        (s) => !s.isAvailable
      )
    )
  );
  const isAvailabilityFilled = hasNoSlots || slots.some((s) => s.isAvailable);

  const {
    register: registerForm,
    handleSubmit: handleFormSubmit,
    formState: { errors, isSubmitting },
    control: controlForm,
    trigger: triggerForm,
    setValue: setFormValue,
  } = useForm<FormType>({ mode: 'onChange' });

  // trigger required field errors on load
  React.useEffect(() => {
    triggerForm();
  }, [triggerForm]);

  const onSubmit = handleFormSubmit(async (data) => {
    const token = await getTokenSilently();
    if (token) {
      // TODO: handle error
      await postMentorProfile(token, {
        name: data.name,
        company: data.company,
        linkedinURL: data.linkedinURL,
        address: data.address,
        availability: {
          slots,
          extraComment: data.availabilityExtraComment,
          hours: data.availabilityHours,
        },
        timezonePrimary: data.timezonePrimary,
        timezoneSecondary: data.timezoneSecondary,
        skillsExtraComment: data.skillsExtraComment,
      });

      refetchMentorProfile();
      toast.success('Profile updated!');
      history.replace(getHomeUrl());
    }
  });

  const availabilityCaption = `${
    !!profile?.availability?.updatedAt
      ? '(Last updated: ' +
        dayjs(profile.availability.updatedAt).format('MMMM DD YYYY') +
        ')'
      : ''
  }`;

  const canSubmit =
    errors.name?.type !== 'required' &&
    errors.company?.type !== 'required' &&
    errors.timezonePrimary?.type !== 'required' &&
    errors.availabilityHours?.type !== 'required' &&
    isAvailabilityFilled;

  return (
    <FormWrapper>
      <Form onSubmit={onSubmit}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          defaultValue={profile.name || ''}
          placeholder="John Doe"
          {...registerForm('name', { required: true })}
        />
        <FormError isValid={errors.name?.type !== 'required'}>
          Name is required
        </FormError>

        <label htmlFor="company">Company</label>
        <input
          type="text"
          defaultValue={profile.company || ''}
          placeholder="Acme Inc."
          {...registerForm('company', { required: true })}
        />
        <FormError isValid={errors.company?.type !== 'required'}>
          Company is required
        </FormError>
        <label htmlFor="linkedinURL">LinkedIn</label>
        <input
          type="text"
          defaultValue={profile.linkedinURL || ''}
          placeholder="https://www.linkedin.com/in/john-doe/"
          {...registerForm('linkedinURL', { required: false })}
        />
        <FormSpacer />

        <label htmlFor="skillsExtraComment">Skills</label>
        <Row>
          {profile.skills.map((skill) => (
            <SkillItem skill={skill} key={`skill-item-${skill.identifier}`} />
          ))}
        </Row>
        <ConfirmSkillsLink
          isCreatingProfile={mentorContext?.profile?.status === 'INVITE_SHARED'}
        />
        <FormCaption>Any other skills you want to add?</FormCaption>
        <input
          type="text"
          defaultValue={profile.skillsExtraComment || ''}
          placeholder="e.g. PowerBI, Heap, Mode, etc."
          {...registerForm('skillsExtraComment', { required: false })}
        />

        <FormSpacer />

        <label htmlFor="address" style={{ marginBottom: '0.25em' }}>
          Address
        </label>
        <FormCaption style={{ marginBottom: '0.5em' }}>
          We use this for billing and contract
        </FormCaption>
        <input
          type="text"
          defaultValue={profile.address || ''}
          placeholder="110 SQL Street"
          {...registerForm('address', { required: true })}
        />
        <FormError isValid={errors.address?.type !== 'required'}>
          Address is required
        </FormError>

        <label htmlFor="timezonePrimary" style={{ marginBottom: '0.25em' }}>
          Primary Location/Timezone
        </label>
        <FormCaption style={{ marginBottom: '0.5em' }}>
          We use this to match you with learners for the 1:1 calls
        </FormCaption>
        <Controller
          name="timezonePrimary"
          control={controlForm}
          rules={{ required: true }}
          defaultValue={profile.timezonePrimary || ''}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              innerRef={ref}
              value={timezones.find((c) => c.value === value)}
              options={timezones}
              palceholder="Region/City"
              onChange={(val) => onChange((val as { value: string })?.value)}
            />
          )}
        />
        <FormError isValid={errors.timezonePrimary?.type !== 'required'}>
          The primary location is required
        </FormError>

        <label htmlFor="timezoneSecondary" style={{ marginBottom: '0.25em' }}>
          Secondary Location/Timezone
        </label>
        <FormCaption style={{ marginBottom: '0.5em' }}>
          We use this to match you with learners for the 1:1 calls
        </FormCaption>

        <Controller
          name="timezoneSecondary"
          control={controlForm}
          defaultValue={profile.timezoneSecondary || ''}
          rules={{ required: false }}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              innerRef={ref}
              value={timezones.find((c) => c.value === value)}
              options={timezones}
              palceholder="Region/City"
              onChange={(val, triggeredAction) => {
                if (triggeredAction.action !== 'clear') {
                  onChange((val as { value: string })?.value);
                } else {
                  setFormValue('timezoneSecondary', '');
                }
              }}
              isClearable
            />
          )}
        />
        <FormSpacer />

        <label style={{ marginBottom: '0.25em' }}>Availability</label>
        <FormCaption style={{ marginBottom: '0.5em' }}>
          Fill in when you'd be typically be flexible each weekday for the next
          few weeks. We use this to match you with learners for the 1:1 calls,
          so the more flexibility (ie. filled in checkboxes) here, the better!
          You'll be able to update this later at any time.
        </FormCaption>
        <FormCaption>{availabilityCaption}</FormCaption>
        <AvailabilityInput
          slots={slots}
          setSlots={setSlots}
          setHasNoSlots={setHasNoSlots}
        />
        <CheckBox
          id={'no-avail-checkbox'}
          isChecked={!!hasNoSlots}
          onChange={(e) => {
            setHasNoSlots(e.target.checked);
            if (e.target.checked) {
              setSlots(slots.map((s) => ({ ...s, isAvailable: false })));
            }
          }}
          labelCopy="I have little or no availability over the next few weeks."
        />
        <FormError isValid={isAvailabilityFilled}>
          Availability is required
        </FormError>
        <FormCaption>
          Anything else you want to add regarding your availability?
        </FormCaption>
        <input
          type="text"
          defaultValue={profile.availability?.extraComment || ''}
          placeholder="e.g. not available on 24th of Dec."
          {...registerForm('availabilityExtraComment', { required: false })}
        />
        <FormCaption>
          Roughly, how many hours per week could you mentor?
        </FormCaption>
        <input
          type="text"
          defaultValue={profile.availability?.hours || ''}
          placeholder="5 to 10 hours"
          {...registerForm('availabilityHours', { required: true })}
        />
        <FormError isValid={errors.availabilityHours?.type !== 'required'}>
          Availability hours are required
        </FormError>

        <FormSubmit
          type="submit"
          value="Save Profile"
          className="primary"
          disabled={!canSubmit || isSubmitting}
        />
      </Form>
    </FormWrapper>
  );
}

function ConfirmSkillsLink({
  isCreatingProfile,
}: {
  isCreatingProfile: boolean;
}) {
  const history = useHistory();
  // hide the link first time completeing the profile as you aren't table to navigate from the page
  if (isCreatingProfile) {
    return null;
  }

  return (
    <div style={{ marginBottom: '1em' }}>
      <Button onClick={() => history.push(getConfirmSkillsUrl())}>
        Confirm your Skills
      </Button>
    </div>
  );
}

const CheckBoxWrapper = styled.input`
  appearance: auto !important;
  accent-color: ${(props) => props.theme.colors.primary};
  visibility: hidden;
`;

const Table = styled.table`
  margin-bottom: 0em;
  td {
    text-align: center;
    vertical-align: middle;
  }

  th {
    text-align: center;
    vertical-align: middle;
    padding: 0.5em;
  }
`;

const CheckBox = ({
  isChecked,
  onChange,
  id,
  labelCopy,
}: {
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  labelCopy: string;
}) => {
  const theme = useTheme();
  return (
    <>
      <CheckBoxWrapper
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <label htmlFor={id} style={{ color: theme.colors.text }}>
        {labelCopy}
      </label>
    </>
  );
};

function AvailabilityInput({
  slots,
  setSlots,
  setHasNoSlots,
}: {
  slots?: Array<{ day: string; period: string; isAvailable: boolean }>;
  setSlots: React.Dispatch<React.SetStateAction<Slot[]>>;
  setHasNoSlots: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const periods = Array.from(new Set([...(slots || [])?.map((s) => s.period)]));
  const days = Array.from(new Set([...(slots || [])?.map((s) => s.day)]));

  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          {periods.map((p) => (
            <th key={`${p}-period-header`}>{p}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {days.map((day) => (
          <tr key={`a-row-${day}`}>
            <th>{day}</th>
            {periods.map((period) => {
              const slot = slots?.find(
                (s) => s.day === day && s.period === period
              );

              const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                const newSlots = slots?.map((s) =>
                  s.day === day && s.period === period
                    ? { ...s, isAvailable: e.target.checked }
                    : s
                );

                setSlots(newSlots || []);
                if (newSlots?.some((s) => s.isAvailable)) {
                  setHasNoSlots(false);
                }
              };

              return (
                <td key={`t-cell-${day}-${period}`}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '0.7em',
                    }}
                  >
                    <CheckBox
                      isChecked={slot?.isAvailable || false}
                      onChange={onChange}
                      id={`t-cell-${day}-${period}`}
                      labelCopy=""
                    />
                  </div>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
