import React from 'react';
import styled, { useTheme } from 'styled-components';

const Wrapper = styled.div`
  padding: 1.5em;
  border: solid 1px rgba(255, 255, 255, 0.15);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 1em;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Pressable = styled.div`
  :hover {
    transform: translateY(-0.1em);
    transition: transform 0.5s;
    cursor: pointer;
  }
`;

const TabHeader = styled.h3<{ color: string }>`
  margin: 0;
  color: ${(props) => props.color};
`;

export default function TabbedContainer({
  tabs,
}: {
  tabs?: Array<{
    isDisabled?: boolean;
    name: string;
    componentFn: () => React.ReactNode;
  }>;
}) {
  const theme = useTheme();
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  return (
    <Wrapper>
      <Row
        style={{
          gap: '1em',
          borderBottom: `2px solid ${theme.colors.sectionBorder}`,
          paddingBottom: '1em',
          marginBottom: '1em',
        }}
      >
        {tabs?.map((tab, index) => {
          if (tab.isDisabled) {
            return (
              <TabHeader
                color={theme.colors.sectionBorder}
                key={`tab-header-${tab.name}`}
              >
                {tab.name}
              </TabHeader>
            );
          }
          return (
            <Pressable
              key={`tab-header-${tab.name}`}
              onClick={() => setActiveTabIndex(index)}
            >
              <TabHeader
                color={
                  index === activeTabIndex
                    ? theme.colors.text
                    : theme.colors.textSecondary
                }
              >
                {tab.name}
              </TabHeader>
            </Pressable>
          );
        })}
      </Row>
      {tabs && tabs[activeTabIndex].componentFn()}
    </Wrapper>
  );
}
