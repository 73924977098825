import React from 'react';

import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import Button from '../Button';

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 4;
  @media screen and (max-width: 768px) {
    padding: 1em;
  }
  /* padding: 2em; */
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
`;

const ModalBase = styled.div`
  @media screen and (max-width: 768px) {
    padding: 1em 1em;
  }
  padding: 2em 2em;
  display: flex;
  flex-direction: column;
  z-index: 5;
  height: auto;

  background-color: ${prop('theme.colors.sectionBg')};
  border: 1px solid ${prop('theme.colors.sectionBorder')};
  border-radius: 8px;
`;

export default function PostReviewModal({
  onCloseModal,
  unreviewedSubmissionsCount,
  onNextSubmission,
}: {
  onCloseModal?: () => void;
  unreviewedSubmissionsCount: number;
  onNextSubmission: () => void;
}) {
  // for some reason, having two modals open at the same time causes render issues
  // seems to be working with this setup (a new div child to root, at the top of the tree)
  let modalRoot = document.getElementById('post-review-modal-root');
  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', 'post-review-modal-root');
  }

  document.body.prepend(modalRoot);

  return createPortal(
    <ModalWrapper>
      <ModalBase>
        <h2>🎉 Awesome!</h2>
        {unreviewedSubmissionsCount > 0 ? (
          <p style={{ marginBottom: '1.5em' }}>
            <b>{unreviewedSubmissionsCount} more submission(s)</b> left to review.
          </p>
        ) : (
          <p style={{ marginBottom: '1.5em' }}>
            You’re up to date with all your reviews.
          </p>
        )}

        <div
          style={{
            gap: '2em',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={onCloseModal}>Ok ✕</Button>
          {unreviewedSubmissionsCount ? (
            <Button primary onClick={onNextSubmission}>
              Let's go →
            </Button>
          ) : null}
        </div>
      </ModalBase>
    </ModalWrapper>,
    modalRoot
  );
}
