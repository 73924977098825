import React from 'react';
import styled from 'styled-components';
import Markdown from '../../components/Markdown';
import SimplePage from '../../components/SimplePage';

const md = `# Reviewing Submissions

## **Overview**

Submitting reviews for Learners is a **key element** to being an effective Mentor, and should be prioritized especially in the first few weeks of a Cycle.

Submissions and projects should be reviewed within **24 hours** from when the Learner submits their attempt. You will receive a reminder message for any outstanding submissions that need to be reviewed and **should be spending 30-60min/week/Learner on this**.

Mentors:

- Review Learners' activity submissions in the [Submissions page](https://mentor.enki.com/submissions)
- Keep updated notes on each Learner; utilizing the note section on each [Learner's profile](https://mentor.enki.com/learners) to track progress

> **💡 Mentor Tip**: Review assignments content before a Track starts and adapt to a Learners' style. Be open-minded as they may all need different things!
> 

## **How to review Learner assignments:**

1. **Give a star rating:**
    - 5 is the maximum score (i.e. perfect - not much to improve)
    - 1 is the the minimum score (i.e. completely missed the answer; lots of feedback expected)
    - You should assign points based on the completed work:
        - If answers are shorter than expected but still correct, 4-5 stars is recommended
        - Follow up with the Learner afterwards and ask them to give more detail next time if you feel like they should have elaborated more.
2. **Provide written feedback:** here is your opportunity to guide the Learner. Think about what they may have missed, did they use the best approach to answer the question? What could they have expanded further on?
    - It is always helpful to give 'real-world' examples - try to always tie them back to the Learner and how it's relevant to their current work.

## **What makes a great review?**

Provide written feedback based on Learner's questions/feedback. If the Learner got all of the answers correct and had no feedback, you should still offer a note of encouragement for understanding the material and ask them how they felt about the level of difficulty.

It is important to **provide a learning experience in each review**, point out where they could have elaborated further, or if they could have considered a different approach that may have been more appropriate.

### **Examples of great reviews:**

**Track**: Data 101

**Mission**: A/B Testing

**Why is this a good review**: While providing useful feedback to the Learner, the Mentor also takes the time to expand on the Learners' answer and give real-world examples. They are also ensuring that the Learner understands the importance of interpreting the data correctly and using the data to build out a good product.

> Yes, certainly AB testing is very important when it comes to the deployment of software applications. You want to test how the user experience is for both applications. You also want to ensure that you don't create an app at the expense of security. Data security is very important in this day and age.
> 
> We will explore more on this topic as we go through the Track. Good job with providing a perspective on how data can be used to calculate the security risks you can be exposed to when building out a solution!
> 


**Track:** Data 101
**Mission:** Top mistakes (part 1)

**Why this is a good review:** In this example, the Mentor has gone through the Learners' answers and has a good understanding of the question and how they would have answered the questions. Using their expertise, they help identify areas where the Learner could have used a different approach to answer the question better and help guide them to take additional steps in their analysis process in the future.

> For the first question, you will want to identify where the data is obtained and how the calculation was done. You want to think about the source and the accuracy of the data. You also want to consider how the "average" was calculated. Did he use any measure of centers (mean, median, mode) when calculating the data? Are there outliers? 
>
> With regard to the second question, The median eliminates outliers and is not greatly affected by the outlier. The other approach you might take is to eliminate the outlier and then calculate the average using the mean. You also want to use more data because using the 100 most recent data points might not be the most accurate and doesn't represent the entire dataset. You want to eliminate outliers while also paying attention to sampling bias. 
>
> Finally, the next step is to ensure that they have an objective (what the goal of the analysis is). They should also define which revenue they're actually working with, for example, Gross Revenue or Net Revenue. The fact that the mean is the same as the median might represent a symmetrical dataset. It can also represent an error in the data.
> 


**Track:** Data 101
**Mission:** Correlation

**Why is this a good review:** With this review, the Mentor has made a point of asking the Learner to take it a step further and to ask some questions about the data they are analyzing and what external factors should also be taken into account.

> 💡 Good answers for Q1. I think I would have added 7 to the list of correlated relationships. However, correlation/causation is difficult because multiple factors are involved and you could really argue both sides on some of these. 
>
> For Q2, if you look at the 2nd chart you can see that marketing spend and revenue are correlated. As you mentioned Team A is outperforming Team B. It might be worth asking what strategies Team A is using as they are generating more sales.
>`;

const HalfWidthContainer = styled.div`
  margin: 0 25%;

  p,
  ul,
  ol {
    margin-bottom: 1em;
  }
`;

export default function ReviewingResourcePages() {
  return (
    <SimplePage>
      <HalfWidthContainer>
        <Markdown md={md} />
      </HalfWidthContainer>
    </SimplePage>
  );
}
