import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#70ffbc',
    primaryBorder: '#3beba8',
    primaryBg: 'rgba(59, 235, 168, 0.1)',

    text: '#ffffff',
    textSecondary: '#a7aec8',
    textDanger: '#E54073',
    textSuccess: '#24CB8C',

    pageBg: '#202029',

    sectionBg: '#383d55',
    sectionBorder: '#585e7e',

    sectionDivider: '#7f89bb',

    elementBg: '#2e334d',
    elementBgSuccess: 'rgba(59, 235, 168, 0.16)',
    elementBgDanger: 'rgba(229, 64, 115, 0.16)',

    bgDanger: 'rgba(229, 64, 115, 0.16);',
  },
  dimensions: {
    sectionBorderRadius: '8px',
  },
  fonts: {
    playground: `Monaco, Menlo, 'Ubuntu Mono', Consolas, source-code-pro, monospace`,
  },
  shadows: {
    default: '0px 0px 20px rgba(0, 0, 0, 0.4)',
    light: '0px 0px 10px rgba(0, 0, 0, 0.2)',
  },
  accents: {
    lightRed: '#ffa6b7',
    lightTeal: '#a6ffee',
    lightYellow: '#ffeea6',
    lightBlue: '#a6b7ff',
  },
};

export default theme;
