import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import Card from '../Card';
import Markdown from '../Markdown';
import MarkdownEditor from '../Markdown/MarkdownEditor';
import SKILL_TASKS from './skills-tasks';

type SkillType = {
  identifier: string;
  name: string;
  isSelected: boolean;
  isConfirmed: boolean;
};

type SkillTaskAnswer = {
  identifier: string;
  sections: Array<{ question: string; answer: string }>;
};

export default function ConfirmSkillsWidget({
  skill,
  onConfirm,
  isSubmitting,
}: {
  skill: SkillType | undefined;
  onConfirm: (skill: SkillTaskAnswer) => void;
  isSubmitting: boolean;
}) {
  if (!skill || !Object.keys(SKILL_TASKS).includes(skill.identifier)) {
    return null;
  }

  const task = SKILL_TASKS[skill.identifier as keyof typeof SKILL_TASKS];

  return (
    <>
      <h2>Confirm {skill?.name}</h2>
      <p>Let's get you going as a mentor for {skill?.name}!</p>
      <p>
        This step involves two practical tasks which aim to give us a sense of
        how you'd interact with learners in the context of {skill?.name}. They
        will also give you a taste for some of the mentor tasks.
      </p>
      <p>
        If there's anything you need or would like to ask as you complete these,
        feel free to email us (
        <a href="mailto:concierge@enki.com">concierge@enki.com</a>) directly.
        Don't hesitate to ask any and all questions (if for example we didn't
        give enough context for a task or some information is missing). Our goal
        is to help you maximize the quality of your answers!
      </p>
      <p>The whole task should take you roughly 30 minutes.</p>
      {task.description && <Markdown md={task.description} />}
      <MarkdownSectionsForm
        questions={task.questions}
        onConfirm={onConfirm}
        identifier={skill?.identifier}
        isSubmitting={isSubmitting}
      />
    </>
  );
}

function MarkdownSectionsForm({
  questions,
  onConfirm,
  identifier,
  isSubmitting,
}: {
  questions: string[];
  onConfirm: (skill: SkillTaskAnswer) => void;
  identifier: string;
  isSubmitting: boolean;
}) {
  const [questionsWithAnswers, setQuestionsWithAnswers] = React.useState<
    Array<{ question: string; answer: string }>
  >(questions.map((q) => ({ question: q, answer: '' })));

  React.useEffect(() => {
    setQuestionsWithAnswers(
      questions.map((q) => ({ question: q, answer: '' }))
    );
  }, [questions]);

  return (
    <div>
      {questionsWithAnswers.map((section, index) => (
        <MarkdownSectionForm
          key={`markdown-section-${index}`}
          questionMd={section.question}
          answerMd={section.answer}
          index={index + 1}
          onChange={(answer) => {
            setQuestionsWithAnswers(
              questionsWithAnswers.map((q, i) => {
                if (i === index) {
                  return { ...q, answer };
                }
                return q;
              })
            );
          }}
        />
      ))}
      <Button
        primary
        disabled={
          questionsWithAnswers.some((q) => q.answer === '') || isSubmitting
        }
        onClick={() =>
          onConfirm({
            identifier,
            sections: questionsWithAnswers,
          })
        }
      >
        Submit Answers
      </Button>
    </div>
  );
}

const QuestionCard = styled(Card)`
  margin-bottom: 1em;
`;

function MarkdownSectionForm({
  questionMd,
  answerMd,
  index,
  onChange,
}: {
  questionMd: string;
  answerMd: string;
  index: number;
  onChange: (answer: string) => void;
}) {
  return (
    <QuestionCard>
      <h2>Question {index}</h2>
      <Markdown md={questionMd} />
      <MarkdownEditor md={answerMd} onChange={onChange} />
    </QuestionCard>
  );
}
