import styled from 'styled-components';

const Tag = styled.div`
  border-radius: 20px;
  color: black;
  padding: 0.25em 1em;
  margin: 1em 0.5em 1em 0em;
  text-align: center;
  background-color: ${(props: { color?: string }) => props.color || 'white'};
`;

export default Tag;
