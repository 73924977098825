import React from 'react';
import { AsyncStateRetry } from 'react-use/lib/useAsyncRetry';
import { useGetMentorSubmissions } from '../hooks/use-hydra';
import { MentorSubmissionsResponse } from '../hydra';

export const MentorSubmissionsContext = React.createContext<
  AsyncStateRetry<MentorSubmissionsResponse>
>({
  retry: () => {},
  loading: false,
  error: undefined,
  value: undefined,
});


export const MentorSubmissionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const submissions = useGetMentorSubmissions();

  return (
    <MentorSubmissionsContext.Provider value={submissions}>
      {children}
    </MentorSubmissionsContext.Provider>
  );
};
