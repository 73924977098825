import React from 'react';
import styled from 'styled-components';
import Markdown from '../../components/Markdown';
import SimplePage from '../../components/SimplePage';

const md = `# Billing and Admin

## **Time commitment**

On average we expect Mentors to spend **1 - 1.5 hrs/Learner/week** during an EnkiCamp

- If you find yourself spending significantly more than this, please reach out to Enki Ops as it means something's probably not quite right.
- There are situations where spending a little more time is expected:
    - You're new to EnkiCamp and getting used to the processes is taking more time than expected.
    - You're mentoring a new Track and you're adjusting to new content.

## **Submitting Invoices**

We use [Remote.com](http://remote.com/) to confirm invoices and settle payments. Please contact Enki Ops if you haven't received an onboarding link.

1. You should receive an email inviting you to onboard with [Remote.com](http://remote.com/)
2. And an email once you have completed the onboarding process for Remote

Within the **first 5 days of the month**, please use Remote to submit your invoice for the previous month's work.

- Head to the [Remote invoices dashboard](https://employ.remote.com/dashboard/invoices) and click '+ Add Invoice'
    
    ![https://img.enkipro.com/fe171ef57dc536395a3a9693850534c5.png](https://img.enkipro.com/fe171ef57dc536395a3a9693850534c5.png)
    
- Separate each Cycle week as a separate line item on your invoice.
    - e.g. a Mentor on Track 'A' mentoring students 'B' and 'C' for 2 weeks spending 2.5 and 1.5 hrs each week respectively, and on Track 'X' mentoring student 'Y' spending 1.5 hrs in the first week would have the following line items:
        - Mentoring B & C on Track A, Week 1, 2.5hrs
        - Mentoring B & C on Track A, Week 2, 1.5hrs
        - Mentoring Y on Track X, Week 1, 1.5hrs
- The invoice number should be your initials followed by 'R' followed by three digits. So for John Smith's 23rd Invoice, the invoice number would be JSR023
    
    ![https://img.enkipro.com/75604cf5b8435f6cb19cf64ec0525480.png](https://img.enkipro.com/75604cf5b8435f6cb19cf64ec0525480.png)
    

Enki will review your invoices during days 6-8 of the month and will process payments by day 12, exceptions include US and UK holidays.
`;

const HalfWidthContainer = styled.div`
  margin: 0 25%;

  p,
  ul,
  ol {
    margin-bottom: 1em;
  }
`;

export default function BillingResourcePage() {
  return (
    <SimplePage>
      <HalfWidthContainer>
        <Markdown md={md} />
      </HalfWidthContainer>
    </SimplePage>
  );
}
