import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled, { ThemeProvider } from 'styled-components';
import Loader from '../components/Loader';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import NotFound from '../components/NotFound';
import Home from './Home';
import Auth from './Auth';
import Submissions from './Submissions';
import { Auth0Provider } from '../contexts/auth0';
import { MentorProvider } from '../contexts/mentor';
import useAuth0 from '../hooks/use-auth0';
import { AuthProvider } from '../contexts/auth';
import useAuth from '../hooks/use-auth';
import { useHealthcheck } from '../hooks/use-hydra';
import { ButtonWrapper } from '../components/Button';
import theme from '../theme';
import * as CrashReporter from '../crash-reporter';
import * as Analytics from '../analytics';
import MentorAuthorizedRoute from '../components/MentorAuthorizedRoute';
import Profile from './Profile';
import ConfirmSkills from './ConfirmSkills';
import ScheduleCalendlyInterview from './ScheduleCalendlyInterview';
import SignContract from './SignContract';
import { CacheLocation } from '@auth0/auth0-spa-js';
import RequestError from '../components/RequestError';
import {
  getConfirmSkillsUrl,
  getHomeUrl,
  getLearnersUrl,
  getProfileUrl,
  getScheduleInterviewUrl,
  getSignContractUrl,
  getSubmissionsUrl,
  getCommunicationResourceUrl,
  getReviewingResourceUrl,
  getProjectsResourceUrl,
  getBestPracticesResourceUrl,
  getBillingResourceUrl,
  getFAQResourceUrl,
  getCyclesAndTracksResourceUrl,
} from '../routing';
import { MentorSubmissionsProvider } from '../contexts/mentor-submissions';
import { MentorLearnersProvider } from '../contexts/mentor-learners';
import { SubmissionsTimeSpentProvider } from '../contexts/submissions-time-spent';
import Learners from './Learners';

import CyclesAndTracksResourcePage from './Resources/CyclesAndTracks';
import CommunicationResourcePages from './Resources/Communication';
import ReviewingResourcePages from './Resources/Reviewing';
import ProjectsResourcePage from './Resources/Projects';
import BestPracticesResourcePage from './Resources/BestPractices';
import BillingResourcePage from './Resources/Billing';
import FAQsResourcePage from './Resources/FAQs';

const ErrorWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > ${ButtonWrapper}, > a {
    margin-top: 2em;
  }
`;

function RenderError() {
  return (
    <ErrorWrapper>
      <h1>Unfortunately, something went wrong</h1>
    </ErrorWrapper>
  );
}

function HydraAuthenticatedApplication() {
  const { isLoading, error, retry, user } = useAuth();

  React.useEffect(() => {
    if (user) {
      CrashReporter.setUser(user);
      Analytics.setUser(user);
      Analytics.trackEvent({ event: Analytics.EVENTS.AUTHENTICATE });
    }
  }, [user]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return RequestError({ error, retry });
  }

  return (
    <MentorProvider>
      <MentorLearnersProvider>
        <MentorSubmissionsProvider>
          <Switch>
            <Route exact path={getHomeUrl()}>
              <Home />
            </Route>
            <AuthenticatedRoute exact path="/auth" targetUrl="/">
              <Auth />
            </AuthenticatedRoute>
            <MentorAuthorizedRoute exact path={getProfileUrl()} targetUrl="/">
              <Profile />
            </MentorAuthorizedRoute>
            <MentorAuthorizedRoute
              exact
              path={getConfirmSkillsUrl()}
              targetUrl="/"
            >
              <ConfirmSkills />
            </MentorAuthorizedRoute>
            <MentorAuthorizedRoute
              exact
              path={getScheduleInterviewUrl()}
              targetUrl="/"
            >
              <ScheduleCalendlyInterview />
            </MentorAuthorizedRoute>
            <MentorAuthorizedRoute
              exact
              path={getSignContractUrl()}
              targetUrl="/"
            >
              <SignContract />
            </MentorAuthorizedRoute>
            <MentorAuthorizedRoute
              exact
              path={getSubmissionsUrl()}
              targetUrl="/"
            >
              <SubmissionsTimeSpentProvider>
                <Submissions />
              </SubmissionsTimeSpentProvider>
            </MentorAuthorizedRoute>
            <MentorAuthorizedRoute exact path={getLearnersUrl()} targetUrl="/">
              <Learners />
            </MentorAuthorizedRoute>
            <MentorAuthorizedRoute
              exact
              path={getCyclesAndTracksResourceUrl()}
              targetUrl="/"
            >
              <CyclesAndTracksResourcePage />
            </MentorAuthorizedRoute>
            <MentorAuthorizedRoute
              exact
              path={getCommunicationResourceUrl()}
              targetUrl="/"
            >
              <CommunicationResourcePages />
            </MentorAuthorizedRoute>
            <MentorAuthorizedRoute
              exact
              path={getReviewingResourceUrl()}
              targetUrl="/"
            >
              <ReviewingResourcePages />
            </MentorAuthorizedRoute>
            <MentorAuthorizedRoute
              exact
              path={getProjectsResourceUrl()}
              targetUrl="/"
            >
              <ProjectsResourcePage />
            </MentorAuthorizedRoute>
            <MentorAuthorizedRoute
              exact
              path={getBestPracticesResourceUrl()}
              targetUrl="/"
            >
              <BestPracticesResourcePage />
            </MentorAuthorizedRoute>
            <MentorAuthorizedRoute
              exact
              path={getBillingResourceUrl()}
              targetUrl="/"
            >
              <BillingResourcePage />
            </MentorAuthorizedRoute>
            <MentorAuthorizedRoute
              exact
              path={getFAQResourceUrl()}
              targetUrl="/"
            >
              <FAQsResourcePage />
            </MentorAuthorizedRoute>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </MentorSubmissionsProvider>
      </MentorLearnersProvider>
    </MentorProvider>
  );
}

function Auth0AuthenticatedApplication() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loader />;
  }
  return (
    <AuthProvider>
      <HydraAuthenticatedApplication />
    </AuthProvider>
  );
}

const auth0Config = {
  domain: 'auth.enki.com',
  client_id: 'wdW9AB82PJ1xrI1gHSOkXGv3NjkdCl47',
  audience: 'https://desktop.enki.com',
  // for the two settings below, see: https://www.notion.so/enki/Users-cannot-login-on-Safari-or-Chrome-Incognito-c902fdfbabbd4906b4fa44d5e24b94fa
  cacheLocation: 'localstorage' as CacheLocation,
  useRefreshTokens: true,
};

function AuthenticatedApplication() {
  const history = useHistory();

  const { loading, error, value, retry } = useHealthcheck();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return RequestError({ error, retry });
  }

  console.info(`Backend: ${value}`);

  return (
    <Auth0Provider
      config={auth0Config}
      redirect_uri={window.location.origin}
      onRedirectCallback={(appState) => {
        history.replace(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        );
      }}
    >
      <Auth0AuthenticatedApplication />
    </Auth0Provider>
  );
}

function RoutedApplication() {
  return (
    <Route path="/">
      <ToastContainer
        position="top-center"
        className="enki-toast-container"
        toastClassName="enki-toast"
        progressClassName="enki-toast-progress"
      />
      <Switch>
        {/* public routes can go here */}
        <AuthenticatedApplication />
      </Switch>
    </Route>
  );
}

function Application() {
  return (
    <CrashReporter.ErrorBoundary FallbackComponent={RenderError}>
      <Router>
        <ThemeProvider theme={theme}>
          <RoutedApplication />
        </ThemeProvider>
      </Router>
    </CrashReporter.ErrorBoundary>
  );
}

export default Application;
