import { useEffect } from 'react';

export default function useCleanGlobalClasses({
  isLoading,
}: {
  isLoading: boolean;
}) {
  useEffect(() => {
    // handle theme.css responsive loading transition
    // by adding the animation class and then removing it
    // (so that the animation is triggered)
    if (!isLoading) {
      document.body.classList.add('is-preload');
      setTimeout(() => {
        document.body.classList.remove('initial');
        document.body.classList.remove('is-preload');
      });
    }
  }, [isLoading]);
}
