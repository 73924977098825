import React from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import PageWrapper from '../components/PageWrapper';
import useAuth0 from '../hooks/use-auth0';
import useCleanGlobalClasses from '../hooks/use-clean-global-classes';
import useMentorContext from '../hooks/use-mentor';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import useScript from '../hooks/use-html-script';
import useQuery from '../hooks/use-url-query';
import { getHomeUrl } from '../routing';

const PageContentWrapper = styled.div`
  padding: 2em;
  background-color: ${(prop) => prop.theme.colors.sectionBg};
  border: 1px solid ${(prop) => prop.theme.colors.sectionBorder};
  border-radius: ${(prop) => prop.theme.dimensions.sectionBorderRadius};
`;

export default function SignContract() {
  const { isLoading, logout, auth0User, isAuthenticated } = useAuth0();
  const { value: mentorProfileResponse } = useMentorContext();
  const history = useHistory();

  // scripts used for contract iframe auto-resize
  // https://esignatures.io/docs/api#embedded-signing
  useScript('https://code.jquery.com/jquery-3.4.1.min.js');
  useScript('https://cdncf.esignatures.io/assets/iframeResizer.4.2.10.min.js');

  useCleanGlobalClasses({ isLoading });

  const query = useQuery();

  React.useEffect(() => {
    // note, this won't be trigger post contract sign
    // the status update happens through a webhook, and the mentor client is not notified about the update to CONTRACT_TASK_COMPLETED

    // we still want this effect to redirect people that shouldn't be signing the contract
    if (mentorProfileResponse?.profile?.status !== 'CONTRACT_TASK_SHARED') {
      history.push(getHomeUrl());
    }
  });

  const isSigned = query.get('isSigned') === 'true';

  React.useEffect(() => {
    if (isSigned) {
      toast.success('Contract signed successfully!');
    }
  }, [isSigned]);

  const contractURL = mentorProfileResponse?.profile?.contract.url;

  return (
    <>
      <Navbar
        auth={{
          isLoading,
          isAuthenticated,
          user: auth0User,
          logout,
        }}
      />
      <PageWrapper>
        <PageContentWrapper>
          <h1>Sign the contract</h1>
          {isSigned ? <PostSign /> : <Contract url={contractURL || ''} />}
        </PageContentWrapper>
      </PageWrapper>
    </>
  );
}

function Contract({ url }: { url: string }) {
  return (
    <>
      {' '}
      <p>You'll need to verify your email for this!</p>
      <div
        dangerouslySetInnerHTML={{
          __html: `
<iframe
src="${url}"
id="eSignaturesIOIframe"
onload="iFrameResize({heightCalculationMethod: 'bodyScroll'}, '#eSignaturesIOIframe')"
style="width: 1px;min-width: 100%;">
</iframe>
`,
        }}
      />
    </>
  );
}

function PostSign() {
  return (
    <>
      <p>
        Nice one! Once we've signed the contract too, we'll send you a copy on
        your email.
      </p>
      <p>
        Lastly, if you aren't on Slack already, keep in eye out on your email
        for an invite to our Slack Community.
      </p>
    </>
  );
}
