import React from 'react';
import { AsyncStateRetry } from 'react-use/lib/useAsyncRetry';
import { useGetMentorLearners } from '../hooks/use-hydra';
import { MentorLearnersResponse } from '../hydra';

export const MentorLearnersContext = React.createContext<
  AsyncStateRetry<MentorLearnersResponse>
>({
  retry: () => {},
  loading: false,
  error: undefined,
  value: undefined,
});

export const MentorLearnersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const learners = useGetMentorLearners();

  return (
    <MentorLearnersContext.Provider value={learners}>
      {children}
    </MentorLearnersContext.Provider>
  );
};
