import React, { useState, useEffect } from 'react';
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  IdToken,
  LogoutOptions,
  RedirectLoginOptions,
  User,
  GenericError,
} from '@auth0/auth0-spa-js';
import * as Analytics from '../analytics';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

type Auth0ContextType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  auth0User: User | null;
  popupOpen: boolean;
  getTokenSilently: (
    options?: GetTokenSilentlyOptions
  ) => Promise<string> | undefined;
  loginWithPopup: (params?: {}) => Promise<void> | undefined;
  handleRedirectCallback: () => Promise<void> | undefined;
  getIdTokenClaims: (
    options?: GetIdTokenClaimsOptions
  ) => Promise<IdToken | undefined> | undefined;
  loginWithRedirect: (
    params?: RedirectLoginOptions
  ) => Promise<void> | undefined;
  getTokenWithPopup: (
    p: GetTokenWithPopupOptions
  ) => Promise<string> | undefined;
  logout: (p?: LogoutOptions | undefined) => void | Promise<void> | undefined;
  error: GenericError | null;
};

export const Auth0Context = React.createContext<Auth0ContextType>({
  isAuthenticated: false,
  auth0User: null,
  isLoading: true,
  popupOpen: false,
  getTokenSilently: () => undefined,
  loginWithPopup: () => undefined,
  handleRedirectCallback: () => undefined,
  getIdTokenClaims: () => undefined,
  loginWithRedirect: () => undefined,
  getTokenWithPopup: () => undefined,
  logout: () => undefined,
  error: null,
});

export const Auth0Provider = ({
  config,
  redirect_uri,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  children,
}: {
  config: Auth0ClientOptions;
  redirect_uri?: string;
  onRedirectCallback?: (appState?: any) => void;
  children: React.ReactNode;
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [auth0User, setAuth0User] = useState<User | null>(null);
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [isLoading, setIsLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  // TODO: should probably catch all auth0 calls
  const [error, setError] = useState<GenericError | null>(null);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0Client = await createAuth0Client({
        ...config,
        redirect_uri,
      });

      setAuth0(auth0Client);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0Client.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0Client.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const auth0User = await auth0Client.getUser();

        if (auth0User) {
          setAuth0User(auth0User);
        }
      }

      setIsLoading(false);
    };

    initAuth0();
  }, [config, redirect_uri, onRedirectCallback]);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);

    try {
      await auth0Client?.loginWithPopup(params);
    } catch (error) {
      console.error(error);
      setError(error as GenericError);
    } finally {
      setPopupOpen(false);
    }

    const auth0User = await auth0Client?.getUser();

    if (auth0User) {
      setAuth0User(auth0User);
      setIsAuthenticated(true);
    }
  };

  const handleRedirectCallback = async () => {
    setIsLoading(true);
    await auth0Client?.handleRedirectCallback();
    const auth0User = await auth0Client?.getUser();
    setIsLoading(false);

    if (auth0User) {
      setIsAuthenticated(true);
      setAuth0User(auth0User);
    }
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        auth0User,
        isLoading,
        popupOpen,
        loginWithPopup,
        error,
        handleRedirectCallback,
        getIdTokenClaims: (p?: GetIdTokenClaimsOptions) =>
          auth0Client?.getIdTokenClaims(p),
        loginWithRedirect: (p?: RedirectLoginOptions) =>
          auth0Client?.loginWithRedirect(p),
        getTokenSilently: (p?: GetTokenSilentlyOptions) =>
          auth0Client?.getTokenSilently(p),
        getTokenWithPopup: (p?: GetTokenWithPopupOptions) =>
          auth0Client?.getTokenWithPopup(p),
        logout: (p?: LogoutOptions) => {
          Analytics.removeUser();
          return auth0Client?.logout({
            returnTo: redirect_uri,
            ...p,
          });
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
