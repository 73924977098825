import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

const CardWrapper = styled.div`
  flex-direction: column;
  border-radius: 8px;
  background: ${prop('theme.colors.elementBg')};
  border: 1px solid ${prop('theme.colors.sectionBorder')};
  box-shadow: ${prop('theme.shadows.default')};
  padding: 1em;
`;

export default function Card({ children, ...rest }) {
  return <CardWrapper {...rest}>{children}</CardWrapper>;
}
