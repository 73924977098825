import React from 'react';
import theme from '../theme';
import useMentorContext from '../hooks/use-mentor';
import AuthenticatedRoute from './AuthenticatedRoute';
import Loader from '../components/Loader';
import { Redirect } from 'react-router-dom';
import RequestError from './RequestError';
import { getHomeUrl } from '../routing';
import { useEffectOnce } from 'react-use';

export function MentorAuthorizer({
  children,
  path,
}: React.PropsWithChildren<{
  path: string;
}>) {
  const { value, error, loading, retry } = useMentorContext();

  useEffectOnce(() => {
    retry();
  });

  if (loading) {
    return <Loader color={theme.colors.primary} />;
  }

  if (error) {
    return RequestError({ error, retry });
  }

  if (!value?.isMentor) {
    return <Redirect to={getHomeUrl()} />;
  }

  if (path !== '/profile' && value.shouldUpdateProfile) {
    return <Redirect to={'/profile'} />;
  }

  // returning and mounting "children" directly, which is potentially undefined, makes TS complain
  // wrapping it in a fragment ensures it's a JSX Element
  return <>{children}</>;
}

function MentorAuthorizedRoute({
  children,
  path,
  ...rest
}: React.PropsWithChildren<{
  path: string;
  exact: boolean;
  targetUrl?: string;
}>) {
  return (
    <AuthenticatedRoute path={path} {...rest}>
      <MentorAuthorizer path={path}>{children}</MentorAuthorizer>
    </AuthenticatedRoute>
  );
}

export default MentorAuthorizedRoute;
