const SKILLS_TASKS = {
  DATA_101: {
    description: `**For both questions below, suppose a (non-technical!) learner that you've been assigned has sent you a message in follow up to one of the Data 101 exercises they completed. 
    How would you answer them (using an asynchronous text message via Slack)?**`,
    questions: [
      `
Heya! I was wondering if you could help me understand why some people say that correlation does not imply causation?

Also, what I don't get is how to even prove a correlation… or if that's even necessary?!

How could I even practically apply correlations to make business decisions? Where do you think it makes most sense?`,
      `
Hey, for my project could you help me figure out how to do some forecasting?

I've been given a spreadsheet of yearly revenue generated by different products at my company; what's the simplest way to forecast next year's revenue?

Also, how do I calculate how “correct” this forecast is? Are there other methods that could work better?
`,
    ],
  },
  TABLEAU: {
    description: `**For both questions below, suppose a (non-technical!) learner that you've been assigned has sent you a message in follow up to one of the Tableau exercises they completed. 
    How would you answer them (using an asynchronous text message via Slack)?**`,
    questions: [
      `Hey. I'm struggling to understand how measures and dimensions work. Aren't they just fields in a table?

Why doesn't it show me all the rows in the table when I add the columns that I'm interested in? How could I generate that view then?

Please let me know if you can share any extra resources that could help me understand this better.`,
      `
Hey. I'm trying to plot two different aggregations on the same X axis (SUM vs AVERAGE).

However, when I drag-and-drop them in the shelf I get two plots instead of one.

How can I overlap the plots? Also, I anticipate that the AVERAGE will be way smaller than the SUM which may render the former almost invisible. Is there a recommended way to scale it up such that the plot is more readable?`,
    ],
  },
  SQL: {
    description: `To get started, take a look at [this optional activity about SELF JOINs](https://learn.enki.com/preview/missions/monday-2-v2/activities/self-joins-v2).

Make sure to go through the lesson text and try out the questions to get a sense for the material shared with learners.

For context, before this activity, learners will have already covered:

- INNER JOINs
- single-table queries (i.e. SELECT, WHERE, etc)
- basic DML commands (i.e. UPDATE, CREATE TABLE, DELETE)

**For both submissions below, suppose a (non-technical!) learner that you've been assigned has sent you a message in follow up to one of the SQL exercises they completed. 
  How would you answer them (using an asynchronous text message via Slack)?**
> Tip: Mentor solutions aren't necessarily correct. You might have to manually test their submissions in order to see what was wrong with them.
`,
    questions: [
      `Feedback:
> I got the answers right but definitely need more practice - can you suggest additional practice questions? I understand the concepts but shaky on execution.
> 

Difficulty: *4/5*

Solution:
\`\`\`sql
-- question 1
SELECT
employee.name AS "employees' names",
employee.email AS "employees' emails",
manager.name AS "Manager"
FROM employees AS employee
INNER JOIN employees AS manager
  ON employee.manager_id = manager.id;

\`\`\`

\`\`\`sql
-- question 2
SELECT
  DISTINCT second.email
FROM
  employees first
  JOIN employees second ON first.manager_id = second.id
ORDER BY
  second.email
\`\`\`
    `,
      `Feedback:
> I found the last question extremely confusing without the reference
> 

*Difficulty: 5/5*

Solution:

\`\`\`sql
-- question 1
SELECT
  employee.name AS "Employees' Name",
  employee.email AS "Employees'Email",
  manager.name AS "Manager"
FROM employees AS employee
INNER JOIN employees AS manager
    ON employee.manager_id = manager.id;

\`\`\`

\`\`\`sql
-- question 2
SELECT
  DISTINCT
  email
FROM
  employees as "employees"
  manager as "manager"
WHERE "manager_id" = "manager"
\`\`\`
      `,
    ],
  },
  LOOKER: {
    description: `**For both questions below, suppose a (non-technical!) learner that you've been assigned has sent you a message in follow up to one of the Looker exercises they completed. 
    How would you answer them (using an asynchronous text message via Slack)?**`,
    questions: [
      `Hey. I'm struggling to understand how measures and dimensions work. Aren't they just fields in a table?

Why doesn't it show me all the rows in the table when I add the columns that I'm interested in? How could I generate that view then?

Please let me know if you can share any extra resources that could help me understand this better.`,
      `
I'm trying to calculate a moving average for one of my Explore measures and managed to do so with this table calculation:

\`mean(offset_list(\${name_game.count},0,10))\`

However, I have a few questions:
- as the window size is 10, how can I make it skip the first 10 days. it seems the MAs for the first 10 items are really MA1, MA2, ... MA9 as they only take a subset of data into consideration. I would like to add NULL values for these first rows 
- do you think this approach will work well if the dataset contains NULL values? 

I also attached a picture with my explore, hope it helps!

![](https://img.enkipro.com/c7fe2bb3cd5ea35611a0d81964b42e78.png)`,
    ],
  },
  SHEETS: {
    description: `Suppose some **non-technical** learners in your class are having issues understanding some concepts, and have messaged you asking for help in Slack.

How would you answer their questions (via text only)?`,
    questions: [
      `Hey there! Can you help me understand how to use VLOOKUP in Google Sheets? Here's an example: I have a table of customer orders, and I want to look up the customer name based on their order ID. How could I use VLOOKUP to do this? And what do each of the inputs in the function mean?`,
      `Hey, I was wondering if you could help me construct a pivot table for some data that I have in a specific way? Let's say I've got a table of sales from multiple employees spread across multiple departments and regions over the course of 5 years. The customization menu is overwhelming- how do I know where to drag what field into what menu/box?`,
    ],
  },
};

export default SKILLS_TASKS;
