import React from 'react';
import styled from 'styled-components';
import Markdown from '../../components/Markdown';
import SimplePage from '../../components/SimplePage';

const md = `# Best Practices

**Before a Cycle Starts**

- Make sure to keep your availability information in the [Profile page](https://mentor.enki.com/profile) updated.
- If you're mentoring a specific Track for the first time, go through the **Learner preview dashboard** ([Data 101](https://learn.enki.com/preview/data-101/dashboard), [Looker](https://learn.enki.com/preview/looker/dashboard), [Looker 101](https://learn.enki.com/preview/looker-2-weeks/dashboard), [Tableau](https://learn.enki.com/preview/tableau/dashboard), [SQL](https://learn.enki.com/preview/sql/dashboard)) to familiarize yourself with the upcoming content, especially the first few days worth of activities.

**Getting Started on a Cycle**

- Introduce yourself to your Learner(s) via DM and confirm or reschedule your 1:1 times.
    - Remind Learners you're there to help if they have any questions.
- Review onboarding survey answers for each Learner to get a sense of who they are and what their end goal is with each Track.

**Add an [m] to your name in Slack**

- Doing so clearly denotes to Learners that you are a **Mentor** and there to help
    - Note that you will update the area labeled 'Full Name,' as opposed to 'Display Name'

**1:1 Calls**:

- **During the first 1:1 cal**l, use this time to review your Learners' end goals with the Track, their learning style, how you can best mentor them, understand their communication style, and start brainstorming on their projects
- Set an agenda for each call (See [Communication with Learners](https://mentor.enki.com/resources/comms-with-learners) page for more detail)

**Utilize the note section on each Learner's profile:**

- Share key info and useful insights from your interactions with Learners, especially after your 1:1s
- Add **notes** to document information and progress about your Learner.
    - We recommend documenting every meaningful interaction (e.g. project updates or work updates like their tool stack or upcoming PTO).
- You can think of Learner notes as a personal notebook; make sure they are complete and clear enough so that any other Mentor could take over for that Learner if necessary.

> 💡 **All Learner notes are automatically reported in the status channel so that we all stay updated on Learner progress.**
> 

**Reviews:**

- Check on a daily basis for new submissions to review to ensure that Learners are receiving feedback in a timely manner.
- Learners expect to receive their review **within 24 hours** typically. Try to get the review done as early as possible so the feedback the Learner receives is as relevant as possible for their development.

**Questions:**

- Please let Enki Ops know if something is unclear, broken, or inefficient. Over-communication is key!
- We're here to help you be an awesome Mentor for Learners, but we can only support you to that end if you share issues or areas to improve with us.

**Help drive engagement:**

- Engage with posts in the **#discuss** channel and share anything you think is valuable from 1:1s with Learners
- We can all benefit from questions raised in your 1-1 as well as any anecdotes you have from your own experience. Don't be shy!

**Tips from previous mentors:**

- Review assignment content ahead
- Adapt to Learners' style, be open minded as they all need different things
- Use Slack liberally, otherwise, Learners will hold questions till their next 1:1's
- Set expectations early on
- Review Learners' onboarding answers to get a sense of each Learner`;

const HalfWidthContainer = styled.div`
  margin: 0 25%;

  p,
  ul,
  ol {
    margin-bottom: 1em;
  }
`;

export default function BestPracticesResourcePage() {
  return (
    <SimplePage>
      <HalfWidthContainer>
        <Markdown md={md} />
      </HalfWidthContainer>
    </SimplePage>
  );
}
