import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { toast } from 'react-toastify';
import useAuth0 from '../../hooks/use-auth0';
import useMentorLearnersContext from '../../hooks/use-mentor-learners';

import { createLearnerNote, MentorLearner } from '../../hydra';
import Button from '../Button';
import * as tableConfigs from '../../table-configs';
import Table from '../Table';
dayjs.extend(utc);

export default function Notes({ learner }: { learner: MentorLearner }) {
  const { retry: refreshLearnersContext } = useMentorLearnersContext();
  const [note, setNote] = React.useState('');
  const [type, setType] = React.useState<'student' | 'project' | 'company'>(
    'student'
  );
  const { getTokenSilently } = useAuth0();

  const saveNote = async () => {
    const token = await getTokenSilently();
    if (token) {
      await createLearnerNote(token, {
        learnerId: learner.learner.id,
        note,
        type,
      });
      setNote('');
      refreshLearnersContext();
      toast.success('Successfully saved the note!');
    }
  };

  return (
    <div style={{}}>
      <h4>Note</h4>
      <textarea
        placeholder="Write a new note here"
        rows={note.split('\n').length}
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <h4 style={{ marginTop: '1em' }}>Note type</h4>
      <select
        style={{ marginBottom: '1.5em', width: 'initial' }}
        value={type}
        onChange={(e) =>
          setType(e.target.value as 'student' | 'company' | 'project')
        }
      >
        <option value={'student'}>Learner</option>
        <option value={'project'}>Project</option>
        <option value={'company'}>Company</option>
      </select>
      <Button
        primary
        disabled={!note.length}
        onClick={saveNote}
        style={{ marginBottom: '2em' }}
      >
        Save note
      </Button>
      <Table<{ text: string; type: string; date: string }>
        data={learner.notes}
        columnConfigs={tableConfigs.notes.getColumns()}
        fallbackCopy="No notes"
        filters={[]}
        defaultSortDirection="desc"
        defaultSortIndex={2}
      />
    </div>
  );
}
