import React from 'react';
import styled from 'styled-components';
import Markdown from '../../components/Markdown';
import SimplePage from '../../components/SimplePage';

const md = `# Communication

## Overview

Every Learner is paired with a Mentor that's there to help them throughout their learning EnkiCamp journey. Mentors help Learners overcome challenging material, give useful feedback and help apply new skills to hands-on projects.

**Where you will communicate**: 

- [Slack](http://enkicamp.slack.com/) - For each Track you'll be added to two channels in EnkiCamp Slack:
    
    - **#discuss:** for all Track-related questions your Learner may have. You should also participate with any prompts and involve your learners as much as possible in various discussions, driving engagement.
    
    - **#status:** or you to monitor automatic Track updates, post any questions for Enki Ops and give any tips or comments that may be helpful for other Mentors.

    - **1-1 DMs:** use this to discuss admin with your Learner or Enki Ops (e.g. 1-1 timings) 

- Video calls - 1:1, Mentor:Learner, weekly sync-ups to cover more in-depth questions, feedback and project planning
    - Enki Ops will set up a calendar invite with a Google Meets link for you and each of your Learners, but feel free to use any video conferencing tool (e.g. Zoom).
    - During your first 1:1 you should focus on:
        - Discussing your Learners' end goals with the Track
        - Their learning and communication style
        - How you can best mentor them
        - Start brainstorming on their projects
    - For the remaining 1:1s you should build an agenda and read any Learner notes to get up to speed with your Learners' progress. Make sure to leave space for:
        - Project discussions
        - Learners' performance, e.g.:
            - what activities took too much time,
            - what difficulty rating they've given each activity,
            - what activities were skipped etc.
        - Answering any questions the learner might have
        - Planning what the Learner might share in the Showcase or getting ready for the next Cycle

## How to communicate with your Learners:

Being an effective Mentor means helping drive engagement and making sure that Learners don't ***disengage***. Mentors are there to help bridge the gap between what Learners are covering in their Tracks and how to directly apply what they are learning to their work.

**Best practices for effective communication:**

- Build a relationship with each Learner by understanding their motivations when learning
    - What motivates them individually?
        - Are they motivated by task accomplishment and achieving results
        - Or by meaningful order and thinking things through
- Ask open-ended question
    - Ask questions in a way that resonates with each Learner
    - Vary the style of questions dependent on the need to learn, confirm, or persuade
- Be open-minded
    - Without an open mind, you can't listen with the intent to understand and learn first. When you listen, you create an environment where your Learner is more engaged and open too.
    

**How to help increase engagement:**

- Set goals with each Learner - figure out their end goal, and help set milestones to achieve those goals
- Ask your Learners questions about the content and how it applies to their work
    - Help guide them with this, give examples and ideas of your own experience
- Checking your Learners' progress to make sure they are completing assignments
    - Tag them in a question on the **#discuss** channel as a 'gentle reminder” if they are falling behind
- Post useful resources in the **#discuss** channel`;

const HalfWidthContainer = styled.div`
  margin: 0 25%;

  p,
  ul,
  ol {
    margin-bottom: 1em;
  }
`;

export default function CommunicationResourcePages() {
  return (
    <SimplePage>
      <HalfWidthContainer>
        <Markdown md={md} />
      </HalfWidthContainer>
    </SimplePage>
  );
}
