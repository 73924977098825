import React, { Dispatch, Reducer } from 'react';

type State = Array<{
  submissionType: 'activity' | 'project';
  submissionId: number;
  pageFocusTimestampIntervals: Array<[Date, Date | undefined]>;
}>;

type Action =
  | {
      submissionType: 'activity' | 'project';
      submissionId: number;
      type: 'start';
    }
  | { type: 'stop' };

export const SubmissionsTimeSpentContext = React.createContext<
  [State, Dispatch<Action>]
>([[], () => {}]);

export const SubmissionsTimeSpentProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = React.useReducer<Reducer<State, Action>>(
    timeSpentReducer,
    []
  );

  return (
    <SubmissionsTimeSpentContext.Provider value={[state, dispatch]}>
      {children}
    </SubmissionsTimeSpentContext.Provider>
  );
};

function closeIntervals(stateItem: State[0]): State[0] {
  return {
    ...stateItem,
    pageFocusTimestampIntervals: stateItem.pageFocusTimestampIntervals.map(
      (interval) => (!interval[1] ? [interval[0], new Date()] : interval)
    ),
  };
}

const timeSpentReducer = (state: State, action: Action): State => {
  if (action.type === 'stop') {
    return state.map(closeIntervals);
  }

  const submissionIdx = state.findIndex(
    (item) =>
      item.submissionId === action.submissionId &&
      item.submissionType === action.submissionType
  );

  const stateWithIntervalsClosed = state.map(closeIntervals);

  if (submissionIdx === -1) {
    return [
      ...stateWithIntervalsClosed,
      {
        submissionType: action.submissionType,
        submissionId: action.submissionId,
        pageFocusTimestampIntervals: [[new Date(), undefined]],
      },
    ];
  }

  return stateWithIntervalsClosed.map((item, idx) =>
    idx === submissionIdx
      ? {
          ...item,
          pageFocusTimestampIntervals: [
            ...item.pageFocusTimestampIntervals,
            [new Date(), undefined],
          ],
        }
      : item
  );
};

export function getSubmissionSecondsSpent({
  submissionType,
  submissionId,
  submissionsIntervals,
}: {
  submissionType: 'activity' | 'project';
  submissionId: number;
  submissionsIntervals: State;
}): number {
  const submissionInterval = submissionsIntervals.find(
    (item) =>
      item.submissionId === submissionId &&
      item.submissionType === submissionType
  );

  if (!submissionInterval) {
    return 0;
  }

  return submissionInterval.pageFocusTimestampIntervals.reduce(
    (total: number, [start, end = new Date()]) =>
      total + secondsBetweenTimestamps(start, end),
    0
  );
}

const secondsBetweenTimestamps = (start: Date, end: Date): number =>
  (end.getTime() - start.getTime()) / 1000;
