import styled, { useTheme } from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const RatingHoverWrapper = styled.div<{
  isDisabled: boolean;
  selectedColor: string;
}>`
  ${(props) =>
    !props.isDisabled
      ? `
      i:hover,
      i:hover ~ i {
        color: ${props.selectedColor} !important;
        cursor: pointer;
      }
  `
      : `opacity: 0.5`}
`;

export function Ratings({
  score,
  isDisabled = false,
  setScore,
}: {
  score: number | null;
  isDisabled?: boolean;
  setScore?: (score: number) => void;
}) {
  const theme = useTheme();
  return (
    <div>
      <Row>
        <RatingHoverWrapper
          isDisabled={isDisabled}
          selectedColor={theme.colors.primary}
          style={{
            flexDirection: 'row-reverse',
            display: 'flex',
            gap: '0.1em',
          }} // reversing presentation order in order to use the "i:hover ~ i" selector
        >
          {[...Array(5)].map((_, i) => (
            <span
              key={`rating-${i}`}
              style={{
                display: 'inline-block',
                fontSize: '1.5em',
                color:
                  (score || 0) >= 5 - i
                    ? theme.colors.primary
                    : theme.colors.textSecondary, // 5 - 1 bc order is reversedu
              }}
              className={`fa-solid fa-star`}
              onClick={
                !isDisabled && setScore ? () => setScore(5 - i) : () => {}
              } // 5 - 1 bc order is reversed
            />
          ))}
        </RatingHoverWrapper>
      </Row>
    </div>
  );
}
