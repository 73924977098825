import React from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth0 from '../hooks/use-auth0';
import Alert from '../components/Alert';
import Loader from '../components/Loader';
import theme from '../theme';

export default function Auth() {
  const { isLoading, error, auth0User } = useAuth0();

  if (isLoading) {
    return <Loader color={theme.colors.primary} />;
  }

  if (error) {
    return <Alert type="error" text={error.message} />;
  }

  if (auth0User) {
    toast.warn(`Oops, looks like you are already authenticated`);
    return <Redirect to="/" exact />;
  }

  return <Alert type="primary" text={'Almost there...'} />;
}
