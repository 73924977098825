export type SkillType = {
  identifier: string;
  name: string;
  isSelected: boolean;
  isConfirmed: boolean;
  isSubmitted: boolean;
};

export function getSkillStatus(skill: SkillType) {
  let status = 'LOCKED';
  if (skill.isSelected) {
    status = 'NOT CONFIRMED';
  }
  if (skill.isSubmitted) {
    status = 'SUBMITTED';
  }
  if (skill.isConfirmed) {
    status = 'CONFIRMED';
  }
  return status;
}
