import React from 'react';
import styled from 'styled-components';
import Markdown from '../../components/Markdown';
import SimplePage from '../../components/SimplePage';

const md = `# FAQs

## Contents


## **What do I do if a learner is 1/3/5 days behind or missing our 1:1's?**

- Learners often disengage or fall behind for lots of different reasons. However, it's mostly because online-only engagement flows are not enough to keep them engaged every day. The reality is that the human touch is needed to motivate busy professionals when they're learning and to keep them fully engaged.
    
    We expect 90%+ of each Learners to stay engaged (defined as completing 10+ days of exercises, and evidence of applying them in some way via the project). **The biggest factors that impact this are that:**
    
    a) they are engaged every single one of the first 5 days
    
    b) they don't fall further than 3 days behind
    
    One of the key responsibilities of the Mentor is to ensure both the goals above are met for their Learners.
    
    Make sure to review which of your Learners are 2+ days behind and **message them** to ask how we can support or help them catch up, and help them to be motivated to engage. If a:
    
    - **Learner is finding content too easy** → share external advanced resources with them
    - **Learner is blocked by content** → use your 1:1 call to help them become unblocked
    - **Learner is overwhelmed by the content they skipped** → point out the most important activities and let them know they should focus on tackling those as a priority

## **What do I do if a Learner doesn't know what to do for a project?**

- Ideally, this should be known through their submissions on project-related activities. However, there will be Learners who will delay the process and will not have a clear project idea late into the Cycle.
    
    You should always talk about projects **during the first 1:1 call** and try to encourage your Learner to settle on one idea and focus on it. They should understand the needs of their project and point out where they'll eventually need help with the most difficult aspects (e.g. set up a db for them to query).
    
    See [Projects](https://mentor.enki.com/resources/projects) for more info.
    

## **What do I do if I need to reschedule my 1:1 with my Learner?**

- Both Mentors and Learners have the option to edit the calendar invites for your 1:1's. We recommend reaching out to your Learner directly via Slack to find a time that works better for both of you.

## **How do I get in contact with an Enki team member if I need help?**

- To get in contact with the Enki Ops team feel free to message us via slack or reach out via email. We do recommend messaging both Daniel and Megan in the same message/ email to ensure you receive the fastest resolution.
    
    
    | Enki Operations | Slack | Email | Timezone |
    | --- | --- | --- | --- |
    | Daniel Makharinsky | [m] Daniel Makharinsky | daniel@enki.com | UK |
    | Megan Gaunt | [m] Megan Gaunt | megan@enki.com | USA-PST |

## **Do Learners see our review immediately?**

- Yes, as soon as you submit your final review for any mission or project, your Learner will be notified by Enkibot via Slack.
    
    They will also see your review on their Learner dashboard.
    

## **Is there anyway for my Learner to evaluate my work as a mentor?**

- Yes! Learners are sent a mid-Cycle check in and a retro survey asking them to evaluate their EnkiCamp experience as well as their experience with their Mentor.
    
    In addition, we do check-ins with Learners to see if they are finding your feedback helpful.
    

## **How do I report what I have been working on with my Learner?**

- A great way to keep track of your Learners' progress and documenting high-level feed back for areas to improve on is to add notes to your [Learners' profile](https://mentor.enki.com/learners?learner=all&topic=all&track=all&review=all). See [Best Practices](https://mentor.enki.com/resources/best-practices) for tips to keeping notes.

## **What do I do if a learner is experiencing technical difficulties?**

- Get in contact with the Enki Ops team, **or** instruct your learner to reach out to the Enki Ops team. You should contact both Daniel and Megan in the same message to ensure your Learner receives the fastest resolution.
    
    
    | Enki Operations | Slack | Email | Timezone |
    | --- | --- | --- | --- |
    | Daniel Makharinsky | [m] Daniel Makharinsky | daniel@enki.com | UK |
    | Megan Gaunt | [m] Megan Gaunt | megan@enki.com | USA-PST |

## **What do I do if someone is not at a company?**

- Generally, people outside a company can easily take our course as part of a company-centric Track.
    
    The main difference comes when discussing projects. While people from companies have a relatively obvious source of inspiration (eg. trying to apply their SQL skills on arbitrary data querying problems or even more fully-fledged projects) people without a company may be left in the dark.
    
    In this scenario, the Mentor should provide some extra support for these Learners in terms of picking a suitable project for them.
    
    Some project ideas can include:
    
    - designing your own database → [https://dbdiagram.io/home](https://dbdiagram.io/home) can be of help
    - running queries over custom data → public datasets can be easily found online either from GOV/Company sources (think of downloading your Spotify data) or from places like [kaggle.com](http://kaggle.com/). The main problem here is that data usually comes in CSV and not within a database which you can query. The learners might need support from their Mentor to import/set-up the data.`;

const HalfWidthContainer = styled.div`
  margin: 0 25%;

  p,
  ul,
  ol {
    margin-bottom: 1em;
  }

  h2 {
    font-size: 1.25em; // make h2s required by TOC easier to read
  }
  // hack to offset erroneous <a /> id scrolling caused by hacking header
  [id]::before {
    content: '';
    display: block;
    height: 5em;
    margin-top: -5em;
    visibility: hidden;
  }
`;

export default function FAQsResourcePage() {
  return (
    <SimplePage>
      <HalfWidthContainer>
        <Markdown md={md} showTOC />
      </HalfWidthContainer>
    </SimplePage>
  );
}
