import React from 'react';

export default function PageWrapper({ children, style = {} }) {
  return (
    <div id="wrapper">
      <div className="wrapper fullscreen fade-up">
        <div className="inner" style={style}>
          {children}
        </div>
      </div>
    </div>
  );
}
