import React from 'react';
import Markdown from '.';
import styled, { useTheme } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

const MarkdownWrite = styled.textarea`
  flex: 1;
`;

const MarkdownPreview = styled(Markdown)`
  flex: 1;
`;

const WidgetContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Caption = styled.p`
  font-style: italic;
  margin: 0.5em 0;
`;

export default function MarkdownEditor({
  md: defaultMd,
  onChange,
}: {
  md: string;
  onChange: (md: string) => void;
}) {
  const [md, setMd] = React.useState(defaultMd);
  const theme = useTheme();

  return (
    <Wrapper>
      <WidgetContainer>
        <h3>Write</h3>
        <MarkdownWrite
          onChange={(e) => {
            setMd(e.target.value);
            onChange(e.target.value);
          }}
          rows={5}
          value={md}
        />
        <Caption>
          <a href="https://www.markdownguide.org/cheat-sheet/#basic-syntax" target="_blank" rel="noopener noreferrer">
            Markdown formatting help
          </a>
        </Caption>
      </WidgetContainer>
      <WidgetContainer>
        <h3>Preview</h3>
        <MarkdownPreview
          md={md}
          style={{
            flex: 1,
            padding: '1em',
            backgroundColor: theme.colors.sectionBg,
          }}
        />
        <Caption style={{ visibility: 'hidden' }}>{'placeholder'}</Caption>
      </WidgetContainer>
    </Wrapper>
  );
}
