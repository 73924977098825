import React from 'react';
import useMentorContext from '../../hooks/use-mentor';
import styled from 'styled-components';
import Card from '../Card';
import { getSubmissionsUrl, getLearnersUrl } from '../../routing';
import { useHistory } from 'react-router-dom';
import useMentorSubmissionsContext from '../../hooks/use-mentor-submissions';

const Grid = styled.div`
  display: grid;
  display: grid;
  grid-template-columns: 8em 8em;
  gap: 1em;
`;

const ItemWrapper = styled(Card)`
  height: 8em;
  width: 8em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  transition: 1s;
  align-items: center;

  :hover {
    transform: translateY(-0.1em);
    transition: transform 0.5s;
    cursor: pointer;
  }
`;

const ItemTitle = styled.h4`
  margin: 0 0 0.25em;
`;

const ItemIcon = styled.i`
  margin: 0.25em 0;
  color: white;
  font-size: 3em;
`;

const NotificationBadge = styled.span`
  position: absolute;
  top: -0.5em;
  left: 2em;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.textDanger};
  color: white;
  width: 1.5em;
  height: 1.5em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export default function MentorLinks() {
  const { value: mentorContextValue } = useMentorContext();
  const { value: submissionsValue } = useMentorSubmissionsContext();

  const unreviewedSubmissionsCount = (
    submissionsValue?.submissions ?? []
  ).filter((s) => !s.review.date).length;

  if (mentorContextValue?.profile?.status !== 'READY') {
    return null;
  }

  return (
    <div style={{ marginBottom: '2em' }}>
      <h1 style={{ marginBottom: '0.25em' }}>Your links</h1>
      <Grid>
        <GridItem
          notificationCount={unreviewedSubmissionsCount}
          title="Submissions"
          icon="fas fa-list-ul"
          url={getSubmissionsUrl()}
        />
        <GridItem title="Learners" icon="fas fa-users" url={getLearnersUrl()} />
      </Grid>
    </div>
  );
}

const GridItem = ({
  title,
  icon,
  url,
  notificationCount = 0,
}: {
  title: string;
  icon: string;
  url: string;
  notificationCount?: number;
}) => {
  const history = useHistory();

  const onPress = () => {
    history.push(url);
  };

  return (
    <ItemWrapper onClick={onPress}>
      {notificationCount > 0 && (
        <div style={{ position: 'relative', textAlign: 'center' }}>
          <NotificationBadge>{notificationCount}</NotificationBadge>
        </div>
      )}
      <ItemIcon className={icon} />
      <ItemTitle>{title}</ItemTitle>
    </ItemWrapper>
  );
};
