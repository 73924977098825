import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const {
  REACT_APP_ENV = 'development',
  REACT_APP_BUGSNAG_API_KEY = 'dummy',
  REACT_APP_VERSION = 'unspecified',
} = process.env;

const IS_DEV = REACT_APP_ENV === 'development';

if (!IS_DEV) {
  Bugsnag.start({
    apiKey: REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: REACT_APP_VERSION,
    releaseStage: REACT_APP_ENV,
  });
} else {
  console.log(`Skipping CrashReporter init as environment = ${REACT_APP_ENV}`);
}

export function setUser({ id, email, name }) {
  if (!IS_DEV) {
    Bugsnag.setUser(id, email, name);
  } else {
    console.log(
      `Skipping CrashReporter setUser as environment = ${REACT_APP_ENV}`
    );
  }
}

export function reportError(error) {
  if (!IS_DEV) {
    Bugsnag.notify(error);
  } else {
    console.log(
      `Skipping CrashReporter reportError as environment = ${REACT_APP_ENV}`
    );
  }
}

export function addBreadcrumb({ message, metadata = {}, type = 'default' }) {
  if (!IS_DEV) {
    Bugsnag.leaveBreadcrumb(message, metadata, type);
  } else {
    console.log(
      `Skipping CrashReporter addBreadcrumb ${message} as environment = ${REACT_APP_ENV}`
    );
  }
}

export const BREADCRUM_TYPE = {
  NAVIGATE: 'navigate',
  CONTEXT: 'context-action',
};

export const ErrorBoundary = IS_DEV
  ? ({ children }) => <>{children}</>
  : Bugsnag.getPlugin('react').createErrorBoundary(React);
