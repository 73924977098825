import React from 'react';
import { AsyncStateRetry } from 'react-use/lib/useAsyncRetry';
import {
  GetMentorProfileResponse,
  useGetMentorProfile,
} from '../hooks/use-hydra';

export const MentorContext = React.createContext<
  AsyncStateRetry<GetMentorProfileResponse>
>({
  retry: () => {},
  loading: false,
  error: undefined,
  value: undefined,
});

export const MentorProvider = ({ children }: { children: React.ReactNode }) => {
  const mentor = useGetMentorProfile();

  return (
    <MentorContext.Provider value={mentor}>{children}</MentorContext.Provider>
  );
};
