import React from 'react';
import styled, { useTheme } from 'styled-components';
import Navbar from '../components/Navbar';
import PageWrapper from '../components/PageWrapper';
import useAuth0 from '../hooks/use-auth0';
import useCleanGlobalClasses from '../hooks/use-clean-global-classes';
import { MentorLearner } from '../hydra';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LearnerModal from '../components/Learner/index';
import Table from '../components/Table';
import uniqBy from 'lodash.uniqby';
import { useHistory, useLocation } from 'react-router-dom';
import useMentorSubmissionsContext from '../hooks/use-mentor-submissions';
import useMentorLearnersContext from '../hooks/use-mentor-learners';
import * as tableConfigs from '../table-configs';
dayjs.extend(utc);

const PageContentWrapper = styled.div`
  padding: 2em;
  background-color: ${(prop) => prop.theme.colors.sectionBg};
  border: 1px solid ${(prop) => prop.theme.colors.sectionBorder};
  border-radius: ${(prop) => prop.theme.dimensions.sectionBorderRadius};
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Learners() {
  const theme = useTheme();
  const history = useHistory();
  const query = useQuery();

  const { isLoading, logout, auth0User, isAuthenticated } = useAuth0();
  const { value: learnersResponse } = useMentorLearnersContext();
  const { value: submissionsResponse } = useMentorSubmissionsContext();

  useCleanGlobalClasses({ isLoading });

  const learnerOptions = [
    {
      value: 'all',
      label: 'All',
    },
    ...uniqBy(
      learnersResponse?.learners.map((item) => ({
        value: item.learner.id.toString(),
        label: item.learner.name,
      })),
      'value'
    ),
  ];
  const topicsOptions = [
    {
      value: 'all',
      label: 'All',
    },
    ...uniqBy(
      learnersResponse?.learners.map((item) => ({
        value: item.content.slug,
        label: item.content.name,
      })),
      'value'
    ),
  ];

  const trackOptions = [
    {
      value: 'all',
      label: 'All',
    },
    ...uniqBy(
      learnersResponse?.learners.map((item) => ({
        value: item.track.slug,
        label: item.track.name,
      })),
      'value'
    ),
  ];

  const reviewOptions = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'unreviewed',
      label: 'Needs review',
    },
    {
      value: 'reviewed',
      label: 'Completed review',
    },
  ];

  const [topicFilter, setTopicFilter] = React.useState(
    topicsOptions
      .map((x) => x.value)
      .find((tag) => query.get('topic') === tag) || 'all'
  );
  const [trackFilter, setTrackFilter] = React.useState(
    trackOptions
      .map((x) => x.value)
      .find((tag) => query.get('track') === tag) || 'all'
  );

  const [learnerFilter, setLearnerFilter] = React.useState(
    learnerOptions
      .map((x) => x.value)
      .find((tag) => query.get('learner') === tag) || 'all'
  );
  const [reviewFilter, setReviewFilter] = React.useState(
    reviewOptions
      .map((x) => x.value)
      .find((tag) => query.get('review') === tag) || 'all'
  );

  const urlProfileId = query.get('profile');
  const [openProfileId, setOpenProfileId] = React.useState<number | null>(
    urlProfileId ? parseInt(urlProfileId) || null : null
  );
  const openProfile = learnersResponse?.learners.find(
    (x) => x.learner.id === openProfileId
  );

  React.useEffect(() => {
    history.push({
      search: `?learner=${learnerFilter}&topic=${topicFilter}&track=${trackFilter}&review=${reviewFilter}${
        openProfileId ? `&profile=${openProfileId}` : ''
      }`,
    });
  }, [
    history,
    learnerFilter,
    trackFilter,
    topicFilter,
    reviewFilter,
    openProfileId,
  ]);

  return (
    <>
      <Navbar
        auth={{
          isLoading,
          isAuthenticated,
          user: auth0User,
          logout,
        }}
      />
      {openProfileId && (
        <LearnerModal
          learner={openProfile}
          onCloseModal={() => setOpenProfileId(null)}
        />
      )}
      <PageWrapper>
        <PageContentWrapper>
          <h1>Your Learners</h1>
          <Table<MentorLearner>
            onClick={(item) => setOpenProfileId(item.learner.id)}
            data={learnersResponse?.learners || []}
            canResetFilters
            filters={tableConfigs.learners.getFilters({
              theme,
              submissions: submissionsResponse?.submissions || [],
              learner: {
                options: learnerOptions,
                onChange: setLearnerFilter,
                value: learnerFilter,
              },
              topic: {
                options: topicsOptions,
                onChange: setTopicFilter,
                value: topicFilter,
              },
              track: {
                options: trackOptions,
                onChange: setTrackFilter,
                value: trackFilter,
              },
              review: {
                options: reviewOptions,
                onChange: setReviewFilter,
                value: reviewFilter,
              },
            })}
            columnConfigs={tableConfigs.learners.getColumns({
              theme,
              submissions: submissionsResponse?.submissions || [],
            })}
            fallbackCopy="No learners"
          />
        </PageContentWrapper>
      </PageWrapper>
    </>
  );
}
