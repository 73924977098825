import React from 'react';
import { ActivitySubmission, ProjectSubmission } from '../../hydra';
import styled, { useTheme } from 'styled-components';
import { Pressable, SectionWrapper } from './common';

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const HeaderSection = ({
  submission,
  onClose,
}: {
  submission: ActivitySubmission | ProjectSubmission;
  onClose: () => void;
}) => {
  const theme = useTheme();

  const onOpenLearnerProfile = () => {
    window.open(
      `${window.location.protocol}/learners?profile=${submission.learner.id}`,
      '_blank'
    );
  };

  return (
    <SectionWrapper>
      <HeaderRow>
        <Pressable
          title="Open learner profile - take notes and check on past progress"
          onClick={onOpenLearnerProfile}
        >
          <h1 style={{ margin: '0' }}>
            Learner: {submission.learner.name}
            {'  '}
            <i className="fa-solid fa-arrow-up-right-from-square" />
          </h1>
        </Pressable>

        <Pressable title="Close Modal">
          <i
            className="fa-solid fa-x fa-2x"
            style={{ color: theme.colors.text }}
            onClick={onClose}
          />
        </Pressable>
      </HeaderRow>
    </SectionWrapper>
  );
};

export default HeaderSection;
